<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="clients"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Client Center</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <!-- <span class="text-h5">Hello</span> -->
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <!-- <v-layout wrap> -->
                  <v-row no-gutters>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.AccNo"
                          label="Acc No"
                          prepend-inner-icon="person"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                          readonly
                        ></v-text-field>
                      </v-flex>
                    </v-col>

                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Client"
                          label="Client"
                          prepend-inner-icon="person"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                          v-on:keydown.enter.prevent="getClientsAccNoCount"
                        ></v-text-field>
                      </v-flex>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Contact"
                          label="Contact"
                          prepend-inner-icon="person"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-flex>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Tel"
                          label="Tel No"
                          prepend-inner-icon="mdi-phone"
                          :rules="[rules.required, rules.min]"
                          clearable
                          dense
                          filled
                          outlined
                          counter
                          maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.CellNo"
                          label="Cell No"
                          prepend-inner-icon="mdi-cellphone"
                          :rules="[rules.required, rules.min]"
                          clearable
                          dense
                          filled
                          outlined
                          counter
                          maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Fax"
                          label="Fax No"
                          prepend-inner-icon="mdi-fax"
                          clearable
                          dense
                          filled
                          outlined
                          counter
                          maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-autocomplete
                          v-model="editedItem.Discount"
                          label="Discount"
                          :items="discountdb"
                          item-text="DiscPerc"
                          item-value="DiscPerc"
                          prepend-inner-icon="mdi-percent"
                          :rules="[rules.required]"
                          auto-select-first
                          clearable
                          dense
                          filled
                          outlined
                        ></v-autocomplete>
                      </v-flex>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-autocomplete
                          v-model="editedItem.Status"
                          label="Status"
                          :items="statusdb"
                          item-text="Status"
                          item-value="Status"
                          prepend-inner-icon="mdi-checkbox-marked-outline"
                          :rules="[rules.required]"
                          auto-select-first
                          clearable
                          dense
                          filled
                          outlined
                        >
                        </v-autocomplete>
                      </v-flex>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-autocomplete
                          label="Terms"
                          :items="termsdb"
                          item-text="Terms"
                          item-value="Terms"
                          v-model="editedItem.Terms"
                          prepend-inner-icon="gavel"
                          :rules="[rules.required]"
                          auto-select-first
                          clearable
                          dense
                          filled
                          outlined
                        >
                        </v-autocomplete>
                      </v-flex>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col xs="12" sm="12" md="8">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Email"
                          label="Email"
                          prepend-inner-icon="mdi-email"
                          :rules="[rules.required, rules.email]"
                          clearable
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-flex>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.VatNo"
                          label="Vat No"
                          prepend-inner-icon="credit_score"
                          clearable
                          dense
                          filled
                          outlined
                          required
                          counter
                          maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col md="6" offset-md="4">
                      <v-flex>
                        <v-checkbox
                          v-model="enableAddr"
                          :label="`Postal Address same as Address`"
                          outlined
                          id="ChangeAddress"
                          @change="changeAddressToPostal"
                        >
                        </v-checkbox>
                      </v-flex>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col xs="12" sm="12" md="6">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Add1"
                          label="Street"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Add2"
                          label="Area"
                          append-icon="search"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                          @click:append="dialog2 = !dialog2"
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Add3"
                          label="City"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.Add4"
                          label="Area Code"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required, rules.counteraddMin]"
                          clearable
                          dense
                          filled
                          outlined
                          counter
                          maxlength="4"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-col>
                    <v-col xs="12" sm="12" md="6">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.DelAdd1"
                          label="P.O. Box"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.DelAdd2"
                          label="Postal Area"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.DelAdd3"
                          label="Postal City"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.DelAdd4"
                          label="Postal Area Code"
                          prepend-inner-icon="mdi-map-marker"
                          counter
                          :rules="[rules.required, rules.counteraddMin]"
                          clearable
                          dense
                          filled
                          outlined
                          maxlength="4"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="close"
                  >Cancel</v-btn
                >
                <v-btn color="primary" dark class="mb-2" @click="validate"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog2" max-width="700px">
            <v-card>
              <v-card-text>
                <v-container grid-list-md>
                  <v-data-table
                    :headers="headersarea"
                    :items="areacodes"
                    :search="searcharea"
                    sort-by="calories"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Area Search</v-toolbar-title>
                        <v-divider class="mx-3" inset vertical left></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="searcharea"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItemArea(item)">
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon small @click="closeDialog2()">
                        mdi-cancel
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="closeAdd"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon v-if="checkPermissions()" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    dialogDelete: false,
    isEditing: false,
    enableAddr: false,
    show1: false,

    id: "",
    search: "",
    searcharea: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 10 || "Min 10 characters",
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      counteraddMax: (value) => value.length <= 4 || "Max 4 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Acc No",
        align: "center",
        sortable: true,
        value: "AccNo",
        width: "5%",
      },
      { text: "Client", value: "Client" },
      { text: "Tel No", value: "Tel" },
      { text: "Cell No", value: "CellNo" },
      { text: "Email", value: "Email" },
      { text: "Contact", value: "Contact" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    headersarea: [
      {
        text: "Area",
        align: "left",
        sortable: true,
        value: "area",
      },
      { text: "Code", value: "code" },
      { text: "City", value: "city" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    clients: [],
    discountdb: [],
    statusdb: [],
    termsdb: [],
    areacodes: [],
    editedIndex: -1,
    editedIndexArea: -1,
    editedItem: {
      id: "",
      AccNo: "",
      Client: "",
      Add1: "",
      Add2: "",
      Add3: "",
      Add4: "",
      Tel: "",
      Fax: "",
      Contact: "",
      Terms: "",
      Email: "",
      CellNo: "",
      Discount: "",
      DelAdd1: "",
      DelAdd2: "",
      DelAdd3: "",
      DelAdd4: "",
      Status: "",
      VatNo: "",
    },
    defaultItem: {
      id: "",
      AccNo: "",
      Client: "",
      Add1: "",
      Add2: "",
      Add3: "",
      Add4: "",
      Tel: "",
      Fax: "",
      Contact: "",
      Terms: "",
      Email: "",
      CellNo: "",
      Discount: "",
      DelAdd1: "",
      DelAdd2: "",
      DelAdd3: "",
      DelAdd4: "",
      Status: "",
      VatNo: "",
    },
    editedItemArea: {
      id: "",
      area: "",
      code: "",
      city: "",
    },
    defaultItemArea: {
      id: "",
      area: "",
      code: "",
      city: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getClients();
    self.getDiscount();
    self.getStatus();
    self.getTerms();
    self.getAreaCodes();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Client" : "Edit Client";
    },
    roleAccess: function () {
      return this.$store.getters.roleAccess;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    getClients: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/clients`,
      }).then((response) => {
        this.clients = response.data;
      });
    },
    getDiscount: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/discounts`,
      }).then((response) => (this.discountdb = response.data));
    },
    getStatus: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/statuses`,
      }).then((response) => (this.statusdb = response.data));
    },
    getTerms: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/terms`,
      }).then((response) => (this.termsdb = response.data));
    },
    getAreaCodes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/areacodes`,
      }).then((response) => {
        this.areacodes = response.data;
      });
    },
    postInsertClients: function () {
      const data = {
        AccNo: this.editedItem.AccNo,
        Client: this.editedItem.Client,
        Add1: this.editedItem.Add1,
        Add2: this.editedItem.Add2,
        Add3: this.editedItem.Add3,
        Add4: this.editedItem.Add4,
        Tel: this.editedItem.Tel,
        Fax: this.editedItem.Fax,
        Contact: this.editedItem.Contact,
        Terms: this.editedItem.Terms,
        Email: this.editedItem.Email,
        CellNo: this.editedItem.CellNo,
        Discount: this.editedItem.Discount,
        DelAdd1: this.editedItem.DelAdd1,
        DelAdd2: this.editedItem.DelAdd2,
        DelAdd3: this.editedItem.DelAdd3,
        DelAdd4: this.editedItem.DelAdd4,
        Status: this.editedItem.Status,
        VatNo: this.editedItem.VatNo,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/clients`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postUpdateClients: function () {
      const data = {
        id: this.editedItem.id,
        AccNo: this.editedItem.AccNo,
        Client: this.editedItem.Client,
        Add1: this.editedItem.Add1,
        Add2: this.editedItem.Add2,
        Add3: this.editedItem.Add3,
        Add4: this.editedItem.Add4,
        Tel: this.editedItem.Tel,
        Fax: this.editedItem.Fax,
        Contact: this.editedItem.Contact,
        Terms: this.editedItem.Terms,
        Email: this.editedItem.Email,
        CellNo: this.editedItem.CellNo,
        Discount: this.editedItem.Discount,
        DelAdd1: this.editedItem.DelAdd1,
        DelAdd2: this.editedItem.DelAdd2,
        DelAdd3: this.editedItem.DelAdd3,
        DelAdd4: this.editedItem.DelAdd4,
        Status: this.editedItem.Status,
        VatNo: this.editedItem.VatNo,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/clients/${this.editedItem.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postDeleteClients: function () {
      const data = {
        id: this.id,
      };
      this.axios({
        method: "delete",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/clients/${this.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    getClientsAccNoCount: function () {
      var AccName = this.editedItem.Client;
      AccName = AccName.replace(/[\s\/]/g, "");
      AccName = AccName.substring(0, 3);
      AccName = AccName.toUpperCase();
      const data = {
        AccName,
      };
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/clients/count/${AccName}`,
        data: data,
      }).then((response) => {
        var AccCode = response.data.count;
        AccCode++;
        if (AccCode < 10) {
          AccCode = AccName + "000" + AccCode;
        } else if ((AccCode >= 10) & (AccCode < 100)) {
          AccCode = AccName + "00" + AccCode;
        } else if ((AccCode >= 100) & (AccCode < 1000)) {
          AccCode = AccName + "0" + AccCode;
        } else if ((AccCode >= 1000) & (AccCode < 10000)) {
          AccCode = AccName + this.AccCode;
        } else {
          alert("Maximumn account number for: " + AccName);
        }
        this.editedItem.AccNo = AccCode;
      });
    },
    editItem(item) {
      this.editedIndex = this.clients.indexOf(item);

      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },
    editItemArea(item) {
      this.editedIndexArea = this.areacodes.indexOf(item);
      this.editedItemArea = Object.assign({}, item);
      this.editedItem.Add2 = this.editedItemArea.area;
      this.editedItem.Add3 = this.editedItemArea.city;
      this.editedItem.Add4 = this.editedItemArea.code;
      this.searcharea = null;
      this.dialog2 = false;
    },

    deleteItem(item) {
      this.id = item.id;
      const index = this.clients.indexOf(item);
      if (confirm("Are you sure you want to delete this client?")) {
        this.clients.splice(index, 1);
        this.postDeleteClients();
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
      this.enableAddr = false;
    },
    closeAdd() {
      this.dialog2 = false;
      this.editedItemArea = Object.assign({}, this.defaultItemArea);
      this.editedIndexArea = -1;
      this.$refs.form.resetValidation();
      this.searcharea = null;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.clients[this.editedIndex], this.editedItem);
      } else {
        this.clients.push(this.editedItem);
      }
      this.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.clients[this.editedIndex], this.editedItem);
          this.postUpdateClients();
        } else {
          this.postInsertClients();
          this.clients.push(this.editedItem);
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
    changeAddressToPostal() {
      this.$nextTick(() => {
        let elt = document.getElementById("ChangeAddress");
        if (elt.checked) {
          this.editedItem.DelAdd1 = this.editedItem.Add1;
          this.editedItem.DelAdd2 = this.editedItem.Add2;
          this.editedItem.DelAdd3 = this.editedItem.Add3;
          this.editedItem.DelAdd4 = this.editedItem.Add4;
        } else {
          this.editedItem.DelAdd1 = "";
          this.editedItem.DelAdd2 = "";
          this.editedItem.DelAdd3 = "";
          this.editedItem.DelAdd4 = "";
        }
      });
    },
    closeDialog2() {
      this.dialog2 = false;
      this.searcharea = null;
    },
    checkPermissions() {
      if (this.roleAccess == "Super User") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
