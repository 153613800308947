<template>
  <div class="my-5">
    <v-form ref="form" v-model="valid">
      <template>
        <v-toolbar flat color="white" scroll-target>
          <v-toolbar-title>Planner</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px">
            <v-btn slot="activator" color="primary" dark class="mb-2" right
              >New Ticket</v-btn
            >
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editedItem.id_no"
                        label="ID No"
                        prepend-inner-icon="featured_video"
                        :rules="[rules.required]"
                        v-on:keydown.enter.prevent="getSelctedClientByIDNo"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-combobox
                        :items="users"
                        item-text="name"
                        item-value="name"
                        v-model="editedItem.user"
                        label="User"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                        :return-object="false"
                      ></v-combobox>
                    </v-flex>

                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editedItem.surname"
                        label="Surname"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editedItem.policy_no"
                        label="Policy No"
                        prepend-inner-icon="folder_shared"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-menu>
                        <v-text-field
                          :value="editedItem.date"
                          slot="activator"
                          label="Date"
                          prepend-inner-icon="date_range"
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-date-picker
                          v-model="editedItem.date"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12 sm6 md6>
                      <v-combobox
                        :items="status"
                        item-text="name"
                        item-value="name"
                        v-model="editedItem.status"
                        label="Status"
                        prepend-inner-icon="schedule"
                        :rules="[rules.required]"
                        :return-object="false"
                      ></v-combobox>
                    </v-flex>
                    <v-flex xs12 sm6 md12>
                      <v-textarea
                        v-model="editedItem.details"
                        label="Description"
                        prepend-inner-icon="description"
                        :rules="[rules.required]"
                        :counter="250"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" flat @click="validate">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-form>

    <v-data-table
      :headers="headers"
      :items="tickets"
      :search="search"
      :pagination.sync="pagination"
      class="elevation-0"
    >
      <template v-slot:items="props">
        <td>{{ props.item.name }}</td>
        <td class="text-xs-left">{{ props.item.surname }}</td>
        <td class="text-xs-left">{{ props.item.id_no }}</td>
        <td class="text-xs-left">{{ props.item.policy_no }}</td>
        <td class="text-xs-left">{{ props.item.date }}</td>
        <td class="text-xs-left">{{ props.item.details }}</td>
        <td class="text-xs-left">{{ props.item.status }}</td>
        <td class="justify-center layout px-0">
          <v-icon small class="mr-2" @click="editItem(props.item)">
            edit
          </v-icon>
          <v-icon small @click="deleteItem(props.item)"> delete </v-icon>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    valid: true,
    search: "",
    id_no: "",
    email: "",
    pagination: {
      rowsPerPage: 10,
    },
    show1: false,
    dbres: "",
    id: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      { text: "Name", align: "left", sortable: true, value: "name" },
      { text: "Surname", value: "surname" },
      { text: "ID No", value: "id_no" },
      { text: "Policy No", value: "policy_no" },
      { text: "Date", value: "date" },
      { text: "Details", value: "details" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "action", sortable: false, align: "center" },
    ],
    tickets: [],
    clients: [],
    status: [],
    users: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      surname: "",
      id_no: "",
      policy_no: "",
      date: "",
      details: "",
      status: "",
      c_id: "",
      user: "",
    },
    defaultItem: {
      name: "",
      surname: "",
      id_no: "",
      policy_no: "",
      date: "",
      details: "",
      status: "",
      c_id: "",
      user: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getTickets();
    self.getStatus();
    self.getUsers();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Ticket" : "Edit Ticket";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {},
  methods: {
    getTickets: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/ticket`,
      }).then((response) => {
        this.tickets = response.data;
      });
    },
    getStatus: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/statuses`,
      }).then((response) => {
        this.status = response.data;
      });
    },
    getSelctedClientByIDNo: function () {
      const data = {
        id_no: this.editedItem.id_no,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/clientssel`,
        data: data,
      }).then((response) => {
        this.editedItem.c_id = response.data[0].id;
        this.editedItem.name = response.data[0].name;
        this.editedItem.surname = response.data[0].surname;
        this.editedItem.policy_no = response.data[0].policy_no;
      });
    },
    getUsers: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/usersm`,
      }).then((response) => (this.users = response.data));
    },
    postInsertTicket: function () {
      const data = {
        c_id: this.editedItem.c_id,
        date: this.editedItem.date,
        details: this.editedItem.details,
        status: this.editedItem.status,
        user: this.editedItem.user,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/ticketins`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postUpdateTicket: function () {
      const data = {
        id: this.editedItem.id,
        c_id: this.editedItem.c_id,
        date: this.editedItem.date,
        details: this.editedItem.details,
        status: this.editedItem.status,
        user: this.editedItem.user,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/ticketupd`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postDeleteTicket: function () {
      const data = {
        id: this.id,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/ticketdel`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.tickets.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.id = item.id;
      const index = this.tickets.indexOf(item);
      if (confirm("Are you sure you want to delete this ticket?")) {
        this.tickets.splice(index, 1);
        this.postDeleteTicket();
      }
    },
    close() {
      this.dialog = false;
      // setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      // }, 300)
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.tickets[this.editedIndex], this.editedItem);
      } else {
        this.tickets.push(this.editedItem);
      }
      this.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.tickets[this.editedIndex], this.editedItem);
          this.postUpdateTicket();
        } else {
          this.tickets.push(this.editedItem);
          this.postInsertTicket();
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
  },
};
</script>
