<template>
  <v-form ref="form">
    <v-toolbar flat dense>
      <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
      <v-divider class="mx-3" inset vertical left></v-divider>
    </v-toolbar>

    <v-card>
      <v-card-text id="content">
        <v-container>
          <v-row class="align-center justify-center">
            <v-col cols="12" sm="6" md="6">
              <v-card outlined>
                <v-toolbar color="primary" dense>
                  <v-toolbar-title>Expense Info:</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-row no-gutters>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="Req No"
                            type="number"
                            prepend-inner-icon="pin"
                            v-model="editedItem.ReqNo"
                            outlined
                            dense
                            readonly
                            hide-spin-buttons
                            class="pr-1"
                            :disabled="!isNewExpense"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Created By"
                            prepend-inner-icon="person"
                            v-model="editedItem.CreatedBy"
                            :rules="[rules.required]"
                            outlined
                            dense
                            readonly
                            class="pl-1"
                            @change="calcUnitTotal"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="6" md="6">
                          <v-menu
                            v-model="dateCreated"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.DateCreated"
                                label="Date Created"
                                prepend-inner-icon="calendar_today"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                class="pr-1"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.DateCreated"
                              @input="dateCreated = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                            v-model="editedItem.Category"
                            label="Category"
                            :items="expensetypes"
                            item-text="Type"
                            item-value="Type"
                            prepend-inner-icon="category"
                            :rules="[rules.required]"
                            auto-select-first
                            dense
                            outlined
                            class="pl-1"
                            @change="setCategoryType"
                            ><template v-slot:append-outer>
                              <v-icon
                                title="Add Category"
                                :disabled="!isNewExpense"
                                @click="dialog = !dialog"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </template></v-autocomplete
                          >
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Invoice No"
                            prepend-inner-icon="pin"
                            v-model="editedItem.InvNo"
                            outlined
                            dense
                            class="pr-1"
                            :disabled="!isNewExpense"
                            :rules="
                              isBankFees === false ? [rules.required] : []
                            "
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu
                            v-model="invDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.InvDate"
                                label="Invoice Date"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                class="pl-1"
                                :rules="
                                  isBankFees === false ? [rules.required] : []
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.InvDate"
                              @input="invDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="6">
                          <v-menu
                            v-model="monthDue"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.MonthDue"
                                label="Due by date"
                                prepend-inner-icon="calendar_month"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                class="pr-1"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.MonthDue"
                              @input="monthDue = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu
                            v-model="paymentDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.MtnPd"
                                label="Payment Date"
                                prepend-inner-icon="today"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                class="pl-1"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.MtnPd"
                              @input="paymentDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                            v-model="editedItem.Supplier"
                            label="Supplier"
                            :items="supplierdb"
                            item-text="Supplier"
                            item-value="Supplier"
                            prepend-inner-icon="mdi-account-network"
                            :rules="[rules.required]"
                            auto-select-first
                            dense
                            outlined
                            class="pr-1"
                            :readonly="!enableClient"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="PO No"
                            type="number"
                            prepend-inner-icon="pin"
                            v-model="editedItem.PoNo"
                            outlined
                            dense
                            hide-spin-buttons
                            class="pl-1"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                            v-model="editedItem.Appr_By"
                            label="Approved By"
                            :items="itemstatusdb"
                            item-text="Status"
                            item-value="Status"
                            prepend-inner-icon="verified_user"
                            auto-select-first
                            dense
                            outlined
                            class="pr-1"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu
                            v-model="dateApproved"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.Appr_Date"
                                label="Date Approved"
                                prepend-inner-icon="event_available"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                class="pl-1"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.Appr_Date"
                              @input="dateApproved = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                            v-model="editedItem.Pay_Method"
                            label="Payment Type"
                            :items="paymenttypesdb"
                            item-text="p_type"
                            item-value="p_type"
                            prepend-inner-icon="credit_card"
                            auto-select-first
                            dense
                            outlined
                            class="pr-1"
                            :rules="
                              isBankFees === false ? [rules.required] : []
                            "
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            v-model="editedItem.Payment_No"
                            prepend-inner-icon="receipt"
                            label="Payment No"
                            outlined
                            dense
                            class="pl-1 pr-1"
                            :rules="
                              isBankFees === false ? [rules.required] : []
                            "
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4">
                          <v-text-field
                            label="Paid By"
                            prepend-inner-icon="person"
                            v-model="editedItem.PaidBy"
                            :rules="
                              isBankFees === false ? [rules.required] : []
                            "
                            outlined
                            dense
                            class="pl-1"
                            readonly
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Total Amount"
                            type="number"
                            prepend-inner-icon="mdi-equal"
                            v-model="editedItem.TotalAmt"
                            :rules="[rules.required]"
                            outlined
                            dense
                            hide-spin-buttons
                            class="pr-1"
                            :disabled="!isNewExpense"
                            @change="calcTotal"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Balance Due"
                            type="number"
                            prepend-inner-icon="account_balance_wallet"
                            v-model="editedItem.OutAmt"
                            :rules="[rules.required]"
                            outlined
                            dense
                            hide-spin-buttons
                            readonly
                            class="pl-1"
                            :disabled="!isNewExpense"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Amount Paid"
                            type="number"
                            prepend-inner-icon="paid"
                            v-model="editedItem.AmtPaid"
                            :rules="[rules.required]"
                            outlined
                            dense
                            hide-spin-buttons
                            readonly
                            class="pr-1"
                            :disabled="!isNewExpense"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Pay Now"
                            type="number"
                            prepend-inner-icon="payments"
                            v-model="AmountPaid"
                            :rules="[rules.required]"
                            outlined
                            dense
                            hide-spin-buttons
                            class="pl-1"
                            :disabled="!isNewExpense"
                            @change="calcTotal"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-textarea
                        v-model="editedItem.Description"
                        label="Description"
                        prepend-inner-icon="description"
                        :rules="[rules.required]"
                        outlined
                        dense
                        counter
                        no-resize
                        rows="2"
                        maxlength="250"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">New Category</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="itype" v-model="valid">
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="newCategory"
                    label="Category"
                    prepend-inner-icon="mdi-file-check"
                    :rules="[rules.required]"
                    clearable
                    dense
                    filled
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="close2"
                >Close</v-btn
              >
              <v-btn color="primary" dark class="mb-2" @click="validate2"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-footer padless dense>
          <v-row justify="center" no-gutters>
            <v-btn
              tile
              :disabled="!enableCancel"
              color="primary"
              dark
              class="mx-2"
              @click="close"
              >Cancel</v-btn
            >
            <v-btn
              tile
              :disabled="!enableSave"
              color="primary"
              dark
              class="mb-2"
              @click="validate"
              >Save</v-btn
            >
            <v-btn
              tile
              :disabled="!enableSave"
              color="primary"
              dark
              class="mx-2"
              @click="validate"
              >Print</v-btn
            >
            <v-btn
              tile
              :disabled="!enableClose"
              color="primary"
              dark
              class="mb-2"
              @click="close"
              >Close</v-btn
            >
          </v-row>
        </v-footer>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { uid } from "uid";
export default {
  data: () => ({
    systemsetup: [],
    supplierdb: [],
    itemstatusdb: [],
    expensetypes: [],
    paymenttypesdb: [],
    editedInvoiceIndex: -1,
    invDate: false,
    monthDue: false,
    paymentDate: false,
    dateCreated: false,
    dateApproved: false,
    datePurchace: false,
    dateOut: false,
    isService: false,
    dialog: false,
    valid: true,
    Date_Purchace: "",
    Date_Out: "",
    id: "",
    status: "",
    itemA: "",
    itemB: "",
    newCategory: "",
    enableCancel: true,
    enableSave: true,
    invoiceSaved: false,
    enableNewItem: false,
    enableClient: true,
    enableClose: false,
    isNewExpense: false,
    formValid: false,
    isBankFees: false,
    AmountPaid: "0.00",
    Balance: "",
    rules: {
      required: (value) => !!value || "Required.",
      alphaOnly: (v) => /^[a-zA-Z]/.test(v) || "Can not contain digits.",
      aphaOnly: (v) => {
        const aOnly = /[a-zA-Z]/;
        return aOnly.test(v) || "Can not contain digits.";
      },
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    editedItem: {
      id: "",
      ReqNo: "",
      Category: "",
      InvDate: "",
      Supplier: "",
      InvNo: "",
      Description: "",
      TotalAmt: "",
      AmtPaid: "",
      OutAmt: "",
      DueDate: "",
      MonthDue: "",
      MtnPd: "",
      PaidBy: "",
      CreatedBy: "",
      DateCreated: "",
      PoNo: "",
      Appr_By: "",
      Appr_Date: "",
      Pay_Method: "",
      Payment_No: "",
    },
    defaultItem: {
      id: "",
      ReqNo: "",
      Category: "",
      InvDate: "",
      Supplier: "",
      InvNo: "",
      Description: "",
      TotalAmt: "",
      AmtPaid: "",
      OutAmt: "",
      DueDate: "",
      MonthDue: "",
      MtnPd: "",
      PaidBy: "",
      CreatedBy: "",
      DateCreated: "",
      PoNo: "",
      Appr_By: "",
      Appr_Date: "",
      Pay_Method: "",
      Payment_No: "",
    },
    formatter: new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }),
  beforeMount: function () {
    let self = this;
    self.getItemStatus();
    self.getSupplier();
    self.getExpenseTypes();
    self.getPaymentTypes();
    if (this.$route.params.id > "-1") {
      self.getExpenceDetailsById();
    } else {
      self.setDateNow();
      this.isNewExpense = true;
      self.setDefault();
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id === "-1"
        ? "New Expense"
        : "Edit Expense: " + this.$route.params.id;
    },

    getUserFull: function () {
      return localStorage.getItem("fullname");
    },
    getUserEmail: function () {
      return localStorage.getItem("useremail");
    },
  },
  methods: {
    getExpenseTypes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/expensetypes`,
      }).then((response) => (this.expensetypes = response.data));
    },

    getItemStatus: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/itemstatuses`,
      }).then((response) => {
        this.itemstatusdb = response.data;
      });
    },

    getSupplier: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/suppliers`,
      }).then((response) => {
        this.supplierdb = response.data;
      });
    },

    getPaymentTypes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/paymenttypes`,
      }).then((response) => (this.paymenttypesdb = response.data));
    },

    getExpenceDetailsById: function () {
      const data = {
        id: this.$route.params.id,
      };
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/expenses/${this.$route.params.id}`,
        data: data,
      }).then((response) => {
        this.editedItem.id = response.data.id;
        this.editedItem.ReqNo = response.data.ReqNo;
        this.editedItem.Category = response.data.Category;
        this.editedItem.InvDate = response.data.InvDate;
        this.editedItem.Supplier = response.data.Supplier;
        this.editedItem.InvNo = response.data.InvNo;
        this.editedItem.Description = response.data.Description;
        this.editedItem.TotalAmt = response.data.TotalAmt;
        this.editedItem.AmtPaid = response.data.AmtPaid;
        this.editedItem.OutAmt = response.data.OutAmt;
        this.editedItem.DueDate = response.data.DueDate;
        this.editedItem.MonthDue = response.data.MonthDue;
        this.editedItem.MtnPd = response.data.MtnPd;
        this.editedItem.PaidBy = response.data.PaidBy;
        this.editedItem.CreatedBy = response.data.CreatedBy;
        this.editedItem.DateCreated = response.data.DateCreated;
        this.editedItem.PoNo = response.data.PoNo;
        this.editedItem.Appr_By = response.data.Appr_By;
        this.editedItem.Appr_Date = response.data.Appr_Date;
        this.editedItem.Pay_Method = response.data.Pay_Method;
        this.editedItem.Payment_No = response.data.Payment_No;
      });
    },

    setDefault: function () {
      this.editedItem.CreatedBy = this.getUserFull;
    },

    setReqNo: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/expenses/findreqno`,
      }).then((response) => {
        if (response.data.length === 0) {
          var ReqNo = 0;
        } else {
          var ReqNo = response.data[0].ReqNo;
        }

        ReqNo++;
        if (ReqNo < 10) {
          ReqNo = "000000" + ReqNo;
        } else if ((ReqNo >= 10) & (ReqNo < 100)) {
          ReqNo = "00000" + ReqNo;
        } else if ((ReqNo >= 100) & (ReqNo < 1000)) {
          ReqNo = "0000" + ReqNo;
        } else if ((ReqNo >= 1000) & (ReqNo < 10000)) {
          ReqNo = "000" + ReqNo;
        } else if ((ReqNo >= 10000) & (ReqNo < 100000)) {
          ReqNo = "00" + ReqNo;
        } else if ((ReqNo >= 100000) & (ReqNo < 1000000)) {
          ReqNo = "0" + ReqNo;
        } else if ((ReqNo >= 1000000) & (ReqNo < 1000000)) {
          ReqNo = this.ReqNo;
        } else {
          alert("Maximumn number for ReqNo");
        }
        this.editedItem.ReqNo = ReqNo;
        this.postExpenseDetails();
      });
    },

    postExpenseDetails: function () {
      const data = {
        ReqNo: this.editedItem.ReqNo,
        Category: this.editedItem.Category,
        InvDate: this.editedItem.InvDate,
        Supplier: this.editedItem.Supplier,
        InvNo: this.editedItem.InvNo,
        Description: this.editedItem.Description,
        TotalAmt: this.editedItem.TotalAmt,
        AmtPaid: this.editedItem.AmtPaid,
        OutAmt: this.editedItem.OutAmt,
        DueDate: this.editedItem.DueDate,
        MonthDue: this.editedItem.MonthDue,
        MtnPd: this.editedItem.MtnPd,
        PaidBy: this.editedItem.PaidBy,
        CreatedBy: this.editedItem.CreatedBy,
        DateCreated: this.editedItem.DateCreated,
        PoNo: this.editedItem.PoNo,
        Appr_By: this.editedItem.Appr_By,
        Appr_Date: this.editedItem.Appr_Date,
        Pay_Method: this.editedItem.Pay_Method,
        Payment_No: this.editedItem.Payment_No,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/expenses`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        //jaco
        if (this.AmountPaid > "0,00") {
          this.getBankBalance();
        }
      });
    },

    postItemType: function () {
      const data = {
        type: this.newCategory,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/itemtypes`,
        data: data,
      })
        .then((response) => {
          this.dbres = response.data;
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data.message);
          }
          // if (error.response) {
          //   if (error.response.status == 401 || error.response.status == 404) {
          //     this.alerterror = true;
          //   }
          // }
        });
    },

    updateExpenceDetails: function () {
      const data = {
        ReqNo: this.editedItem.ReqNo,
        Category: this.editedItem.Category,
        InvDate: this.editedItem.InvDate,
        Supplier: this.editedItem.Supplier,
        InvNo: this.editedItem.InvNo,
        Description: this.editedItem.Description,
        TotalAmt: this.editedItem.TotalAmt,
        AmtPaid: this.editedItem.AmtPaid,
        OutAmt: this.editedItem.OutAmt,
        DueDate: this.editedItem.DueDate,
        MonthDue: this.editedItem.MonthDue,
        MtnPd: this.editedItem.MtnPd,
        PaidBy: this.editedItem.PaidBy,
        CreatedBy: this.editedItem.CreatedBy,
        DateCreated: this.editedItem.DateCreated,
        PoNo: this.editedItem.PoNo,
        Appr_By: this.editedItem.Appr_By,
        Appr_Date: this.editedItem.Appr_Date,
        Pay_Method: this.editedItem.Pay_Method,
        Payment_No: this.editedItem.Payment_No,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/Inventories/${this.editedItem.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        // this.deleteInvoice();
      });
    },

    insertBankBalance() {
      let CurrentBalance = 0;
      let Amount_Paid = 0;
      let TotalBalance = 0;
      CurrentBalance = this.Balance;
      Amount_Paid = this.AmountPaid;
      TotalBalance = this.formatNumber(
        parseFloat(CurrentBalance) - parseFloat(Amount_Paid)
      );
      const data = {
        Payment_Date: this.editedItem.MtnPd,
        Description:
          "Paid " +
          this.editedItem.Supplier +
          " Invoice no " +
          this.editedItem.InvNo,
        Debit: this.formatNumber(this.AmountPaid),
        Balance: TotalBalance,
        Supplier: this.editedItem.Supplier,
        InvNo: this.editedItem.InvNo,
        Created_By: this.getUserFull,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/bankbalances`,
        data: data,
      })
        .then((response) => {
          this.dbres = response.data;
          // this.close();
          // this.getInvoiceDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBankBalance() {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/bankbalances`,
      }).then((response) => {
        this.Balance = response.data[0].Balance;
        this.insertBankBalance();
      });
    },

    setDateNow() {
      var today = new Date();
      this.editedItem.DateCreated = today.toISOString().substring(0, 10);
    },

    setCategoryType: function () {
      if (this.editedItem.Category == "Bank Fees") {
        this.isBankFees = true;

        // this.editedItem.Unit = 1;
        // this.editedItem.Qty = 1;
        // this.editedItem.Markup = 0;
        // this.incMarkup = "0.00";
        this.$refs.form.resetValidation();
      } else {
        this.isBankFees = false;
        // this.editedItem.Markup = "";
        // this.incMarkup = "";
        this.$refs.form.resetValidation();
      }
    },

    validate() {
      if (this.$refs.form.validate()) {
        if ((this.$route.params.id = "-1" && this.isNewExpense)) {
          this.setReqNo();
        } else {
          this.updateExpenceDetails();
        }

        this.$refs.form.resetValidation();
        // this.closeInvoice();
      }
    },

    validate2() {
      if (this.$refs.itype.validate()) {
        this.postItemType();
        this.$refs.itype.resetValidation();
      }
    },

    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    formatCurrency(num) {
      return this.formatter
        .format(num)
        .replace(/[^0-9\,.]/, "")
        .trim();
    },
    calcUnitTotal() {
      if (this.editedItem.OutAmt) {
        let priceUnit = this.editedItem.OutAmt;
        this.editedItem.MtnPd = this.formatNumber(
          this.editedItem.OutAmt / this.editedItem.MonthDue
        );

        this.editedItem.OutAmt = this.formatNumber(priceUnit);
      }
    },
    calcTotal() {
      let totalAmount = 0;
      let balanceDue = 0;
      let amountPaid = 0;
      let payNow = 0;
      totalAmount = this.editedItem.TotalAmt;
      balanceDue = this.editedItem.OutAmt;
      amountPaid = this.editedItem.AmtPaid;
      payNow = this.AmountPaid;

      if (totalAmount) {
        this.editedItem.TotalAmt = this.formatNumber(totalAmount);
        if (!balanceDue) {
          this.editedItem.OutAmt = this.formatNumber(totalAmount);
        }
        if (!amountPaid) {
          this.editedItem.AmtPaid = this.formatNumber(0);
        }
        if (payNow > 0) {
          this.editedItem.PaidBy = this.getUserFull;
          if (Number(payNow) <= Number(balanceDue)) {
            this.editedItem.OutAmt = this.formatNumber(
              Number(balanceDue) - Number(payNow)
            );
            this.editedItem.AmtPaid = this.formatNumber(
              Number(payNow) + Number(amountPaid)
            );

            this.AmountPaid = this.formatNumber(0);
          } else {
            alert(
              "The amount that you have entered is more than the balance due amount"
            );
            this.AmountPaid = this.formatNumber(0);
          }
        }
      }
      //jaco
    },
    close() {
      this.$router.push("/Expenses");
    },
    close2() {
      this.dialog = false;
      this.$refs.itype.resetValidation();
      this.getExpenseTypes();
    },
  },
};
</script>
