<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="editedItem.PO_No"
                prepend-inner-icon="mdi-file"
                label="PO No"
                outlined
                dense
                class="d-flex my-1"
                hide-details
                readonly
              >
              </v-text-field>
              <v-text-field
                v-model="editedItem.PO_Date"
                prepend-inner-icon="mdi-calendar-today"
                label="Date"
                outlined
                dense
                class="d-flex my-1"
                hide-details
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <v-card outlined>
                <v-toolbar color="primary" dense>
                  <v-toolbar-title>Supplier:</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-autocomplete
                    v-model="editedItem.Supplier"
                    label="Supplier"
                    :items="supplierdb"
                    item-text="Supplier"
                    item-value="id"
                    prepend-inner-icon="person"
                    :rules="[rules.required]"
                    auto-select-first
                    dense
                    outlined
                    hide-details
                    return-object
                    :readonly="!enableClient"
                    @change="UpdateSupplierDetails"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="editedItem.Add1"
                    prepend-inner-icon="mdi-home-map-marker"
                    label="Street"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.Add2"
                    prepend-inner-icon="mdi-home-assistant"
                    label="Area"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.Add3"
                    prepend-inner-icon="mdi-city"
                    label="City"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.Add4"
                    prepend-inner-icon="post_add"
                    label="Code"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.TelNo"
                    prepend-inner-icon="credit_score"
                    label="Tel No"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.Email"
                    prepend-inner-icon="credit_score"
                    label="Email"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" class="justify-end">
              <v-card outlined>
                <v-toolbar color="primary" dense>
                  <v-toolbar-title>Deliver To:</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="Company"
                    prepend-inner-icon="mdi-home-map-marker"
                    label="Company"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="Address"
                    prepend-inner-icon="mdi-home-map-marker"
                    label="Street"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="Area"
                    prepend-inner-icon="mdi-home-assistant"
                    label="Area"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="City"
                    prepend-inner-icon="mdi-city"
                    label="City"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="Code"
                    prepend-inner-icon="post_add"
                    label="Code"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="Tel"
                    prepend-inner-icon="post_add"
                    label="Tel"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="AccountNo"
                    prepend-inner-icon="post_add"
                    label="Account No"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <template>
            <v-data-table
              :headers="headers"
              :items="porderItemList"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title> Purchase Order Items </v-toolbar-title>
                  <v-divider class="mx-3" insert vertical left></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!enableNewItem"
                      >
                        New Item
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Items</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container grid-list-md>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                label="Item Code"
                                v-model="editedItemPOrder.ItemCode"
                                append-icon="search"
                                prepend-inner-icon="inventory_2"
                                :rules="[rules.required]"
                                outlined
                                dense
                                @click:append="dialog3 = !dialog3"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                label="Stock Code"
                                v-model="editedItemPOrder.StockCode"
                                outlined
                                dense
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="12">
                              <v-text-field
                                label="Description"
                                v-model="editedItemPOrder.Description"
                                :rules="[rules.required]"
                                outlined
                                dense
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="12">
                              <v-text-field
                                label="Qty"
                                type="number"
                                v-model="editedItemPOrder.Qty"
                                :rules="[rules.required]"
                                outlined
                                dense
                                @input="formatNumberTotal"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                label="Price"
                                v-model="editedItemPOrder.Price"
                                :rules="[rules.required]"
                                outlined
                                dense
                                @change="formatNumberTotal"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                type="number"
                                label="Total"
                                v-model="editedItemPOrder.Total"
                                :rules="[rules.required]"
                                outlined
                                dense
                                readonly
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="closePOrder"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="validate"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialog2" max-width="700px">
                    <v-card class="elevation-12" outlined dense>
                      <v-toolbar dark color="primary" dense>
                        <v-toolbar-title>Send emails...</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-form ref="vdialog2">
                          <v-text-field
                            v-model="editedEmailItem.email"
                            prepend-inner-icon="email"
                            :rules="[rules.email]"
                            name="email"
                            label="To..."
                            type="text"
                            outlined
                            dense
                            hide-details
                            class="d-flex my-1"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedEmailItem.subject"
                            prepend-inner-icon="subject"
                            :rules="[rules.required]"
                            name="subject"
                            label="Subject..."
                            type="text"
                            outlined
                            dense
                            hide-details
                            class="d-flex my-1"
                          ></v-text-field>
                          <v-file-input
                            v-model="file"
                            label="File Input"
                            show-size
                            counter
                            outlined
                            prepend-icon=""
                            prepend-inner-icon="mdi-paperclip"
                            placeholder="Upload your documents"
                            dense
                            hide-details
                            class="d-flex my-1"
                            type="file"
                            accept=".pdf"
                            @change="uploadFile"
                          >
                          </v-file-input>
                          <v-flex xs12 sm6 md12>
                            <v-textarea
                              v-model="editedEmailItem.textBody"
                              label="Description"
                              prepend-inner-icon="description"
                              :rules="[rules.required]"
                              outlined
                              dense
                              class="d-flex my-1"
                            ></v-textarea>
                          </v-flex>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        {{ status }}
                        {{ loading }}
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="closeDialog2"
                          >Cancel</v-btn
                        >
                        <v-btn color="primary" class="mb-2" @click="send"
                          >Send</v-btn
                        >
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="closeDialog2"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialog3" max-width="900px">
                    <v-card>
                      <v-card-text>
                        <v-container grid-list-md>
                          <v-data-table
                            :headers="headersinventory"
                            :items="inventories"
                            :search="searchinventory"
                            sort-by="calories"
                            class="elevation-1"
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title
                                  >Inventory Search</v-toolbar-title
                                >
                                <v-divider
                                  class="mx-3"
                                  inset
                                  vertical
                                  left
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field
                                  v-model="searchinventory"
                                  append-icon="search"
                                  label="Search"
                                  single-line
                                  hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>
                              </v-toolbar>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                class="mr-2"
                                @click="editItemInventory(item)"
                              >
                                mdi-check-circle-outline
                              </v-icon>
                              <v-icon small @click="closeInv()">
                                mdi-cancel
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="closeInv"
                          >Cancel</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </template>
        </v-container>
        <v-spacer></v-spacer>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" class="justify-end">
              <v-card outlined>
                <v-toolbar color="primary" dense>
                  <v-toolbar-title>Total:</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="editedItem.Sub_Total"
                    prepend-inner-icon="mdi-home-map-marker"
                    label="Sub Total"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="editedItem.Vat"
                    prepend-inner-icon="mdi-percent"
                    label="Vat"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    label="Total"
                    v-model="editedItem.Grand_Total"
                    prepend-inner-icon="functions"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-footer padless dense>
          <v-row justify="center" no-gutters>
            <v-btn
              tile
              :disabled="!enableCancel"
              color="primary"
              dark
              class="mx-2"
              @click="close"
              >Cancel</v-btn
            >
            <v-btn
              tile
              color="primary"
              dark
              class="mb-2"
              @click="validatePOrder"
              >Save</v-btn
            >
            <v-btn
              tile
              :disabled="!enabledPrint"
              color="primary"
              dark
              class="mx-2"
              @click="print"
              >Print</v-btn
            >
            <v-btn
              tile
              :disabled="!enabledEmail"
              color="primary"
              dark
              class="mb-2"
              @click="emailInv"
              >Email</v-btn
            >
            <v-btn
              tile
              :disabled="!enableClose"
              color="primary"
              dark
              class="mx-2"
              @click="close"
              >Close</v-btn
            >
          </v-row>
        </v-footer>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { uid } from "uid";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
export default {
  data: () => ({
    heading: "Purchase Order ",
    supplierdb: [],
    porderItemList: [],
    systemsetup: [],
    inventories: [],
    editedPOrderIndex: -1,
    editedIndexInv: -1,
    dialog: false,
    dialog2: false,
    dialog3: false,
    menudoe: false,
    Company: "",
    id: "",
    Disc: "",
    Address: "",
    Area: "",
    City: "",
    Code: "",
    Tel: "",
    AccountNo: "",
    Website: "",
    Email: "",
    Owner: "",
    TelNo: "",
    CellNo: "",
    FaxNo: "",
    AddStreet: "",
    AddArea: "",
    AddCity: "",
    AddAreaCode: "",
    RegNumber: "",
    VatRegNo: "",
    loading: "",
    status: "",
    attachment: "",
    file: null,
    fileUrl: "",
    searchinventory: "",
    enabledPrint: false,
    enableCancel: true,
    porderSaved: false,
    enableNewItem: false,
    enableClient: true,
    enableClose: false,
    isNewPOrder: false,
    enabledEmail: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 10 || "Min 10 characters",
      minId: (v) => v.length >= 13 || "Min 13 characters",
      requiredId: (value) => {
        if (value == true) {
          return true;
        }
        if (value == false) {
          return false;
        }
      },
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      counteraddMax: (value) => value.length <= 4 || "Max 4 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Qty",
        // align: "center",
        sortable: true,
        value: "Qty",
        width: "8%",
      },
      { text: "Item Code", value: "ItemCode", width: "10%" },
      { text: "Stock Code", value: "StockCode", width: "12%" },
      { text: "Description", value: "Description", width: "40%" },
      { text: "Price", value: "Price", width: "10%" },
      { text: "Total", value: "Total", width: "10%" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    headersinventory: [
      {
        text: "Item",
        align: "left",
        sortable: true,
        value: "I_Type",
      },
      { text: "Stock Code", value: "Supp_Ref_No" },
      { text: "Description", value: "Display_Desc" },
      { text: "Sales Price", value: "Sales_Price" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    editedItem: {
      id: "",
      PO_No: "",
      Supplier: "",
      Sub_Total: "",
      Vat: "",
      Vat_Select: "",
      Grand_Total: "",
      Quote_No: "",
      Paid: "",
      PO_Date: "",
    },
    defaultItem: {
      id: "",
      PO_No: "",
      Supplier: "",
      Sub_Total: "",
      Vat: "",
      Vat_Select: "",
      Grand_Total: "",
      Quote_No: "",
      Paid: "",
      PO_Date: "",
    },
    editedItemPOrder: {
      id: "",
      Qty: "",
      ItemCode: "",
      StockCode: "",
      Description: "",
      Price: "",
      Disc: "",
      Total: "",
    },
    defaultItemPOrder: {
      id: 0,
      Qty: "",
      ItemCode: "",
      StockCode: "",
      Description: "",
      Price: "",
      Disc: "",
      Total: "",
    },
    editedEmailItem: {
      email: "",
      subject: "",
      textBody: "",
      attachment: "",
    },
    defaultEmailItem: {
      email: "",
      subject: "",
      textBody: "",
      attachment: "",
    },
    editedItemInv: {
      id: "",
      I_Type: "",
      Supp_Ref_No: "",
      Display_Desc: "",
      Sales_Price: "",
    },
    defaultItemInv: {
      id: "",
      I_Type: "",
      Supp_Ref_No: "",
      Display_Desc: "",
      Sales_Price: "",
    },
    formatter: new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }),
  beforeMount: function () {
    let self = this;
    self.getSystemSetup();
    self.getSupplier();
    self.getInventories();

    if (this.$route.params.id > "-1") {
      self.getPOrderDetailsByPOrderId();
    } else {
      self.setDateNow();
      this.isNewPOrder = true;
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id === "-1"
        ? "New Purchase Order"
        : "Edit Purchase Order: " + this.$route.params.id;
    },
    calcTotal() {
      return this.porderItemList
        .map((stotal) => stotal.Total)
        .reduce((total, amount) => total + amount);
    },
    calculateSubTotals() {
      return this.porderItemList.map((stotal) => {
        let sum = 0;
        sum += Number(stotal.Total);
        return sum;
      });
    },
    getUserFull: function () {
      return localStorage.getItem("fullname");
    },
    getUserEmail: function () {
      return localStorage.getItem("useremail");
    },
  },
  methods: {
    uploadFile(e) {
      this.file = e;
    },
    send() {
      this.user = this.getUserEmail;
      this.recipient = {
        name: "Jaco",
        email: this.editedEmailItem.email,
      };
      this.mailsubject = this.editedEmailItem.subject;
      this.mailBody = this.editedEmailItem.textBody;

      this.mail;
      this.status = "";
      this.loading = "Sending email, please wait....";
      const formData = new FormData();
      formData.append("user", JSON.stringify(this.user));
      formData.append("recipient", JSON.stringify(this.recipient));
      formData.append("mailsubject", JSON.stringify(this.mailsubject));
      formData.append("mailBody", JSON.stringify(this.mailBody));

      if (this.file) {
        formData.append("files", this.file, this.file.name);
        this.axios
          .post(`${this.$hostname}/api/sendmail`, formData, {
            headers: {
              "x-access-token": localStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.loading = "";
            this.status = res.data.message;
          });
      } else {
        this.axios
          .post(`${this.$hostname}/api/sendmail`, formData, {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          })
          .then((res) => {
            this.loading = "";
            this.status = res.data.message;
          });
      }
    },
    getSystemSetup: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/systemsetup`,
      }).then((response) => {
        this.systemsetup = response.data;
        this.Company = response.data[0].Company;
        this.Address = response.data[0].Address;
        this.Area = response.data[0].Area;
        this.City = response.data[0].City;
        this.Code = response.data[0].Code;
        this.Tel = response.data[0].Tel;

        this.Website = response.data[0].Website;
        this.Email = response.data[0].Email;
        this.Owner = response.data[0].Owner;
        this.TelNo = response.data[0].Tel;
        this.CellNo = response.data[0].Cell;
        this.FaxNo = response.data[0].Fax;
        this.AddStreet = response.data[0].Address;
        this.AddArea = response.data[0].Area;
        this.AddCity = response.data[0].City;
        this.AddAreaCode = response.data[0].Code;
        this.RegNumber = response.data[0].RegNo;
        this.VatRegNo = response.data[0].VatRegNo;
      });
    },

    getSupplier: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/suppliers`,
      }).then((response) => {
        this.supplierdb = response.data;
      });
    },

    getInventories: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/inventories`,
      }).then((response) => (this.inventories = response.data));
    },

    getPOrderDetailsByPOrderId() {
      const data = {
        id: this.$route.params.id,
      };
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/purchasedetails/${this.$route.params.id}`,
        data: data,
      }).then((response) => {
        this.editedItem.PO_No = response.data.PO_No;
        this.editedItem.PO_Date = response.data.PO_Date;

        var supplierdbIndex = this.supplierdb.findIndex(
          (data) => data.Supplier === response.data.Supplier
        );

        this.editedItem.Supplier = this.supplierdb[supplierdbIndex].id;
        this.editedItem.Add1 = this.supplierdb[supplierdbIndex].Add1;
        this.editedItem.Add2 = this.supplierdb[supplierdbIndex].Add2;
        this.editedItem.Add3 = this.supplierdb[supplierdbIndex].Add3;
        this.editedItem.Add4 = this.supplierdb[supplierdbIndex].Add4;
        this.editedItem.TelNo = this.supplierdb[supplierdbIndex].TelNo;
        this.editedItem.Email = this.supplierdb[supplierdbIndex].Email;
        this.AccountNo = this.supplierdb[supplierdbIndex].AccNo;

        this.editedItem.Sub_Total = response.data.Sub_Total;
        this.editedItem.Vat = response.data.Vat;
        this.editedItem.Grand_Total = response.data.Grand_Total;

        this.dbres = response.data;
        this.getPOrderByPOrderNo();
      });
    },

    setDateNow() {
      var today = new Date();
      this.editedItem.PO_Date = today.toISOString().substring(0, 10);
    },
    getPOrderByPOrderNo() {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/purchaseorders/${this.$route.params.id}`,
      }).then((response) => {
        this.porderItemList = response.data;
        if (this.enableNewItem == false) {
          this.enableNewItem = !this.enableNewItem;
        }
        for (let i = 0; i < this.porderItemList.length; i++) {
          this.porderItemList[i].id = uid(6);
          this.porderItemList[i].Qty = response.data[i].count;
          this.porderItemList[i].ItemCode = response.data[i].ItemCode;
          this.porderItemList[i].StockCode = response.data[i].StockCode;
          this.porderItemList[i].Description = response.data[i].Description;
          this.porderItemList[i].Total = response.data[i].Amount;
        }
      });
    },

    setPOrderNo: function () {
      if ((this.$route.params.id = "-1")) {
        this.axios({
          method: "get",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          url: `${this.$hostname}/api/purchasedetails/countsel`,
        }).then((response) => {
          var POrderNo = response.data;
          POrderNo++;
          this.editedItem.PO_No = POrderNo;
          if (this.isNewPOrder == true) {
            this.isNewPOrder = !this.isNewPOrder;
          }
          this.postInsertPOrderDetails();
        });
      }
    },
    postInsertPOrderDetails: function () {
      var supplierdbIndex = this.supplierdb.findIndex(
        (data) => data.Supplier === this.editedItem.Supplier.Supplier
      );
      const data = {
        PO_No: this.editedItem.PO_No,
        Supplier: this.supplierdb[supplierdbIndex].Supplier,
        Sub_Total: this.editedItem.Sub_Total,
        Vat: this.editedItem.Vat,
        Vat_Select: this.editedItem.Vat_Select,
        Grand_Total: this.editedItem.Grand_Total,
        Quote_No: this.editedItem.Quote_No,
        Paid: this.editedItem.Paid,
        PO_Date: this.editedItem.PO_Date,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/purchasedetails`,
        data: data,
      })
        .then((response) => {
          this.dbres = response.data;
          this.postInsertPOrderList();
        })
        .catch((error) => {
          // console.log(error);
          if (error.response) {
            // console.log(error.response.data.message);
            alert(error.response.data.message);
          }
          // if (error.response) {
          //   if (error.response.status == 401 || error.response.status == 404) {
          //     this.alerterror = true;
          //   }
          // }
        });
    },
    postInsertPOrderList: function () {
      var supplierdbIndex = this.supplierdb.findIndex(
        (data) => data.id === this.editedItem.Supplier
      );
      if (supplierdbIndex == -1) {
        var supplierdbIndex = this.supplierdb.findIndex(
          (data) => data.Supplier === this.editedItem.Supplier.Supplier
        );
      }
      const sendPostRequest = async () => {
        for (let i = 0; i < this.porderItemList.length; i++) {
          const data = {
            PO_No: this.editedItem.PO_No,
            Supplier: this.supplierdb[supplierdbIndex].Supplier,
            ItemCode: this.porderItemList[i].ItemCode,
            StockCode: this.porderItemList[i].StockCode,
            Description: this.porderItemList[i].Description,
            count: this.porderItemList[i].Qty,
            Price: this.porderItemList[i].Price,
            Amount: this.porderItemList[i].Total,
          };
          try {
            const resp = await this.axios({
              method: "post",
              mode: "no-cors",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
              url: `${this.$hostname}/api/purchaseorders`,
              data: data,
            });
          } catch (err) {
            console.error(err);
          }
        }
      };
      sendPostRequest();
    },
    UpdateSupplierDetails() {
      var supplierdbIndex = this.supplierdb.findIndex(
        (data) => data === this.editedItem.Supplier
      );
      this.editedItem.Add1 = this.supplierdb[supplierdbIndex].Add1;
      this.editedItem.Add2 = this.supplierdb[supplierdbIndex].Add2;
      this.editedItem.Add3 = this.supplierdb[supplierdbIndex].Add3;
      this.editedItem.Add4 = this.supplierdb[supplierdbIndex].Add4;
      this.editedItem.TelNo = this.supplierdb[supplierdbIndex].TelNo;
      this.editedItem.Email = this.supplierdb[supplierdbIndex].Email;
      this.AccountNo = this.supplierdb[supplierdbIndex].AccNo;

      if (this.enableNewItem == false) {
        this.enableNewItem = !this.enableNewItem;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedPOrderIndex > -1) {
          Object.assign(
            this.porderItemList[this.editedPOrderIndex],
            this.editedItemPOrder
          );
        } else {
          this.editedItemPOrder.id = uid(6);
          this.porderItemList.push(this.editedItemPOrder);
        }
        this.$refs.form.resetValidation();
        this.closePOrder();
      }
    },
    validatePOrder() {
      if (this.porderItemList.length == 0) {
        alert("Please add purchase order items");
      } else {
        if (this.porderSaved == false) {
          this.porderSaved = !this.porderSaved;
          this.enabledPrint = !this.enabledPrint;
          this.enableCancel = !this.enableCancel;
          this.enableClient = !this.enableClient;
          this.enableClose = !this.enableClose;
          this.enabledEmail = !this.enabledEmail;
        }
        if (this.isNewPOrder) {
          this.setPOrderNo();
        } else {
          this.updatePOrder();
        }
      }
    },
    updatePOrder() {
      this.updatePOrderDetails();
    },
    updatePOrderDetails() {
      const data = {
        PO_No: this.editedItem.PO_No,
        Supplier: this.editedItem.Supplier.AccNo,
        Sub_Total: this.editedItem.Sub_Total,
        Vat: this.editedItem.Vat,
        Vat_Select: this.editedItem.Vat_Select,
        Grand_Total: this.editedItem.Grand_Total,
        Quote_No: this.editedItem.Quote_No,
        Paid: this.editedItem.Paid,
        PO_Date: this.editedItem.PO_Date,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/purchasedetails/${this.editedItem.PO_No}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        this.deletePOrder();
      });
    },
    deletePOrder() {
      this.axios({
        method: "delete",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/purchaseorders/${this.editedItem.PO_No}`,
      }).then((response) => {
        this.dbres = response.data;
        this.postInsertPOrderList();
      });
    },
    closePOrder() {
      this.dialog = false;
      this.editedItemPOrder = Object.assign({}, this.defaultItemPOrder);
      this.editedPOrderIndex = -1;
      this.$refs.form.resetValidation();
      this.sumTotal();
    },
    editItem(item) {
      this.editedPOrderIndex = this.porderItemList.indexOf(item);
      this.editedItemPOrder = Object.assign({}, item);
      this.dialog = true;
    },
    editItemInventory(item) {
      this.editedIndexInv = this.inventories.indexOf(item);
      this.editedItemInv = Object.assign({}, item);
      this.editedItemPOrder.ItemCode = this.editedItemInv.I_Type;
      this.editedItemPOrder.StockCode = this.editedItemInv.Supp_Ref_No;
      this.editedItemPOrder.Description = this.editedItemInv.Display_Desc;
      this.editedItemPOrder.Price = this.editedItemInv.Sales_Price;
      this.searchinventory = null;
      this.dialog3 = false;
    },
    deleteItem(item) {
      this.id = item.id;
      const index = this.porderItemList.indexOf(item);
      if (confirm("Are you sure you want to delete this Type?")) {
        this.porderItemList.splice(index, 1);
        this.sumTotal();
      }
    },
    sumTotal() {
      let porder_total = 0;
      let vat = 0;
      let Total = 0;
      this.porderItemList.forEach((val) => {
        porder_total += Number(val.Total);
      });
      this.editedItem.Sub_Total = this.formatNumber(porder_total);
      if (this.systemsetup[0].Use_Vat == 1) {
        vat = porder_total * 1.15 - porder_total;
      }
      this.editedItem.Vat = this.formatNumber(vat);

      Total = this.formatNumber(porder_total + vat);

      this.editedItem.Grand_Total = Total;
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    formatCurrency(num) {
      return this.formatter
        .format(num)
        .replace(/[^0-9\,.]/, "")
        .trim();
    },
    formatNumberTotal() {
      let sTotal = 0;
      let Qty = -1;
      let Price = -1;
      Qty = this.editedItemPOrder.Qty;
      Price = this.editedItemPOrder.Price;

      if (Qty >= 0) {
        if (Price > 0) {
          sTotal = Qty * Price;
        }
      }

      let temp = parseFloat(sTotal).toFixed(2);
      this.editedItemPOrder.Total = temp;
      // return temp;
    },
    close() {
      this.$router.push("/POrder");
    },
    closeDialog2() {
      this.dialog2 = false;
      this.editedEmailItem = Object.assign({}, this.defaultEmailItem);
      this.$refs.vdialog2.resetValidation();
      this.file = null;
    },
    closeInv() {
      this.dialog3 = false;
      this.editedItemInv = Object.assign({}, this.defaultItemInv);
      this.editedIndexInv = -1;
      this.$refs.form.resetValidation();
      this.searchinventory = null;
    },
    emailInv() {
      this.dialog2 = true;
    },
    print() {
      var fontSizes = {
        HeadTitleFontSize: 18,
        Head2TitleFontSize: 16,
        TitleFontSize: 14,
        SubTitleFontSize: 12,
        NormalFontSize: 10,
        SmallFontSize: 8,
      };
      var lineSpacing = {
        NormalSpacing: 12,
      };
      const columns = [
        { title: "Qty", dataKey: "Qty" },
        { title: "Item Code", dataKey: "ItemCode" },
        { title: "Stock Code", dataKey: "StockCode" },
        { title: "Description", dataKey: "Description" },
        { title: "Unit Price", dataKey: "Price" },
        { title: "Total", dataKey: "Total" },
      ];

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        lineHeights: 20,
      });

      doc.setFont("Times", "italic");
      doc.setFont(undefined, "bold");
      doc.setTextColor(192, 192, 192);
      doc.setFontSize(20).text(this.heading, 105, 20, { align: "center" });

      const logo = require("@/assets/logo.png");
      var imgLogo = new Image();
      imgLogo.src = logo;
      doc.addImage(imgLogo, "PNG", 12.7, 39, 30, 30);

      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "normal");

      if (this.systemsetup[0].Use_Vat == 1) {
        doc
          .setFontSize(8)
          .text(`Vat Reg No: ${this.VatRegNo}`, 105, 26, { align: "center" });
        doc.setFontSize(12).text(this.Company, 105, 32, { align: "center" });
      } else {
        doc.setFontSize(12).text(this.Company, 105, 26, { align: "center" });
      }

      doc.rect(146, 34, 50, 38);

      doc.setFontSize(8);
      doc.setFont("Times");
      doc.setFont(undefined, "normal");
      doc.text(this.getUserFull, 148, 39);
      doc.setFont(undefined, "normal");
      doc.text(this.CellNo, 180, 39);

      doc.setFont(undefined, "normal");
      doc.text("Tel No:", 148, 44);
      doc.setFont(undefined, "normal");
      if (this.TelNo) {
        doc.text(this.TelNo, 180, 44);
      }

      doc.setFont(undefined, "normal");
      doc.text("Fax No:", 148, 49);
      if (this.FaxNo) {
        doc.setFont(undefined, "normal");
        doc.text(this.FaxNo, 180, 49);
      }

      doc.setFont(undefined, "normal");
      doc.text(this.Website, 171, 54, "center");
      doc.setFont(undefined, "normal");
      doc.text(this.getUserEmail, 171, 59, "center");

      doc.setFont(undefined, "normal");
      doc.text("Purchase Order No:", 148, 64);
      doc.setFont(undefined, "normal");

      doc.text(this.editedItem.PO_No.toString(), 180, 64);

      doc.setFont(undefined, "normal");
      doc.text("Date:", 148, 69);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.PO_Date, 180, 69);

      doc.setFontSize(10);
      //Bill To
      doc.setDrawColor(0);
      doc.setFillColor(41, 128, 186);
      // doc.setFillColor(192, 192, 192);
      doc.rect(12.7, 75, 75, 5.6, "FD");
      doc.setTextColor(255, 255, 255);
      //doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text("Supplier:", 13.7, 79);

      doc.setDrawColor(0);
      doc.setFillColor(225, 225, 225);
      doc.rect(12.7, 81, 75, 38, "FD");

      doc.setTextColor(0, 0, 0);
      //Client
      doc.setFontSize(8);
      var supplierdbIndex = this.supplierdb.findIndex(
        (data) => data.id === this.editedItem.Supplier
      );

      if (supplierdbIndex == -1) {
        var supplierdbIndex = this.supplierdb.findIndex(
          (data) => data.Supplier === this.editedItem.Supplier.Supplier
        );
      }

      let Supplier = this.supplierdb[supplierdbIndex].Supplier;

      doc.setFont(undefined, "bold");
      doc.text("Supplier:", 13.7, 86);
      doc.setFont(undefined, "normal");
      doc.text(Supplier, 30, 86);

      //Street
      doc.setFont(undefined, "bold");
      doc.text("Street:", 13.7, 91);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Add1, 30, 91);

      //Area
      doc.setFont(undefined, "bold");
      doc.text("Area:", 13.7, 96);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Add2, 30, 96);

      //City
      doc.setFont(undefined, "bold");
      doc.text("City:", 13.7, 101);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Add3, 30, 101);

      //Code
      doc.setFont(undefined, "bold");
      doc.text("Code:", 13.7, 106);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Add4, 30, 106);

      //Tel No
      doc.setFont(undefined, "bold");
      doc.text("Tel No:", 13.7, 111);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.TelNo, 30, 111);

      //Email
      doc.setFont(undefined, "bold");
      doc.text("Email:", 13.7, 116);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Email, 30, 116);

      //Deliver To
      doc.setFontSize(10);
      doc.setDrawColor(0);
      doc.setFillColor(41, 128, 186);
      doc.setTextColor(255, 255, 255);
      // doc.setFillColor(192, 192, 192);
      doc.rect(121, 75, 75, 5.6, "FD");
      doc.setFont(undefined, "bold");
      doc.text("Deliver To:", 122, 79);

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(225, 225, 225);
      doc.rect(121, 81, 75, 38, "FD");

      //company
      doc.setFont(undefined, "bold");
      doc.text("Company:", 122, 86);
      doc.setFont(undefined, "normal");
      doc.text(this.Company, 138.3, 86);

      //Street
      doc.setFont(undefined, "bold");
      doc.text("Street:", 122, 91);
      doc.setFont(undefined, "normal");
      if (this.AddStreet) {
        doc.text(this.AddStreet, 138.3, 91);
      }
      //Area
      doc.setFont(undefined, "bold");
      doc.text("Area:", 122, 96);
      doc.setFont(undefined, "normal");
      doc.text(this.AddArea, 138.3, 96);

      //City
      doc.setFont(undefined, "bold");
      doc.text("City:", 122, 101);
      doc.setFont(undefined, "normal");
      doc.text(this.AddCity, 138.3, 101);

      //Code
      doc.setFont(undefined, "bold");
      doc.text("Code:", 122, 106);
      doc.setFont(undefined, "normal");
      doc.text(this.AddAreaCode, 138.3, 106);

      //Tel No
      doc.setFont(undefined, "bold");
      doc.text("Tel No:", 122, 111);
      doc.setFont(undefined, "normal");
      doc.text(this.Tel, 138.3, 111);

      //Acc No
      doc.setFont(undefined, "bold");
      doc.text("Account No:", 122, 116);
      doc.setFont(undefined, "normal");
      doc.text(this.AccountNo, 138.3, 116);

      // Using array of sentences
      doc.setFont("helvetica");
      doc.setFontSize(12);
      // doc.text(this.moreText, 12.7, 60, { align: "left", maxWidth: "190" });

      doc.setLineWidth(0.05).line(12.7, 120, 196, 120);

      // Using autoTable plugin
      doc.autoTable({
        columns,
        body: this.porderItemList,
        // body: this.items,
        margin: { left: 12.7, top: 121 },
        // theme: "grid",
        columnStyles: {
          halign: "right",
          //   0: {
          //     columnWidth: 15,
          //   },
          //   1: {
          //     columnWidth: 25,
          //   },
          //   2: {
          //     columnWidth: 80.5,
          //   },
          //   3: {
          //     columnWidth: 24,
          //   },
          //   4: {
          //     columnWidth: 12,
          //   },
          //   5: {
          //     columnWidth: 27,
          //   },
        },
        styles: {
          fontSize: 8,
          font: "times",
          cellPadding: 1,
        },
      });

      // Creating footer and saving file
      doc
        .setLineWidth(0.01)
        .line(
          12.7,
          doc.internal.pageSize.height - 35,
          196,
          doc.internal.pageSize.height - 35
        );

      //Sub Total
      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text("Sub Total:", 150, doc.internal.pageSize.height - 30);
      doc.setFont(undefined, "normal");
      doc.text(
        this.formatCurrency(this.editedItem.Sub_Total),
        196,
        doc.internal.pageSize.height - 30,
        "right"
      );

      let vHeight = 30;
      //Vat
      if (this.systemsetup[0].Use_Vat == 1) {
        doc.setFont("Times", "bold");
        doc.text("Vat:", 150, doc.internal.pageSize.height - (vHeight - 5));
        doc.setFont(undefined, "normal");
        doc.text(
          this.formatCurrency(this.editedItem.Vat),
          196,
          doc.internal.pageSize.height - (vHeight - 5),
          "right"
        );
        vHeight = 25;
      }

      //Total
      doc.setFont("Times", "bold");
      doc.text("Total:", 150, doc.internal.pageSize.height - (vHeight - 5));
      doc.setFont(undefined, "normal");
      doc.text(
        this.formatCurrency(this.editedItem.Grand_Total),
        196,
        doc.internal.pageSize.height - (vHeight - 5),
        "right"
      );

      doc.setFont("times");
      doc.setFontSize(8);
      doc.setFont(undefined, "italic");
      //doc.setTextColor(0, 0, 255);//Blue
      doc.setTextColor(0, 0, 0);
      doc
        .setLineWidth(0.01)
        .line(
          12.7,
          doc.internal.pageSize.height - 16,
          196,
          doc.internal.pageSize.height - 16
        );
      doc.text(
        `${this.Company.toUpperCase()} - ${this.AddStreet.toUpperCase()}, ${this.AddArea.toUpperCase()}, ${this.AddCity.toUpperCase()}, ${
          this.AddAreaCode
        }, ${this.TelNo}`,
        105,
        doc.internal.pageSize.height - 12,
        { align: "center" }
      );

      doc
        .setLineWidth(0.01)
        .line(
          12.7,
          doc.internal.pageSize.height - 10,
          196,
          doc.internal.pageSize.height - 10
        );

      doc.save(`${this.heading} ${this.editedItem.PO_No}.pdf`);
    },
  },
};
</script>

<!-- <style lang="scss" scoped></style> -->
