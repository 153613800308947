<template>
  <div class="my-5">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6>
          <v-card class="elevation-12" dense>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Send emails...</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  v-model="editedItem.email"
                  prepend-inner-icon="email"
                  :rules="[rules.email]"
                  name="email"
                  label="To..."
                  type="text"
                  outlined
                  dense
                  hide-details
                  class="d-flex my-1"
                ></v-text-field>
                <v-text-field
                  v-model="editedItem.subject"
                  prepend-inner-icon="subject"
                  :rules="[rules.required]"
                  name="subject"
                  label="Subject..."
                  type="text"
                  outlined
                  dense
                  hide-details
                  class="d-flex my-1"
                ></v-text-field>
                <v-file-input
                  v-model="file"
                  label="File input"
                  outlined
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  dense
                  hide-details
                  class="d-flex my-1"
                  type="file"
                  accept="*"
                  @change="uploadFile"
                >
                </v-file-input>
                <v-flex xs12 sm6 md12>
                  <v-textarea
                    v-model="editedItem.textBody"
                    label="Description"
                    prepend-inner-icon="description"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                  ></v-textarea>
                </v-flex>
              </v-form>
            </v-card-text>
            <v-card-actions>
              {{ status }}
              {{ loading }}
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="send">Send</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    editedItem: {
      email: "",
      subject: "",
      textBody: "",
      attachment: "",
    },
    loading: "",
    status: "",
    attachment: "",
    file: null,
    fileUrl: "",
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  computed: {
    getUserEmail: function () {
      return localStorage.getItem("useremail");
    },
  },
  methods: {
    uploadFile(e) {
      this.file = e;
    },
    send() {
      this.user = this.getUserEmail;
      this.recipient = {
        name: "Jaco",
        email: this.editedItem.email,
      };
      this.mailsubject = this.editedItem.subject;
      this.mailBody = this.editedItem.textBody;

      this.mail;
      this.status = "";
      this.loading = "Sending email, please wait....";
      const formData = new FormData();
      formData.append("user", JSON.stringify(this.user));
      formData.append("recipient", JSON.stringify(this.recipient));
      formData.append("mailsubject", JSON.stringify(this.mailsubject));
      formData.append("mailBody", JSON.stringify(this.mailBody));

      if (this.file) {
        console.log("1");
        formData.append("files", this.file, this.file.name);
        axios
          .post(`${this.$hostname}/api/sendmail`, formData, {
            headers: {
              "x-access-token": localStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.loading = "";
            this.status = res.data.message;
          });
      } else {
        axios
          .post(`${this.$hostname}/api/sendmail`, formData, {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          })
          .then((res) => {
            this.loading = "";
            this.status = res.data.message;
          });
      }
    },
  },
};
</script>
