<template>
  <div class="my-5">
    <v-main>
      <v-container fluid fill-height>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="8" md="4" lg="3">
            <v-card class="elevation-11">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Reset Password</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="newPassword"
                    prepend-inner-icon="mdi-lock"
                    name="newPassword"
                    label="Password"
                    type="password"
                    outlined
                    :rules="[rules.password]"
                  ></v-text-field>
                  <v-text-field
                    v-model="pwdConfirm"
                    prepend-inner-icon="mdi-lock"
                    name="pwdConfirm"
                    label="Confirm Password"
                    type="password"
                    outlined
                    :rules="[
                      (v) => v === this.newPassword || 'Passwords do not match',
                    ]"
                  ></v-text-field>
                  <v-btn color="primary" x-large block @click="validate"
                    >Reset Password</v-btn
                  >
                </v-form>
              </v-card-text>
              <v-card-actions>
                {{ status }}
                {{ loading }}
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: "",
    status: "",
    newPassword: "",
    pwdConfirm: "",
    userId: "",
    token: "",
    rules: {
      required: (value) => !!value || "Required.",
      password: (value) => {
        const pattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 characters with at least one capital letter, a number and a special character."
        );
      },
    },
    pwdConfirms: [
      (v) => !!v || "Confirm password",
      (v) => v === this.newPassword || "Passwords do not match",
    ],
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.sendReset();
        this.$refs.form.resetValidation();
      }
    },
    sendReset: function () {
      this.status = "";
      this.loading = "Sending reset request, please wait....";
      const data = {
        password: this.newPassword,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/auth/reset-password/${this.$route.params.id}/${this.$route.params.token}`,
        data: data,
      }).then((response) => {
        this.loading = "";
        this.status = response.data.message;
        this.$router.push("/");
      });
    },
  },
};
</script>
