<template>
  <div class="dashboard">
    <h1 class="subheading grey--text">Dashboard</h1>
    <v-container class="my-5">
      <v-card flat class="pa-3" v-for="mytask in mytasks" :key="mytask.title">
        <v-row :class="`mytask ${mytask.task_status}`">
          <v-col cols="12" md="6">
            <div class="caption grey--text">Project Title</div>

            <div>{{ mytask.task_name }}</div>
          </v-col>

          <v-col xs="2">
            <div class="caption grey--text">Person</div>

            <div>{{ mytask.task_user }}</div>
          </v-col>

          <v-col xs="2">
            <div class="caption grey--text">Due Date</div>

            <div>{{ mytask.task_date_due }}</div>
          </v-col>

          <v-col xs="2">
            <div class="caption grey--text">Status</div>

            <div>
              <v-chip
                small
                class="white--text"
                caption
                :color="getColor(mytask.task_status)"
              >
                {{ mytask.task_status }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import format from "date-fns/format";
// import { read } from "fs";
export default {
  data: () => ({
    mytasks: [],
  }),
  beforeMount: function () {
    let self = this;
    self.getMyTasks();
  },
  computed: {
    // formattedDate(date) {
    //   return this.date ? format(this.date, "") : "";
    // },
  },
  methods: {
    getMyTasks: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/mytasks`,
      }).then((response) => {
        this.mytasks = response.data;
      });
    },
    sortBy(prop) {
      this.tickets.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
      return 1;
    },

    getColor(item) {
      var newColor = item;
      if (newColor == "Complete") {
        return "#3cd1c2";
      } else if (newColor == "Open") {
        return "#2926d4";
      } else if (newColor == "Overdue") {
        return "rgb(218, 16, 16)";
      } else if (newColor == "Bug") {
        return "rgb(218, 16, 16)";
      } else if (newColor == "Prod") {
        return "#3cd1c2";
      } else if (newColor == "Pending") {
        return "orange";
      } else if (newColor == "Development") {
        return "rgb(23, 148, 44)";
      }
    },
  },
};
</script>

<style>
.mytask.Complete {
  border-left: 4px solid #3cd1c2;
}

.mytask.Open {
  border-left: 4px solid #2926d4;
}

.mytask.Overdue {
  border-left: 4px solid rgb(218, 16, 16);
}

.mytask.Bug {
  border-left: 4px solid rgb(218, 16, 16);
}

.mytask.Prod {
  border-left: 4px solid rgb(218, 16, 16);
}

.mytask.Pending {
  border-left: 4px solid orange;
}

.mytask.Ongoing {
  border-left: 4px solid rgb(23, 148, 44);
}

.mytask.Development {
  border-left: 4px solid rgb(23, 148, 44);
}

.theme--light.v-chip.Complete:not(.v-chip--active) {
  background: #3cd1c2;
}

.theme--light.v-chip.Open:not(.v-chip--active) {
  background: #2926d4;
}

.theme--light.v-chip.Overdue:not(.v-chip--active) {
  background: rgb(218, 16, 16);
}

.theme--light.v-chip.Pending:not(.v-chip--active) {
  background: orange;
}

.theme--light.v-chip.Ongoing:not(.v-chip--active) {
  background: rgb(23, 148, 44);
}

.theme--light.v-chip.Bug:not(.v-chip--active) {
  background: rgb(218, 16, 16);
}

.theme--light.v-chip.Prod:not(.v-chip--active) {
  background: rgb(218, 16, 16);
}
</style>
