<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="inventories"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Inventory</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              color="primary"
              class="mb-2"
              @click="
                $router.push({
                  name: 'InventoryModal',
                  params: { id: '-1' },
                })
              "
            >
              New
            </v-btn>
          </div>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
              New
            </v-btn>
          </template>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
import { uid } from "uid";
export default {
  data: () => ({
    id: "",
    search: "",
    headers: [
      {
        text: "Item Code",
        align: "left",
        sortable: true,
        value: "I_Type",
        width: "10%",
      },
      { text: "Type Descr", value: "Name", width: "15%" },
      { text: "Description", value: "Display_Desc", width: "35%" },
      { text: "Supplier", value: "Supplier", width: "15%" },
      { text: "Ref No", value: "Supp_Ref_No", width: "10%" },
      { text: "Stock Level", value: "Stock_Level", width: "10%" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "5%",
      },
    ],
    inventories: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      Name: "",
      Description: "",
      Display_Desc: "",
      Supplier: "",
      Supp_Ref_No: "",
      Stock_Level: "",
      Min_Stock_Level: "",
      I_Type: "",
      Inv_Type: "",
      Price_Unit: "",
      Unit: "",
      Vat: "",
      Total_Inc: "",
      Qty: "",
      Price_Qty: "",
      Markup: "",
      Sales_Price: "",
    },
    defaultItem: {
      id: "",
      Name: "",
      Description: "",
      Display_Desc: "",
      Supplier: "",
      Supp_Ref_No: "",
      Stock_Level: "",
      Min_Stock_Level: "",
      I_Type: "",
      Inv_Type: "",
      Price_Unit: "",
      Unit: "",
      Vat: "",
      Total_Inc: "",
      Qty: "",
      Price_Qty: "",
      Markup: "",
      Sales_Price: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getInventories();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Type" : "Edit Type";
    },
  },
  watch: {},
  created() {},
  methods: {
    getInventories: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/inventories`,
      }).then((response) => (this.inventories = response.data));
    },
    editItem(item) {
      this.editedIndex = this.inventories.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push({
        name: "InventoryModal",
        params: { id: this.editedItem.id },
      });
    },
    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  width: 100%;
}

.table-heading,
.table-items {
  gap: 5px;
  font-size: 12px;
}

.item-name {
  flex-basis: 50%;

  .item {
    flex-basis: 10%;
  }

  .qty {
    flex-basis: 5%;
  }

  .price {
    flex-basis: 15%;
  }

  .discount {
    flex-basis: 5%;
  }

  .total {
    flex-basis: 15%;
  }

  .table-heading {
    margin-bottom: 16px;
  }

  th {
    text-align: center;
  }

  td {
    text-align: center;
  }

  .button {
    color: #fff;
    // background-color: #252945;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  img {
    margin-right: 4px;
  }

  .table-items {
    position: relative;
    margin-bottom: 24px;

    img {
      position: absolute;
      top: 15px;
      right: 0;
      width: 12px;
      height: 16px;
    }
  }

  .input {
    margin-bottom: 24px;
  }

  input,
  select {
    width: 100%;
    background-color: #1e2139;
    color: #fff;
    border-radius: 4px;
    padding: 12px 4px;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .save {
    margin-top: 60px;

    div {
      flex: 1;
    }

    .right {
      justify-content: flex-end;
    }
  }
}
</style>
