import { render, staticRenderFns } from "./SOrderModal.vue?vue&type=template&id=320b5025&scoped=true&"
import script from "./SOrderModal.vue?vue&type=script&lang=js&"
export * from "./SOrderModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "320b5025",
  null
  
)

export default component.exports