<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="supplier"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Supplier</v-toolbar-title>

          <v-divider class="mx-3" inset vertical left></v-divider>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Supplier
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-row>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.Supplier"
                          label="Supplier Name"
                          prepend-inner-icon="person"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                          counter
                        ></v-text-field>
                      </v-col>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.AccNo"
                          label="Account No"
                          prepend-inner-icon="person"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                          counter
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.TelNo"
                          label="Telephone No"
                          prepend-inner-icon="person"
                          :rules="[rules.required, rules.min]"
                          clearable
                          dense
                          filled
                          outlined
                          counter
                          maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        ></v-text-field>
                      </v-col>

                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.FaxNo"
                          label="Fax No"
                          prepend-inner-icon="person"
                          clearable
                          dense
                          filled
                          outlined
                          counter
                          maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.Email"
                          label="Email"
                          prepend-inner-icon="mdi-email"
                          :rules="[rules.required, rules.email]"
                          clearable
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.Add1"
                          label="Street"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.Add2"
                          label="Area"
                          append-icon="search"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                          @click:append="dialog2 = !dialog2"
                        ></v-text-field>
                      </v-col>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.Add3"
                          label="City"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                          clearable
                          dense
                          filled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.Add4"
                          label="Area Code"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required, rules.counteraddMin]"
                          clearable
                          dense
                          filled
                          outlined
                          maxlength="4"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="close"
                  >Cancel</v-btn
                >
                <v-btn color="primary" dark class="mb-2" @click="validate"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog2" max-width="700px">
            <v-card>
              <v-card-text>
                <v-container grid-list-md>
                  <v-data-table
                    :headers="headersarea"
                    :items="areacodes"
                    :search="searcharea"
                    sort-by="calories"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Area Search</v-toolbar-title>
                        <v-divider class="mx-3" inset vertical left></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="searcharea"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItemArea(item)">
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon small @click="closeDialog2()">
                        mdi-cancel
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="closeAdd"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    dialogDelete: false,
    show1: false,
    id: "",
    search: "",
    searcharea: "",
    rules: {
      required: (value) => !!value || "Required.",
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      min: (v) => v.length >= 10 || "Min 10 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Supplier Name",
        align: "left",
        sortable: true,
        value: "Supplier",
      },
      { text: "Telephone No", value: "TelNo" },
      { text: "Fax No", value: "FaxNo" },
      { text: "Email", value: "Email" },
      { text: "Account No", value: "AccNo" },
      { text: "Address 1", value: "Add1" },
      { text: "Address 2", value: "Add2" },
      { text: "Address 3", value: "Add3" },
      { text: "Address 4", value: "Add4" },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],

    headersarea: [
      {
        text: "Area",
        align: "left",
        sortable: true,
        value: "area",
      },
      { text: "Code", value: "code" },
      { text: "City", value: "city" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    areacodes: [],
    supplier: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      Supplier: "",
      TelNo: "",
      FaxNo: "",
      Email: "",
      AccNo: "",
      Add1: "",
      Add2: "",
      Add3: "",
      Add4: "",
    },
    defaultItem: {
      id: "",
      Supplier: "",
      TelNo: "",
      FaxNo: "",
      Email: "",
      AccNo: "",
      Add1: "",
      Add2: "",
      Add3: "",
      Add4: "",
    },
    editedItemArea: {
      id: "",
      area: "",
      code: "",
      city: "",
    },
    defaultItemArea: {
      id: "",
      area: "",
      code: "",
      city: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getSuppliers();
    self.getAreaCodes();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Supplier" : "Edit Supplier";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    getSuppliers: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/suppliers`,
      }).then((response) => (this.supplier = response.data));
    },
    postInsertSupplier: function () {
      const data = {
        Supplier: this.editedItem.Supplier,
        TelNo: this.editedItem.TelNo,
        FaxNo: this.editedItem.FaxNo,
        Email: this.editedItem.Email,
        AccNo: this.editedItem.AccNo,
        Add1: this.editedItem.Add1,
        Add2: this.editedItem.Add2,
        Add3: this.editedItem.Add3,
        Add4: this.editedItem.Add4,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/suppliers`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postUpdateSupplier: function () {
      const data = {
        Supplier: this.editedItem.Supplier,
        TelNo: this.editedItem.TelNo,
        FaxNo: this.editedItem.FaxNo,
        Email: this.editedItem.Email,
        AccNo: this.editedItem.AccNo,
        Add1: this.editedItem.Add1,
        Add2: this.editedItem.Add2,
        Add3: this.editedItem.Add3,
        Add4: this.editedItem.Add4,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/suppliers/${this.editedItem.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postDeleteSupplier: function () {
      this.axios({
        method: "delete",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/suppliers/${this.id}`,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    getAreaCodes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/areacodes`,
      }).then((response) => {
        this.areacodes = response.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.supplier.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    editItemArea(item) {
      this.editedIndexArea = this.areacodes.indexOf(item);
      this.editedItemArea = Object.assign({}, item);
      this.editedItem.Add2 = this.editedItemArea.area;
      this.editedItem.Add3 = this.editedItemArea.city;
      this.editedItem.Add4 = this.editedItemArea.code;
      this.searcharea = null;
      this.dialog2 = false;
    },

    deleteItem(item) {
      this.id = item.id;
      const index = this.supplier.indexOf(item);
      if (confirm("Are you sure you want to delete this supplier?")) {
        this.supplier.splice(index, 1);
        this.postDeleteSupplier();
      }
    },

    close() {
      this.dialog = false;
      // setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      // }, 300)
      this.$refs.form.resetValidation();
    },
    closeAdd() {
      this.dialog2 = false;
      this.editedItemArea = Object.assign({}, this.defaultItemArea);
      this.editedIndexArea = -1;
      this.$refs.form.resetValidation();
      this.searcharea = null;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.supplier[this.editedIndex], this.editedItem);
      } else {
        this.supplier.push(this.editedItem);
      }
      this.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.supplier[this.editedIndex], this.editedItem);
          this.postUpdateSupplier();
        } else {
          this.postInsertSupplier();
          this.supplier.push(this.editedItem);
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
  },
  closeDialog2() {
    this.dialog2 = false;
    this.searcharea = null;
  },
};
</script>
