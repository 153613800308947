<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="invoicedetails"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Invoice</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              color="primary"
              class="mb-2"
              @click="
                $router.push({
                  name: 'InvoiceModal',
                  params: { id: '-1' },
                })
              "
            >
              New
            </v-btn>
          </div>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
              New
            </v-btn>
          </template>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
import { uid } from "uid";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    show1: false,
    id: "",
    search: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Invoice No",
        align: "left",
        sortable: true,
        value: "Invoice_No",
        width: "10%",
      },
      { text: "Client", value: "Client_Acc", width: "40%" },
      { text: "Total", value: "Grand_Total", width: "10%" },
      { text: "Total Paid", value: "Amt_Paid", width: "10%" },
      { text: "Total Due", value: "Amt_Due", width: "10%" },
      { text: "Paid", value: "Paid", width: "10%" },
      { text: "Inv Date", value: "Inv_Date", width: "10%" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    invoicedetails: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      Invoice_No: "",
      Client_Acc: "",
      Sub_Total: "",
      Vat: "",
      Vat_Select: "",
      Grand_Total: "",
      Amt_Paid: "",
      Amt_Due: "",
      Quote_No: "",
      Paid: "",
      Cust_Memo: "",
      Inv_Date: "",
      Deliver_Date: "",
    },
    defaultItem: {
      id: "",
      Invoice_No: "",
      Client_Acc: "",
      Sub_Total: "",
      Vat: "",
      Vat_Select: "",
      Grand_Total: "",
      Amt_Paid: "",
      Amt_Due: "",
      Quote_No: "",
      Paid: "",
      Cust_Memo: "",
      Inv_Date: "",
      Deliver_Date: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getInvoiceDetails();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Type" : "Edit Type";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {},
  methods: {
    getInvoiceDetails: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/invoicesdetails`,
      }).then((response) => (this.invoicedetails = response.data));
    },
    editItem(item) {
      this.editedIndex = this.invoicedetails.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push({
        name: "InvoiceModal",
        params: { id: this.editedItem.Invoice_No },
      });
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  width: 100%;
}

.table-heading,
.table-items {
  gap: 5px;
  font-size: 12px;
}

.item-name {
  flex-basis: 50%;

  .item {
    flex-basis: 10%;
  }

  .qty {
    flex-basis: 5%;
  }

  .price {
    flex-basis: 15%;
  }

  .discount {
    flex-basis: 5%;
  }

  .total {
    flex-basis: 15%;
  }

  .table-heading {
    margin-bottom: 16px;
  }

  th {
    text-align: center;
  }

  td {
    text-align: center;
  }

  .button {
    color: #fff;
    // background-color: #252945;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  img {
    margin-right: 4px;
  }

  .table-items {
    position: relative;
    margin-bottom: 24px;

    img {
      position: absolute;
      top: 15px;
      right: 0;
      width: 12px;
      height: 16px;
    }
  }

  .input {
    margin-bottom: 24px;
  }

  input,
  select {
    width: 100%;
    background-color: #1e2139;
    color: #fff;
    border-radius: 4px;
    padding: 12px 4px;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .save {
    margin-top: 60px;

    div {
      flex: 1;
    }

    .right {
      justify-content: flex-end;
    }
  }
}
</style>
