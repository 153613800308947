<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="expenses"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Expenses</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              color="primary"
              class="mb-2"
              @click="
                $router.push({
                  name: 'ExpensesModal',
                  params: { id: '-1' },
                })
              "
            >
              New
            </v-btn>
          </div>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    id: "",
    search: "",
    Payment_Date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    headers: [
      { text: "Req No", value: "ReqNo", width: "10%" },
      {
        text: "Inv Date",
        align: "left",
        sortable: true,
        value: "InvDate",
        width: "10%",
      },
      { text: "Supplier", value: "Supplier", width: "10%" },
      { text: "Inv No", value: "Inv No", width: "10%" },
      { text: "Description", value: "Description", width: "30%" },
      { text: "Total Amount", value: "TotalAmt", width: "10%" },
      { text: "Amount Paid", value: "AmtPaid", width: "10%" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    expenses: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      ReqNo: "",
      Category: "",
      InvDate: "",
      Supplier: "",
      InvNo: "",
      Description: "",
      TotalAmt: "",
      AmtPaid: "",
      OutAmt: "",
      DueDate: "",
      MonthDue: "",
      MtnPd: "",
      PaidBy: "",
      CreatedBy: "",
      DateCreated: "",
      PoNo: "",
      Appr_By: "",
      Appr_Date: "",
      Pay_Method: "",
      Payment_No: "",
    },
    defaultItem: {
      id: "",
      ReqNo: "",
      Category: "",
      InvDate: "",
      Supplier: "",
      InvNo: "",
      Description: "",
      TotalAmt: "",
      AmtPaid: "",
      OutAmt: "",
      DueDate: "",
      MonthDue: "",
      MtnPd: "",
      PaidBy: "",
      CreatedBy: "",
      DateCreated: "",
      PoNo: "",
      Appr_By: "",
      Appr_Date: "",
      Pay_Method: "",
      Payment_No: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getExpenses();
  },
  watch: {},
  created() {},
  methods: {
    getExpenses: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/expenses`,
      }).then((response) => (this.expenses = response.data));
    },
    editItem(item) {
      this.editedIndex = this.expenses.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push({
        name: "ExpensesModal",
        params: { id: this.editedItem.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  width: 100%;
}

.table-heading,
.table-items {
  gap: 5px;
  font-size: 12px;
}

.item-name {
  flex-basis: 50%;

  .item {
    flex-basis: 10%;
  }

  .qty {
    flex-basis: 5%;
  }

  .price {
    flex-basis: 15%;
  }

  .discount {
    flex-basis: 5%;
  }

  .total {
    flex-basis: 15%;
  }

  .table-heading {
    margin-bottom: 16px;
  }

  th {
    text-align: center;
  }

  td {
    text-align: center;
  }

  .button {
    color: #fff;
    // background-color: #252945;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  img {
    margin-right: 4px;
  }

  .table-items {
    position: relative;
    margin-bottom: 24px;

    img {
      position: absolute;
      top: 15px;
      right: 0;
      width: 12px;
      height: 16px;
    }
  }

  .input {
    margin-bottom: 24px;
  }

  input,
  select {
    width: 100%;
    background-color: #1e2139;
    color: #fff;
    border-radius: 4px;
    padding: 12px 4px;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .save {
    margin-top: 60px;

    div {
      flex: 1;
    }

    .right {
      justify-content: flex-end;
    }
  }
}
</style>
