import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5"},[_c(VMain,[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{staticClass:"align-center justify-center"},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"4","lg":"3"}},[_c(VCard,{staticClass:"elevation-11"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Reset Password")])],1),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-lock","name":"newPassword","label":"Password","type":"password","outlined":"","rules":[_vm.rules.password]},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-lock","name":"pwdConfirm","label":"Confirm Password","type":"password","outlined":"","rules":[
                    (v) => v === this.newPassword || 'Passwords do not match',
                  ]},model:{value:(_vm.pwdConfirm),callback:function ($$v) {_vm.pwdConfirm=$$v},expression:"pwdConfirm"}}),_c(VBtn,{attrs:{"color":"primary","x-large":"","block":""},on:{"click":_vm.validate}},[_vm._v("Reset Password")])],1)],1),_c(VCardActions,[_vm._v(" "+_vm._s(_vm.status)+" "+_vm._s(_vm.loading)+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }