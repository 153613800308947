<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="personnel"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Personnel Center</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="750px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-card flat>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col xs="12" sm="12" md="12">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.EmpId"
                              label="Emp Code"
                              append-icon="search"
                              prepend-inner-icon="mdi-account-settings"
                              :rules="[rules.required]"
                              clearable
                              dense
                              filled
                              outlined
                              readonly
                              @click:append="getPersonnelEmpCodeCount"
                            ></v-text-field>
                          </v-flex>
                        </v-col>

                        <v-col xs="12" sm="12" md="6">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.Name"
                              label="Name"
                              prepend-inner-icon="person"
                              :rules="[rules.required]"
                              clearable
                              dense
                              filled
                              outlined
                            ></v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="6">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.Surname"
                              label="Surname"
                              prepend-inner-icon="person"
                              :rules="[rules.required]"
                              clearable
                              dense
                              filled
                              outlined
                            ></v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="6">
                          <v-flex>
                            <v-autocomplete
                              v-model="editedItem.CitizenS"
                              label="Nationality"
                              :items="countriesdb"
                              item-text="c_country"
                              item-value="c_country"
                              prepend-inner-icon="mdi-map"
                              :rules="[rules.required]"
                              auto-select-first
                              clearable
                              dense
                              filled
                              outlined
                            ></v-autocomplete>
                          </v-flex>
                        </v-col>

                        <v-col xs="12" sm="12" md="6">
                          <v-flex>
                            <v-autocomplete
                              v-model="editedItem.Id_Types"
                              label="Identification Type"
                              :items="idtypedb"
                              item-text="id_type"
                              item-value="id_type"
                              prepend-inner-icon="picture_in_picture"
                              :rules="[rules.required]"
                              auto-select-first
                              clearable
                              dense
                              filled
                              outlined
                              id="IdType"
                              @change="checkIdTypes"
                            ></v-autocomplete>
                          </v-flex>
                        </v-col>

                        <v-col xs="12" sm="12" md="6">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.IDNo"
                              :disabled="!enableId"
                              :required="!enableId"
                              :rules="enableId === true ? [rules.required] : []"
                              label="Id No"
                              prepend-inner-icon="badge"
                              clearable
                              dense
                              filled
                              outlined
                              counter
                              maxlength="13"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              v-on:keydown.enter.prevent="
                                getEmpIdDetails(editedItem.IDNo)
                              "
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="6">
                          <v-flex>
                            <v-text-field
                              :disabled="!enablePassport"
                              :required="!enablePassport"
                              :rules="
                                enablePassport === true ? [rules.required] : []
                              "
                              v-model="editedItem.PassNo"
                              label="Passport No"
                              prepend-inner-icon="mdi-passport"
                              clearable
                              dense
                              filled
                              outlined
                              counter
                              maxlength="13"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>

                        <v-col xs="12" sm="12" md="4">
                          <v-menu ref="menu" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="editedItem.DOB"
                                slot="activator"
                                label="Date of Birth"
                                prepend-inner-icon="date_range"
                                :rules="[rules.required]"
                                v-bind="attrs"
                                v-on="on"
                                filled
                                outlined
                                dense
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.DOB"
                              no-title
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(editedItem.DOB)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col xs="12" sm="12" md="4">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.Age"
                              label="Age"
                              :rules="[rules.required]"
                              prepend-inner-icon="mdi-calendar"
                              clearable
                              dense
                              filled
                              outlined
                              counter
                              maxlength="10"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>

                        <v-col xs="12" sm="12" md="4">
                          <v-flex>
                            <v-autocomplete
                              v-model="editedItem.Gender"
                              label="Gender"
                              :items="genderdb"
                              item-text="name"
                              item-value="name"
                              prepend-inner-icon="mdi-gender-male-female"
                              :rules="[rules.required]"
                              auto-select-first
                              clearable
                              dense
                              filled
                              outlined
                            ></v-autocomplete>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="4">
                          <v-flex>
                            <v-autocomplete
                              v-model="editedItem.Occupation"
                              label="Job Title"
                              :items="jobtitledb"
                              item-text="JobTitle"
                              item-value="JobTitle"
                              prepend-inner-icon="mdi-format-title"
                              :rules="[rules.required]"
                              auto-select-first
                              clearable
                              dense
                              filled
                              outlined
                            ></v-autocomplete>
                          </v-flex>
                        </v-col>

                        <v-col xs="12" sm="12" md="4">
                          <v-flex>
                            <v-menu
                              ref="menudoe"
                              v-model="menudoe"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="editedItem.DOE"
                                  label="D.O.E."
                                  prepend-inner-icon="date_range"
                                  :rules="[rules.required]"
                                  filled
                                  outlined
                                  dense
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.DOE"
                                no-title
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menudoe.save(editedItem.DOE)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-col>

                        <v-col xs="12" sm="12" md="4">
                          <v-flex>
                            <v-autocomplete
                              v-model="editedItem.Emp_Status"
                              label="Status"
                              :items="empstatusdb"
                              item-text="EmpStatus"
                              item-value="EmpStatus"
                              prepend-inner-icon="mdi-account-check"
                              :rules="[rules.required]"
                              auto-select-first
                              clearable
                              dense
                              filled
                              outlined
                            ></v-autocomplete>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="12">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.Email"
                              label="Email"
                              prepend-inner-icon="email"
                              :rules="[rules.required, rules.email]"
                              clearable
                              dense
                              filled
                              outlined
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-divider></v-divider>

                  <v-card flat>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col xs="12" sm="12" md="6">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.Tel_No"
                              label="Home No"
                              prepend-inner-icon="mdi-phone"
                              clearable
                              dense
                              filled
                              outlined
                              counter
                              maxlength="10"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="6">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.Cell_No"
                              label="Cell No"
                              :rules="[rules.required, rules.min]"
                              prepend-inner-icon="mdi-cellphone"
                              clearable
                              dense
                              filled
                              outlined
                              counter
                              maxlength="10"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>

                        <v-col xs="12" sm="12" md="8">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.EName1"
                              :rules="[rules.required]"
                              label="Incase of Emergency"
                              prepend-inner-icon="mdi-ambulance"
                              clearable
                              dense
                              filled
                              outlined
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="4">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.ETel1"
                              label="Tel No"
                              :rules="[rules.required, rules.min]"
                              prepend-inner-icon="mdi-phone"
                              clearable
                              dense
                              filled
                              outlined
                              counter
                              maxlength="10"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="8">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.EName2"
                              label="Incase of Emergency"
                              prepend-inner-icon="mdi-ambulance"
                              clearable
                              dense
                              filled
                              outlined
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col xs="12" sm="12" md="4">
                          <v-flex>
                            <v-text-field
                              v-model="editedItem.ETel2"
                              label="Tel No"
                              prepend-inner-icon="mdi-phone"
                              clearable
                              dense
                              filled
                              outlined
                              counter
                              maxlength="10"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                      </v-row>

                      <v-row justify="center">
                        <v-col xs="12" sm="12" md="12">
                          <v-checkbox
                            v-model="enableAddr"
                            label="Postal Address same as Address"
                            outlined
                            id="ChangeAddress"
                            @change="copyAddressToPostal"
                          >
                          </v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="12" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.Street"
                            label="Street"
                            prepend-inner-icon="mdi-map-marker"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.Area"
                            label="Area"
                            append-icon="search"
                            prepend-inner-icon="mdi-map-marker"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                            @click:append="dialog2 = !dialog2"
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.City"
                            label="City"
                            prepend-inner-icon="mdi-map-marker"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.Code"
                            label="Area Code"
                            prepend-inner-icon="mdi-map-marker"
                            :rules="[rules.required, rules.counteraddMin]"
                            clearable
                            dense
                            filled
                            outlined
                            counter
                            maxlength="4"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col xs="12" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.StreetP"
                            label="P.O. Box"
                            prepend-inner-icon="mdi-map-marker"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.AreaP"
                            label="Postal Area"
                            prepend-inner-icon="mdi-map-marker"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.CityP"
                            label="Postal City"
                            prepend-inner-icon="mdi-map-marker"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.CodeP"
                            label="Postal Area Code"
                            prepend-inner-icon="mdi-map-marker"
                            counter
                            :rules="[rules.required, rules.counteraddMin]"
                            clearable
                            dense
                            filled
                            outlined
                            maxlength="4"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-card flat>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col xs="12" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.BankName"
                            label="Bank Name"
                            append-icon="search"
                            prepend-inner-icon="mdi-bank"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                            @click:append="dialog3 = !dialog3"
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.BranchName"
                            label="Branch Name"
                            prepend-inner-icon="mdi-bank"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                            required
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.BranchCode"
                            label="Branch Code"
                            prepend-inner-icon="mdi-bank"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                            required
                            counter
                            maxlength="6"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          >
                          </v-text-field>

                          <v-autocomplete
                            v-model="editedItem.Type"
                            label="Account Type"
                            :items="bankacctypedb"
                            item-text="acc_type"
                            item-value="acc_type"
                            prepend-inner-icon="mdi-bank"
                            :rules="[rules.required]"
                            auto-select-first
                            clearable
                            dense
                            filled
                            outlined
                            id="SalaryType"
                            @change="checkSalaryType"
                          ></v-autocomplete>

                          <v-text-field
                            v-model="editedItem.AccNo"
                            label="Account Number"
                            prepend-inner-icon="mdi-bank"
                            :rules="[rules.required, rules.min]"
                            clearable
                            dense
                            filled
                            outlined
                            required
                            counter
                            maxlength="16"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col xs="12" sm="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.SType"
                            label="Salary Type"
                            :items="salarytypedb"
                            item-text="SalaryType"
                            item-value="SalaryType"
                            prepend-inner-icon="mdi-cash-multiple"
                            :rules="[rules.required]"
                            auto-select-first
                            clearable
                            dense
                            filled
                            outlined
                            id="SalaryType"
                            @change="checkSalaryType"
                          ></v-autocomplete>

                          <v-text-field
                            :disabled="!enableSalary"
                            :required="!enableSalary"
                            :rules="
                              enableSalary === true ? [rules.required] : []
                            "
                            v-model="editedItem.Salary"
                            label="Salary"
                            prepend-inner-icon="mdi-cash"
                            clearable
                            dense
                            filled
                            outlined
                            number
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            @change="calculatePerHour"
                          ></v-text-field>

                          <v-text-field
                            :disabled="!enablePerHour"
                            :required="!enablePerHour"
                            :rules="
                              enablePerHour === true ? [rules.required] : []
                            "
                            v-model="editedItem.PerHour"
                            label="Per Hour"
                            prepend-inner-icon="mdi-clock-start"
                            clearable
                            dense
                            filled
                            outlined
                            counter
                            maxlength="10"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          >
                          </v-text-field>

                          <v-text-field
                            v-model="editedItem.TravelAll"
                            label="Travel Allowance"
                            prepend-inner-icon="mdi-car"
                            :rules="[rules.required]"
                            clearable
                            dense
                            filled
                            outlined
                            required
                            counter
                            maxlength="10"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          >
                          </v-text-field>
                          <v-autocomplete
                            v-model="editedItem.Method"
                            label="Method"
                            :items="paymenttypesdb"
                            item-text="p_type"
                            item-value="p_type"
                            prepend-inner-icon="mdi-cash-multiple"
                            :rules="[rules.required]"
                            auto-select-first
                            clearable
                            dense
                            filled
                            outlined
                            id="SalaryType"
                            @change="checkSalaryType"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-card-text>
            </v-card>
            <v-footer padless dense>
              <v-row justify="center" no-gutters>
                <v-btn tile color="primary" dark class="mx-2" @click="close"
                  >Cancel</v-btn
                >
                <v-btn tile color="primary" dark class="mb-2" @click="validate"
                  >Save</v-btn
                >
              </v-row>
            </v-footer>
          </v-dialog>
          <v-dialog v-model="dialog2" max-width="700px">
            <v-card>
              <v-card-text>
                <v-container grid-list-md>
                  <v-data-table
                    :headers="headersarea"
                    :items="areacodes"
                    :search="searcharea"
                    sort-by="calories"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Area Search</v-toolbar-title>
                        <v-divider class="mx-3" inset vertical left></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="searcharea"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItemArea(item)">
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon small @click="closeDialog2()">
                        mdi-cancel
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="closeAdd"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog3" max-width="700px">
            <v-card>
              <v-card-text>
                <v-container grid-list-md>
                  <v-data-table
                    :headers="headersbank"
                    :items="bankdb"
                    :search="searchbank"
                    sort-by="calories"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Bank Search</v-toolbar-title>
                        <v-divider class="mx-3" inset vertical left></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="searchbank"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItemBank(item)">
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon small @click="closeDialog3()">
                        mdi-cancel
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="closeAdd"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
// import { CodeNode } from "source-list-map";

export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    dialog3: false,
    dialogDelete: false,
    isEditing: false,
    enableAddr: false,
    show1: false,
    menu: false,
    menudoe: false,
    modal: false,
    enableSalary: false,
    enablePerHour: false,
    enableId: false,
    enablePassport: false,
    requiredId2: false,
    id: "",
    search: "",
    searcharea: "",
    searchbank: "",
    SEmpCode: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 10 || "Min 10 characters",
      minId: (v) => v.length >= 13 || "Min 13 characters",
      requiredId: (value) => {
        if (value == true) {
          return true;
        }
        if (value == false) {
          return false;
        }
      },
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      counteraddMax: (value) => value.length <= 4 || "Max 4 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Emp Code",
        align: "center",
        sortable: true,
        value: "EmpId",
        width: "8%",
      },
      { text: "Name", value: "Name" },
      { text: "Surname", value: "Surname" },
      { text: "DOB", value: "DOB" },
      { text: "ID No", value: "IDNo" },
      { text: "Status", value: "Emp_Status" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    headersarea: [
      {
        text: "Area",
        align: "left",
        sortable: true,
        value: "area",
      },
      { text: "Code", value: "code" },
      { text: "City", value: "city" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    headersbank: [
      { text: "Bank Name", align: "left", sortable: true, value: "BankName" },
      { text: "Branch Name", value: "BranchName" },
      { text: "Branch Code", value: "BranchCode" },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
    personnel: [],
    discountdb: [],
    empstatusdb: [],
    termsdb: [],
    areacodes: [],
    genderdb: [],
    jobtitledb: [],
    salarytypedb: [],
    bankdb: [],
    countriesdb: [],
    idtypedb: [],
    bankacctypedb: [],
    paymenttypesdb: [],
    editedIndex: -1,
    editedIndexArea: -1,
    editedIndexBank: -1,
    editedItem: {
      id: "",
      EmpId: "",
      Name: "",
      Surname: "",
      DOB: "",
      IDNo: "",
      PassNo: "",
      UIF: "",
      Tel_No: "",
      Cell_No: "",
      DOE: "",
      Occupation: "",
      Street: "",
      Area: "",
      City: "",
      Code: "",
      StreetP: "",
      AreaP: "",
      CityP: "",
      CodeP: "",
      EName1: "",
      ETel1: "",
      EName2: "",
      ETel2: "",
      BankName: "",
      BranchName: "",
      AccNo: "",
      BranchCode: "",
      Type: "",
      Method: "",
      Leave_Day: "",
      PCompany: "",
      GeneralDut: "",
      YOE: "",
      Path: "",
      Salary: "",
      PerHour: "",
      SType: "",
      YearEarn: "",
      TaxPaid: "",
      MonthPay: "",
      TravelAll: "",
      Gender: "",
      CitizenS: "",
      Age: "",
      Email: "",
      Leave_Rate: "",
      Emp_Status: "",
      Id_Types: "",
    },
    defaultItem: {
      id: "",
      EmpId: "",
      Name: "",
      Surname: "",
      DOB: "",
      IDNo: "",
      PassNo: "",
      UIF: "",
      Tel_No: "",
      Cell_No: "",
      DOE: "",
      Occupation: "",
      Street: "",
      Area: "",
      City: "",
      Code: "",
      StreetP: "",
      AreaP: "",
      CityP: "",
      CodeP: "",
      EName1: "",
      ETel1: "",
      EName2: "",
      ETel2: "",
      BankName: "",
      BranchName: "",
      AccNo: "",
      BranchCode: "",
      Type: "",
      Method: "",
      Leave_Day: "",
      PCompany: "",
      GeneralDut: "",
      YOE: "",
      Path: "",
      Salary: "",
      PerHour: "",
      SType: "",
      YearEarn: "",
      TaxPaid: "",
      MonthPay: "",
      TravelAll: "",
      Gender: "",
      CitizenS: "",
      Age: "",
      Email: "",
      Leave_Rate: "",
      Emp_Status: "",
      Id_Types: "",
    },
    editedItemArea: {
      id: "",
      area: "",
      code: "",
      city: "",
    },
    defaultItemArea: {
      id: "",
      area: "",
      code: "",
      city: "",
    },
    editedItemBank: {
      id: "",
      BranchCode: "",
      BankName: "",
      BranchName: "",
    },
    defaultItemBank: {
      id: "",
      BranchCode: "",
      BankName: "",
      BranchName: "",
    },
    acctype: [],
    acctypetest: [{ test: "admin" }, { test: "test" }],
  }),
  beforeMount: function () {
    let self = this;
    self.getPersonnel();
    self.getEmpStatus();
    self.getAreaCodes();
    self.getEmpCode();
    self.getGender();
    self.getJobTitle();
    self.getSalaryType();
    self.getBank();
    self.getAllCountries();
    self.getIdType();
    self.getBankAcctype();
    self.getPaymentTypes();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Employee" : "Edit Employee";
    },
    isRequired() {
      if (this.enableId == true) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    getPersonnel: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/personnel`,
      }).then((response) => {
        this.personnel = response.data;
      });
    },
    getEmpStatus: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/empstatus`,
      }).then((response) => (this.empstatusdb = response.data));
    },
    getAreaCodes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/areacodes`,
      }).then((response) => {
        this.areacodes = response.data;
      });
    },
    getGender: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/gender`,
      }).then((response) => {
        this.genderdb = response.data;
      });
    },
    getJobTitle: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/jobtitle`,
      }).then((response) => {
        this.jobtitledb = response.data;
      });
    },
    getSalaryType: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/salarytype`,
      }).then((response) => {
        this.salarytypedb = response.data;
      });
    },
    getBank: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/banks`,
      }).then((response) => {
        this.bankdb = response.data;
      });
    },
    getIdType: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/idtypes`,
      }).then((response) => {
        this.idtypedb = response.data;
      });
    },

    getBankAcctype: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/bankacctypes`,
      }).then((response) => {
        this.bankacctypedb = response.data;
      });
    },
    getPaymentTypes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/paymenttypes`,
      }).then((response) => (this.paymenttypesdb = response.data));
    },

    postInsertPersonnel: function () {
      const data = {
        EmpId: this.editedItem.EmpId,
        Name: this.editedItem.Name,
        Surname: this.editedItem.Surname,
        DOB: this.editedItem.DOB,
        IDNo: this.editedItem.IDNo,
        PassNo: this.editedItem.PassNo,
        UIF: this.editedItem.UIF,
        Tel_No: this.editedItem.Tel_No,
        Cell_No: this.editedItem.Cell_No,
        DOE: this.editedItem.DOE,
        Occupation: this.editedItem.Occupation,
        Street: this.editedItem.Street,
        Area: this.editedItem.Area,
        City: this.editedItem.City,
        Code: this.editedItem.Code,
        StreetP: this.editedItem.StreetP,
        AreaP: this.editedItem.AreaP,
        CityP: this.editedItem.CityP,
        CodeP: this.editedItem.CodeP,
        EName1: this.editedItem.EName1,
        ETel1: this.editedItem.ETel1,
        EName2: this.editedItem.EName2,
        ETel2: this.editedItem.ETel2,
        BankName: this.editedItem.BankName,
        BranchName: this.editedItem.BranchName,
        AccNo: this.editedItem.AccNo,
        BranchCode: this.editedItem.BranchCode,
        Type: this.editedItem.Type,
        Method: this.editedItem.Method,
        Leave_Day: this.editedItem.Leave_Day,
        PCompany: this.editedItem.PCompany,
        GeneralDut: this.editedItem.GeneralDut,
        YOE: this.editedItem.YOE,
        Path: this.editedItem.Path,
        Salary: this.editedItem.Salary,
        PerHour: this.editedItem.PerHour,
        SType: this.editedItem.SType,
        YearEarn: this.editedItem.YearEarn,
        TaxPaid: this.editedItem.TaxPaid,
        MonthPay: this.editedItem.MonthPay,
        TravelAll: this.editedItem.TravelAll,
        Gender: this.editedItem.Gender,
        CitizenS: this.editedItem.CitizenS,
        Age: this.editedItem.Age,
        Email: this.editedItem.Email,
        Leave_Rate: this.editedItem.Leave_Rate,
        Emp_Status: this.editedItem.Emp_Status,
        Id_Types: this.editedItem.Id_Types,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/personnelins`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postUpdatePersonnel: function () {
      const data = {
        id: this.editedItem.id,
        EmpId: this.editedItem.EmpId,
        Name: this.editedItem.Name,
        Surname: this.editedItem.Surname,
        DOB: this.editedItem.DOB,
        IDNo: this.editedItem.IDNo,
        PassNo: this.editedItem.PassNo,
        UIF: this.editedItem.UIF,
        Tel_No: this.editedItem.Tel_No,
        Cell_No: this.editedItem.Cell_No,
        DOE: this.editedItem.DOE,
        Occupation: this.editedItem.Occupation,
        Street: this.editedItem.Street,
        Area: this.editedItem.Area,
        City: this.editedItem.City,
        Code: this.editedItem.Code,
        StreetP: this.editedItem.StreetP,
        AreaP: this.editedItem.AreaP,
        CityP: this.editedItem.CityP,
        CodeP: this.editedItem.CodeP,
        EName1: this.editedItem.EName1,
        ETel1: this.editedItem.ETel1,
        EName2: this.editedItem.EName2,
        ETel2: this.editedItem.ETel2,
        BankName: this.editedItem.BankName,
        BranchName: this.editedItem.BranchName,
        AccNo: this.editedItem.AccNo,
        BranchCode: this.editedItem.BranchCode,
        Type: this.editedItem.Type,
        Method: this.editedItem.Method,
        Leave_Day: this.editedItem.Leave_Day,
        PCompany: this.editedItem.PCompany,
        GeneralDut: this.editedItem.GeneralDut,
        YOE: this.editedItem.YOE,
        Path: this.editedItem.Path,
        Salary: this.editedItem.Salary,
        PerHour: this.editedItem.PerHour,
        SType: this.editedItem.SType,
        YearEarn: this.editedItem.YearEarn,
        TaxPaid: this.editedItem.TaxPaid,
        MonthPay: this.editedItem.MonthPay,
        TravelAll: this.editedItem.TravelAll,
        Gender: this.editedItem.Gender,
        CitizenS: this.editedItem.CitizenS,
        Age: this.editedItem.Age,
        Email: this.editedItem.Email,
        Leave_Rate: this.editedItem.Leave_Rate,
        Emp_Status: this.editedItem.Emp_Status,
        Id_Types: this.editedItem.Id_Types,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/personnelupd`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postDeletePersonnel: function () {
      const data = {
        id: this.id,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/personneldel`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },

    getPersonnelEmpCodeCount: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/personnelempcodecountsel`,
      }).then((response) => {
        var EmpCode = response.data[0].count;
        EmpCode++;
        if (EmpCode < 10) {
          EmpCode = this.SEmpCode + "00" + EmpCode;
        } else if ((AccCode >= 10) & (EmpCode < 100)) {
          EmpCode = this.SEmpCode + "0" + EmpCode;
        }
        this.editedItem.EmpId = EmpCode;
      });
    },
    getEmpCode: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/systemsetupempcodesel`,
      }).then((response) => {
        this.SEmpCode = response.data[0].EmpCode;
      });
    },
    getAllCountries: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/getallcountries`,
      }).then((response) => {
        this.countriesdb = response.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.personnel.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.checkIdTypes();
      this.checkSalaryType();
      this.dialog = true;
    },
    editItemArea(item) {
      this.editedIndexArea = this.areacodes.indexOf(item);
      this.editedItemArea = Object.assign({}, item);
      this.editedItem.Area = this.editedItemArea.area;
      this.editedItem.City = this.editedItemArea.city;
      this.editedItem.Code = this.editedItemArea.code;
      this.searcharea = null;
      this.dialog2 = false;
    },
    editItemBank(item) {
      this.editedIndexBank = this.bankdb.indexOf(item);
      this.editedItemBank = Object.assign({}, item);
      this.editedItem.BankName = this.editedItemBank.BankName;
      this.editedItem.BranchName = this.editedItemBank.BranchName;
      this.editedItem.BranchCode = this.editedItemBank.BranchCode;
      this.searchbank = null;
      this.dialog3 = false;
    },

    deleteItem(item) {
      this.id = item.id;
      const index = this.personnel.indexOf(item);
      if (confirm("Are you sure you want to delete this Employee?")) {
        this.personnel.splice(index, 1);
        this.postDeletePersonnel();
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
      this.enableAddr = false;
    },
    closeAdd() {
      this.dialog2 = false;
      this.editedItemArea = Object.assign({}, this.defaultItemArea);
      this.editedIndexArea = -1;
      this.$refs.form.resetValidation();
      this.searcharea = null;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.personnel[this.editedIndex], this.editedItem);
      } else {
        this.personnel.push(this.editedItem);
      }
      this.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.personnel[this.editedIndex], this.editedItem);
          this.postUpdatePersonnel();
        } else {
          this.postInsertPersonnel();
          this.personnel.push(this.editedItem);
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
    copyAddressToPostal() {
      this.$nextTick(() => {
        let elt = document.getElementById("ChangeAddress");
        if (elt.checked) {
          this.editedItem.StreetP = this.editedItem.Street;
          this.editedItem.AreaP = this.editedItem.Area;
          this.editedItem.CityP = this.editedItem.City;
          this.editedItem.CodeP = this.editedItem.Code;
        } else {
          this.editedItem.StreetP = "";
          this.editedItem.AreaP = "";
          this.editedItem.CityP = "";
          this.editedItem.CodeP = "";
        }
      });
    },
    closeDialog2() {
      this.dialog2 = false;
      this.searcharea = null;
    },
    closeDialog3() {
      this.dialog3 = false;
      this.searchbank = null;
    },
    getEmpIdDetails(idno) {
      var EmpID = idno;
      var EmpDOB = EmpID.substring(0, 6);
      var EmpYY = EmpDOB.substring(0, 2);
      var EmpMM = EmpDOB.substring(2, 4);
      var EmpDD = EmpDOB.substring(4, 6);

      const currentDate = new Date();
      var nowYY = currentDate.getFullYear();
      var YY;
      if (EmpYY > nowYY - 2000) {
        YY = 19;
      } else if (EmpYY <= nowYY - 2000) {
        YY = 20;
      }

      EmpDOB = YY + EmpYY + "-" + EmpMM + "-" + EmpDD;

      var EmpGender = EmpID.substring(6, 7);
      if (EmpGender <= 4) {
        this.editedItem.Gender = "Female";
      } else {
        this.editedItem.Gender = "Male";
      }

      var today = new Date();
      var now_date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      let difference = new Date(now_date) - new Date(EmpDOB);
      let EmpAge = Math.floor(difference / 31557600000);
      this.editedItem.Age = EmpAge;
      this.editedItem.DOB = EmpDOB;
    },
    checkSalaryType() {
      this.$nextTick(() => {
        let elt = document.getElementById("SalaryType");
        if (this.editedItem.SType == "Monthly") {
          if (this.enableSalary == false) {
            this.enableSalary = true;
            this.editedItem.PerHour = "";
          }
          if (this.enablePerHour == true) {
            this.enablePerHour = false;
          }
        } else {
          if (this.enablePerHour == false) {
            this.enablePerHour = true;
            this.editedItem.Salary = "";
          }
          if (this.enableSalary == true) {
            this.enableSalary = false;
          }
        }
      });
    },
    calculatePerHour() {
      let sSalary = this.editedItem.Salary;
      let PerHour = 0;
      PerHour = (sSalary * 12) / 2080;
      this.editedItem.PerHour = this.formatNumber(PerHour);
    },
    checkIdTypes() {
      this.$nextTick(() => {
        let elt = document.getElementById("IdType");
        if (this.editedItem.Id_Types == "ID") {
          if (this.enableId == false) {
            this.enableId = true;
            this.editedItem.PassNo = "";
          }
          if (this.enablePassport == true) {
            this.enablePassport = false;
          }
        } else {
          if (this.enablePassport == false) {
            this.enablePassport = true;
            this.editedItem.IDNo = "";
          }
          if (this.enableId == true) {
            this.enableId = false;
          }
        }
      });
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
  },
};
</script>
