<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="editedItem.SO_No"
                prepend-inner-icon="mdi-file"
                label="Sales Order No"
                outlined
                dense
                class="d-flex my-1"
                hide-details
                readonly
              >
              </v-text-field>
              <v-text-field
                v-model="editedItem.SO_Date"
                prepend-inner-icon="mdi-calendar-today"
                label="Date"
                outlined
                dense
                class="d-flex my-1"
                hide-details
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <v-card outlined>
                <v-toolbar color="primary" dense>
                  <v-toolbar-title>Bill To:</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-autocomplete
                    v-model="editedItem.Client_Acc"
                    label="Client"
                    :items="clientsdb"
                    item-text="Client"
                    item-value="id"
                    prepend-inner-icon="person"
                    :rules="[rules.required]"
                    auto-select-first
                    dense
                    outlined
                    hide-details
                    return-object
                    :readonly="!enableClient"
                    @change="UpdateClientDetails"
                  ></v-autocomplete>
                  <v-text-field
                    v-model="editedItem.Add1"
                    prepend-inner-icon="mdi-home-map-marker"
                    label="Street"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.Add2"
                    prepend-inner-icon="mdi-home-assistant"
                    label="Area"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.Add3"
                    prepend-inner-icon="mdi-city"
                    label="City"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.Add4"
                    prepend-inner-icon="post_add"
                    label="Code"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.VatNo"
                    prepend-inner-icon="credit_score"
                    label="Vat No"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" class="justify-end">
              <v-card outlined>
                <v-toolbar color="primary" dense>
                  <v-toolbar-title>Deliver To:</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-autocomplete
                    v-model="editedItem.Client_Acc"
                    label="Client"
                    :items="clientsdb"
                    item-text="Client"
                    item-value="id"
                    prepend-inner-icon="person"
                    :rules="[rules.required]"
                    :readonly="!enableClient"
                    auto-select-first
                    dense
                    outlined
                    hide-details
                  ></v-autocomplete>
                  <v-text-field
                    v-model="editedItem.DelAdd1"
                    prepend-inner-icon="mdi-home-map-marker"
                    label="Street"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.DelAdd2"
                    prepend-inner-icon="mdi-home-assistant"
                    label="Area"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.DelAdd3"
                    prepend-inner-icon="mdi-city"
                    label="City"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="editedItem.DelAdd4"
                    prepend-inner-icon="post_add"
                    label="Code"
                    :rules="[rules.required]"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <template>
            <v-data-table
              :headers="headers"
              :items="sorderItemList"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title> Sales Order Items </v-toolbar-title>
                  <v-divider class="mx-3" insert vertical left></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!enableNewItem"
                      >
                        New Item
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Items</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container grid-list-md>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                label="Qty"
                                type="number"
                                v-model="editedItemSOrder.Qty"
                                :rules="[rules.required]"
                                outlined
                                dense
                                @click="formatNumberTotal"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                label="Item"
                                v-model="editedItemSOrder.ItemCode"
                                append-icon="search"
                                prepend-inner-icon="inventory_2"
                                :rules="[rules.required]"
                                outlined
                                dense
                                @click:append="dialog3 = !dialog3"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="12">
                              <v-text-field
                                label="Description"
                                v-model="editedItemSOrder.Descr"
                                :rules="[rules.required]"
                                outlined
                                dense
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                label="Price"
                                v-model="editedItemSOrder.Price"
                                :rules="[rules.required]"
                                outlined
                                dense
                                @change="formatNumberTotal"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-autocomplete
                                v-model="editedItemSOrder.Disc"
                                label="Discount"
                                :items="discountdb"
                                item-text="DiscPerc"
                                item-value="DiscPerc"
                                prepend-inner-icon="mdi-format-title"
                                :rules="[rules.required]"
                                auto-select-first
                                clearable
                                dense
                                filled
                                outlined
                                @change="formatNumberTotal"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                type="number"
                                label="Total"
                                v-model="editedItemSOrder.Total"
                                :rules="[rules.required]"
                                outlined
                                dense
                                readonly
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="closeSOrder"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="validate"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialog2" max-width="700px">
                    <v-card class="elevation-12" outlined dense>
                      <v-toolbar dark color="primary" dense>
                        <v-toolbar-title>Send emails...</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-form ref="vdialog2">
                          <v-text-field
                            v-model="editedEmailItem.email"
                            prepend-inner-icon="email"
                            :rules="[rules.email]"
                            name="email"
                            label="To..."
                            type="text"
                            outlined
                            dense
                            hide-details
                            class="d-flex my-1"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedEmailItem.subject"
                            prepend-inner-icon="subject"
                            :rules="[rules.required]"
                            name="subject"
                            label="Subject..."
                            type="text"
                            outlined
                            dense
                            hide-details
                            class="d-flex my-1"
                          ></v-text-field>
                          <v-file-input
                            v-model="file"
                            label="File Input"
                            show-size
                            counter
                            outlined
                            prepend-icon=""
                            prepend-inner-icon="mdi-paperclip"
                            placeholder="Upload your documents"
                            dense
                            hide-details
                            class="d-flex my-1"
                            type="file"
                            accept=".pdf"
                            @change="uploadFile"
                          >
                          </v-file-input>
                          <v-flex xs12 sm6 md12>
                            <v-textarea
                              v-model="editedEmailItem.textBody"
                              label="Description"
                              prepend-inner-icon="description"
                              :rules="[rules.required]"
                              outlined
                              dense
                              class="d-flex my-1"
                            ></v-textarea>
                          </v-flex>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        {{ status }}
                        {{ loading }}
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="closeDialog2"
                          >Cancel</v-btn
                        >
                        <v-btn color="primary" class="mb-2" @click="send"
                          >Send</v-btn
                        >
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="closeDialog2"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialog3" max-width="900px">
                    <v-card>
                      <v-card-text>
                        <v-container grid-list-md>
                          <v-data-table
                            :headers="headersinventory"
                            :items="inventories"
                            :search="searchinventory"
                            sort-by="calories"
                            class="elevation-1"
                          >
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title
                                  >Inventory Search</v-toolbar-title
                                >
                                <v-divider
                                  class="mx-3"
                                  inset
                                  vertical
                                  left
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-text-field
                                  v-model="searchinventory"
                                  append-icon="search"
                                  label="Search"
                                  single-line
                                  hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>
                              </v-toolbar>
                            </template>
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                class="mr-2"
                                @click="editItemInventory(item)"
                              >
                                mdi-check-circle-outline
                              </v-icon>
                              <v-icon small @click="closeInv()">
                                mdi-cancel
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="closeInv"
                          >Cancel</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </template>
        </v-container>
        <v-spacer></v-spacer>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <v-card outlined>
                <v-toolbar color="primary" dense>
                  <v-toolbar-title>Banking Details:</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="Company"
                    prepend-inner-icon="mdi-bank"
                    label="Account Name"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="AccNo"
                    prepend-inner-icon="mdi-bank"
                    label="Account No"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="Bank"
                    prepend-inner-icon="mdi-bank"
                    label="Bank"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="Branch"
                    prepend-inner-icon="mdi-bank"
                    label="Branch"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="BCode"
                    prepend-inner-icon="mdi-bank"
                    label="Code"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" class="justify-end">
              <v-card outlined>
                <v-toolbar color="primary" dense>
                  <v-toolbar-title>Total:</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="editedItem.Sub_Total"
                    prepend-inner-icon="mdi-home-map-marker"
                    label="Sub Total"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="editedItem.Vat"
                    prepend-inner-icon="mdi-percent"
                    label="Vat"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    label="Total"
                    v-model="editedItem.Grand_Total"
                    prepend-inner-icon="functions"
                    outlined
                    dense
                    class="d-flex my-1"
                    hide-details
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-footer padless dense>
          <v-row justify="center" no-gutters>
            <v-btn
              tile
              :disabled="!enableCancel"
              color="primary"
              dark
              class="mx-2"
              @click="close"
              >Cancel</v-btn
            >
            <v-btn
              tile
              color="primary"
              dark
              class="mb-2"
              @click="validateSOrder"
              >Save</v-btn
            >
            <v-btn
              tile
              :disabled="!enabledPrint"
              color="primary"
              dark
              class="mx-2"
              @click="print"
              >Print</v-btn
            >
            <v-btn
              tile
              :disabled="!enabledEmail"
              color="primary"
              dark
              class="mb-2"
              @click="emailInv"
              >Email</v-btn
            >
            <v-btn
              tile
              :disabled="!enableClose"
              color="primary"
              dark
              class="mx-2"
              @click="close"
              >Close</v-btn
            >
          </v-row>
        </v-footer>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { uid } from "uid";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
export default {
  data: () => ({
    heading: "Sales Order ",
    clientsdb: [],
    sorderItemList: [],
    systemsetup: [],
    discountdb: [],
    inventories: [],
    editedSOrderIndex: -1,
    editedIndexInv: -1,
    dialog: false,
    dialog2: false,
    dialog3: false,
    menudoe: false,
    Company: "",
    AccNo: "",
    Bank: "",
    Branch: "",
    BCode: "",
    Website: "",
    Email: "",
    Owner: "",
    TelNo: "",
    CellNo: "",
    FaxNo: "",
    AddStreet: "",
    AddArea: "",
    AddCity: "",
    AddAreaCode: "",
    RegNumber: "",
    VatRegNo: "",
    id: "",
    DiscPerc: "",
    Client_Acc: "",
    Disc: "",
    loading: "",
    status: "",
    attachment: "",
    file: null,
    fileUrl: "",
    clientContact: "",
    clientCellNo: "",
    clientName: "",
    searchinventory: "",
    enabledPrint: false,
    enableCancel: true,
    sorderSaved: false,
    enableNewItem: false,
    enableClient: true,
    enableClose: false,
    isNewSOrder: false,
    enabledEmail: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 10 || "Min 10 characters",
      minId: (v) => v.length >= 13 || "Min 13 characters",
      requiredId: (value) => {
        if (value == true) {
          return true;
        }
        if (value == false) {
          return false;
        }
      },
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      counteraddMax: (value) => value.length <= 4 || "Max 4 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Qty",
        // align: "center",
        sortable: true,
        value: "Qty",
        width: "10%",
      },
      { text: "Item Code", value: "ItemCode", width: "10%" },
      { text: "Description", value: "Descr", width: "40%" },
      { text: "Price", value: "Price", width: "10%" },
      { text: "Disc", value: "Disc", width: "10%" },
      { text: "Total", value: "Total", width: "10%" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    headersinventory: [
      {
        text: "Item",
        align: "left",
        sortable: true,
        value: "I_Type",
      },
      { text: "Description", value: "Display_Desc" },
      { text: "Sales Price", value: "Sales_Price" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    editedItem: {
      id: "",
      SO_No: "",
      Client_Acc: "",
      Sub_Total: "",
      Vat: "",
      Vat_Select: "",
      Grand_Total: "",
      Quote_No: "",
      Paid: "",
      SO_Date: "",
    },
    defaultItem: {
      id: "",
      SO_No: "",
      Client_Acc: "",
      Sub_Total: "",
      Vat: "",
      Vat_Select: "",
      Grand_Total: "",
      Quote_No: "",
      Paid: "",
      SO_Date: "",
    },
    editedItemSOrder: {
      id: "",
      Qty: "",
      ItemCode: "",
      Descr: "",
      Price: "",
      Disc: "",
      Total: "",
    },
    defaultItemSOrder: {
      id: 0,
      Qty: "",
      ItemCode: "",
      Descr: "",
      Price: "",
      Disc: "",
      Total: "",
    },
    editItemDiscount: {
      id: "",
      DiscPerc: "",
    },
    editedEmailItem: {
      email: "",
      subject: "",
      textBody: "",
      attachment: "",
    },
    defaultEmailItem: {
      email: "",
      subject: "",
      textBody: "",
      attachment: "",
    },
    editedItemInv: {
      id: "",
      I_Type: "",
      Display_Desc: "",
      Sales_Price: "",
    },
    defaultItemInv: {
      id: "",
      I_Type: "",
      Display_Desc: "",
      Sales_Price: "",
    },
    formatter: new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }),
  beforeMount: function () {
    let self = this;
    self.getSystemSetup();
    self.getClients();
    self.getDiscount();
    self.getInventories();

    if (this.$route.params.id > "-1") {
      self.getSOrderDetailsBySOrderId();
    } else {
      self.setDateNow();
      this.isNewSOrder = true;
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id === "-1"
        ? "New Sales Order"
        : "Edit Sales Order: " + this.$route.params.id;
    },
    calcTotal() {
      return this.sorderItemList
        .map((stotal) => stotal.Total)
        .reduce((total, amount) => total + amount);
    },
    calculateSubTotals() {
      return this.sorderItemList.map((stotal) => {
        let sum = 0;
        sum += Number(stotal.Total);
        return sum;
      });
    },
    getUserFull: function () {
      return localStorage.getItem("fullname");
    },
    getUserEmail: function () {
      return localStorage.getItem("useremail");
    },
  },
  methods: {
    uploadFile(e) {
      this.file = e;
    },
    send() {
      this.user = this.getUserEmail;
      this.recipient = {
        name: "Jaco",
        email: this.editedEmailItem.email,
      };
      this.mailsubject = this.editedEmailItem.subject;
      this.mailBody = this.editedEmailItem.textBody;

      this.mail;
      this.status = "";
      this.loading = "Sending email, please wait....";
      const formData = new FormData();
      formData.append("user", JSON.stringify(this.user));
      formData.append("recipient", JSON.stringify(this.recipient));
      formData.append("mailsubject", JSON.stringify(this.mailsubject));
      formData.append("mailBody", JSON.stringify(this.mailBody));

      if (this.file) {
        formData.append("files", this.file, this.file.name);
        this.axios
          .post(`${this.$hostname}/api/sendmail`, formData, {
            headers: {
              "x-access-token": localStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.loading = "";
            this.status = res.data.message;
          });
      } else {
        this.axios
          .post(`${this.$hostname}/api/sendmail`, formData, {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          })
          .then((res) => {
            this.loading = "";
            this.status = res.data.message;
          });
      }
    },
    getSystemSetup: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/systemsetup`,
      }).then((response) => {
        this.systemsetup = response.data;
        this.Company = response.data[0].Company;

        this.AccNo = response.data[0].AccNo;
        this.Bank = response.data[0].Bank;
        this.Branch = response.data[0].Branch;
        this.BCode = response.data[0].BCode;

        this.Website = response.data[0].Website;
        this.Email = response.data[0].Email;
        this.Owner = response.data[0].Owner;
        this.TelNo = response.data[0].Tel;
        this.CellNo = response.data[0].Cell;
        this.FaxNo = response.data[0].Fax;
        this.AddStreet = response.data[0].Address;
        this.AddArea = response.data[0].Area;
        this.AddCity = response.data[0].City;
        this.AddAreaCode = response.data[0].Code;
        this.RegNumber = response.data[0].RegNo;
        this.VatRegNo = response.data[0].VatRegNo;
      });
    },

    getClients: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/clients`,
      }).then((response) => {
        this.clientsdb = response.data;
      });
    },

    getDiscount: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/discounts`,
      }).then((response) => (this.discountdb = response.data));
    },

    getInventories: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/inventories`,
      }).then((response) => (this.inventories = response.data));
    },

    getSOrderDetailsBySOrderId() {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/salesdetails/${this.$route.params.id}`,
      }).then((response) => {
        this.editedItem.SO_No = response.data.SO_No;
        this.editedItem.SO_Date = response.data.SO_Date;

        var clientdbIndex = this.clientsdb.findIndex(
          (data) => data.AccNo === response.data.Client_Acc
        );

        this.editedItem.Client_Acc = this.clientsdb[clientdbIndex].id;
        this.editedItem.Add1 = this.clientsdb[clientdbIndex].Add1;
        this.editedItem.Add2 = this.clientsdb[clientdbIndex].Add2;
        this.editedItem.Add3 = this.clientsdb[clientdbIndex].Add3;
        this.editedItem.Add4 = this.clientsdb[clientdbIndex].Add4;
        this.editedItem.VatNo = this.clientsdb[clientdbIndex].VatNo;
        this.editedItem.DelAdd1 = this.clientsdb[clientdbIndex].DelAdd1;
        this.editedItem.DelAdd2 = this.clientsdb[clientdbIndex].DelAdd2;
        this.editedItem.DelAdd3 = this.clientsdb[clientdbIndex].DelAdd3;
        this.editedItem.DelAdd4 = this.clientsdb[clientdbIndex].DelAdd4;
        this.editedItem.Terms = this.clientsdb[clientdbIndex].Terms;

        this.clientName = this.clientsdb[clientdbIndex].Client;
        this.clientContact = this.clientsdb[clientdbIndex].Contact;
        this.clientCellNo = this.clientsdb[clientdbIndex].CellNo;

        this.editedItem.Sub_Total = response.data.Sub_Total;
        this.editedItem.Vat = response.data.Vat;
        this.editedItem.Grand_Total = response.data.Grand_Total;

        this.dbres = response.data;
        this.getSOrderBySOrderNo();
      });
    },

    setDateNow() {
      var today = new Date();
      this.editedItem.SO_Date = today.toISOString().substring(0, 10);
    },
    getSOrderBySOrderNo() {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/salesorders/${this.editedItem.SO_No}`,
      }).then((response) => {
        this.sorderItemList = response.data;
        if (this.enableNewItem == false) {
          this.enableNewItem = !this.enableNewItem;
        }
        for (let i = 0; i < this.sorderItemList.length; i++) {
          this.sorderItemList[i].id = uid(6);
          this.sorderItemList[i].Qty = response.data[i].count;
          this.sorderItemList[i].ItemCode = response.data[i].ItemCode;
          this.sorderItemList[i].Descr = response.data[i].Description;
          this.sorderItemList[i].Disc = response.data[i].Discount;
          this.sorderItemList[i].Total = response.data[i].Amount;
        }
      });
    },

    setSOrderNo: function () {
      if ((this.$route.params.id = "-1")) {
        this.axios({
          method: "get",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          url: `${this.$hostname}/api/salesdetails/countsel`,
        }).then((response) => {
          var SOrderNo = response.data;
          SOrderNo++;
          this.editedItem.SO_No = SOrderNo;
          this.postInsertSOrderDetails();
          if (this.isNewSOrder == true) {
            this.isNewSOrder = !this.isNewSOrder;
          }
        });
      }
    },
    postInsertSOrderDetails: function () {
      const data = {
        SO_No: this.editedItem.SO_No,
        Client_Acc: this.editedItem.Client_Acc.AccNo,
        Sub_Total: this.editedItem.Sub_Total,
        Vat: this.editedItem.Vat,
        Vat_Select: this.editedItem.Vat_Select,
        Grand_Total: this.editedItem.Grand_Total,
        Quote_No: this.editedItem.Quote_No,
        Paid: this.editedItem.Paid,
        SO_Date: this.editedItem.SO_Date,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/salesdetails`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        this.postInsertSOrderList();
      });
    },
    postInsertSOrderList: function () {
      var clientdbIndex = this.clientsdb.findIndex(
        (data) => data.id === this.editedItem.Client_Acc
      );

      if (clientdbIndex == -1) {
        var clientdbIndex = this.clientsdb.findIndex(
          (data) => data.AccNo === this.editedItem.Client_Acc.AccNo
        );
      }
      const sendPostRequest = async () => {
        for (let i = 0; i < this.sorderItemList.length; i++) {
          const data = {
            SO_No: this.editedItem.SO_No,
            ClientAcc: this.clientsdb[clientdbIndex].AccNo,
            ItemCode: this.sorderItemList[i].ItemCode,
            Description: this.sorderItemList[i].Descr,
            count: this.sorderItemList[i].Qty,
            Price: this.sorderItemList[i].Price,
            Amount: this.sorderItemList[i].Total,
            Discount: this.sorderItemList[i].Disc,
          };
          try {
            const resp = await this.axios({
              method: "post",
              mode: "no-cors",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
              url: `${this.$hostname}/api/salesorders`,
              data: data,
            });
          } catch (err) {
            console.error(err);
          }
        }
      };
      sendPostRequest();
    },
    UpdateClientDetails() {
      var clientdbIndex = this.clientsdb.findIndex(
        (data) => data === this.editedItem.Client_Acc
      );
      this.editedItem.Add1 = this.clientsdb[clientdbIndex].Add1;
      this.editedItem.Add2 = this.clientsdb[clientdbIndex].Add2;
      this.editedItem.Add3 = this.clientsdb[clientdbIndex].Add3;
      this.editedItem.Add4 = this.clientsdb[clientdbIndex].Add4;
      this.editedItem.VatNo = this.clientsdb[clientdbIndex].VatNo;

      this.editedItem.DelAdd1 = this.clientsdb[clientdbIndex].DelAdd1;
      this.editedItem.DelAdd2 = this.clientsdb[clientdbIndex].DelAdd2;
      this.editedItem.DelAdd3 = this.clientsdb[clientdbIndex].DelAdd3;
      this.editedItem.DelAdd4 = this.clientsdb[clientdbIndex].DelAdd4;

      this.clientName = this.clientsdb[clientdbIndex].Client;
      this.clientContact = this.clientsdb[clientdbIndex].Contact;
      this.clientCellNo = this.clientsdb[clientdbIndex].CellNo;

      if (this.enableNewItem == false) {
        this.enableNewItem = !this.enableNewItem;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedSOrderIndex > -1) {
          Object.assign(
            this.sorderItemList[this.editedSOrderIndex],
            this.editedItemSOrder
          );
        } else {
          this.editedItemSOrder.id = uid(6);
          this.sorderItemList.push(this.editedItemSOrder);
        }
        this.$refs.form.resetValidation();
        this.closeSOrder();
      }
    },
    validateSOrder() {
      if (this.sorderItemList.length == 0) {
        alert("Please add Sales Order items");
      } else {
        if (this.sorderSaved == false) {
          this.sorderSaved = !this.sorderSaved;
          this.enabledPrint = !this.enabledPrint;
          this.enableCancel = !this.enableCancel;
          this.enableClient = !this.enableClient;
          this.enableClose = !this.enableClose;
          this.enabledEmail = !this.enabledEmail;
        }
        if (this.isNewSOrder) {
          this.setSOrderNo();
        } else {
          this.updateSOrder();
        }
      }
    },
    updateSOrder() {
      this.updateSOrderDetails();
    },
    updateSOrderDetails() {
      const data = {
        Sub_Total: this.editedItem.Sub_Total,
        Vat: this.editedItem.Vat,
        Grand_Total: this.editedItem.Grand_Total,
        Quote_No: this.editedItem.Quote_No,
        Paid: this.editedItem.Paid,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/salesdetails/${this.editedItem.SO_No}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        this.deleteSOrder();
      });
    },
    deleteSOrder() {
      this.axios({
        method: "delete",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/salesorders/${this.editedItem.SO_No}`,
      }).then((response) => {
        this.dbres = response.data;
        this.postInsertSOrderList();
      });
    },
    closeSOrder() {
      this.dialog = false;
      this.editedItemSOrder = Object.assign({}, this.defaultItemSOrder);
      this.editedSOrderIndex = -1;
      this.$refs.form.resetValidation();
      this.sumTotal();
    },
    editItem(item) {
      this.editedSOrderIndex = this.sorderItemList.indexOf(item);
      this.editedItemSOrder = Object.assign({}, item);
      this.dialog = true;
    },
    editItemInventory(item) {
      this.editedIndexInv = this.inventories.indexOf(item);
      this.editedItemInv = Object.assign({}, item);
      this.editedItemSOrder.ItemCode = this.editedItemInv.I_Type;
      this.editedItemSOrder.Descr = this.editedItemInv.Display_Desc;
      this.editedItemSOrder.Price = this.editedItemInv.Sales_Price;
      this.searchinventory = null;
      this.dialog3 = false;
    },
    deleteItem(item) {
      this.id = item.id;
      const index = this.sorderItemList.indexOf(item);
      if (confirm("Are you sure you want to delete this Type?")) {
        this.sorderItemList.splice(index, 1);
        this.sumTotal();
        // this.postDeleteContrTypes();
      }
    },
    sumTotal() {
      let sorder_total = 0;
      let vat = 0;
      let Total = 0;
      this.sorderItemList.forEach((val) => {
        sorder_total += Number(val.Total);
      });
      this.editedItem.Sub_Total = this.formatNumber(sorder_total);
      if (this.systemsetup[0].Use_Vat == 1) {
        vat = sorder_total * 1.15 - sorder_total;
      }
      this.editedItem.Vat = this.formatNumber(vat);

      Total = this.formatNumber(sorder_total + vat);

      this.editedItem.Grand_Total = Total;
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    formatCurrency(num) {
      return this.formatter
        .format(num)
        .replace(/[^0-9\,.]/, "")
        .trim();
    },
    formatNumberTotal() {
      let sTotal = 0;
      this.DiscPerc = -1;
      let discP = -1;
      let Qty = -1;
      let Price = -1;
      let Discount = 0;
      this.DiscPerc = this.editedItemSOrder.Disc;
      discP = this.DiscPerc;
      Qty = this.editedItemSOrder.Qty;
      Price = this.editedItemSOrder.Price;

      if (Qty >= 0) {
        if (Price > 0) {
          if (discP >= 0) {
            if (discP > 0) {
              Discount = "0." + (100 - discP);
              sTotal = Qty * Price * Discount;
            } else {
              sTotal = Qty * Price;
            }
          }
        }
      }
      let temp = parseFloat(sTotal).toFixed(2);
      this.editedItemSOrder.Total = temp;
      // return temp;
    },
    close() {
      this.$router.push("/SOrder");
    },
    closeDialog2() {
      this.dialog2 = false;
      this.editedEmailItem = Object.assign({}, this.defaultEmailItem);
      this.$refs.vdialog2.resetValidation();
      this.file = null;
    },
    closeInv() {
      this.dialog3 = false;
      this.editedItemInv = Object.assign({}, this.defaultItemInv);
      this.editedIndexInv = -1;
      this.$refs.form.resetValidation();
      this.searchinventory = null;
    },
    emailInv() {
      this.editedEmailItem.textBody = `Dear ${this.clientName},\n\nPlease find attached the sales order dated ${this.editedItem.SO_Date}.\n\nAuthorised by ${this.clientContact}.\n\nIf you have any queries do not hesitate to contact us.\n\nTo open the attachment, please enter you cell phone number.\n\nKind regards\n${this.getUserFull}`;
      this.dialog2 = true;
    },
    print() {
      var fontSizes = {
        HeadTitleFontSize: 18,
        Head2TitleFontSize: 16,
        TitleFontSize: 14,
        SubTitleFontSize: 12,
        NormalFontSize: 10,
        SmallFontSize: 8,
      };
      var lineSpacing = {
        NormalSpacing: 12,
      };
      const columns = [
        { title: "Qty", dataKey: "Qty" },
        { title: "Item", dataKey: "ItemCode" },
        { title: "Description", dataKey: "Descr" },
        { title: "Unit Price", dataKey: "Price" },
        { title: "Disc %", dataKey: "Disc" },
        { title: "Total", dataKey: "Total" },
      ];

      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        lineHeights: 20,
        encryption: {
          userPassword: this.clientCellNo,
          userPermissions: ["print", "modify", "copy", "annot-forms"],
        },
      });

      doc.setFont("Times", "italic");
      doc.setFont(undefined, "bold");
      doc.setTextColor(192, 192, 192);
      doc.setFontSize(20).text(this.heading, 105, 20, { align: "center" });

      const logo = require("@/assets/logo.png");
      var imgLogo = new Image();
      imgLogo.src = logo;

      doc.addImage(imgLogo, "PNG", 12.7, 39, 30, 30);

      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "normal");

      if (this.systemsetup[0].Use_Vat == 1) {
        doc
          .setFontSize(8)
          .text(`Vat Reg No: ${this.VatRegNo}`, 105, 26, { align: "center" });
        doc.setFontSize(12).text(this.Company, 105, 32, { align: "center" });
      } else {
        doc.setFontSize(12).text(this.Company, 105, 26, { align: "center" });
      }

      doc.rect(146, 34, 50, 38);

      doc.setFontSize(8);
      doc.setFont("Times");
      doc.setFont(undefined, "normal");
      doc.text(this.getUserFull, 148, 39);
      doc.setFont(undefined, "normal");
      doc.text(this.CellNo, 180, 39);

      doc.setFont(undefined, "normal");
      doc.text("Tel No:", 148, 44);
      doc.setFont(undefined, "normal");
      if (this.TelNo) {
        doc.text(this.TelNo, 180, 44);
      }

      doc.setFont(undefined, "normal");
      doc.text("Fax No:", 148, 49);
      doc.setFont(undefined, "normal");
      if (this.FaxNo) {
        doc.text(this.FaxNo, 180, 49);
      }

      doc.setFont(undefined, "normal");
      doc.text(this.Website, 171, 54, "center");
      doc.setFont(undefined, "normal");
      doc.text(this.getUserEmail, 171, 59, "center");

      doc.setFont(undefined, "normal");
      doc.text("Sales Order No:", 148, 64);
      doc.setFont(undefined, "normal");

      doc.text(this.editedItem.SO_No.toString(), 180, 64);

      doc.setFont(undefined, "normal");
      doc.text("Date:", 148, 69);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.SO_Date, 180, 69);

      doc.setFontSize(10);
      //Bill To
      doc.setDrawColor(0);
      doc.setFillColor(192, 192, 192);
      doc.rect(12.7, 75, 75, 5.6, "FD");
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text("Bill To:", 13.7, 79);

      doc.setDrawColor(0);
      doc.setFillColor(225, 225, 225);
      doc.rect(12.7, 81, 75, 32, "FD");

      //Client
      doc.setFontSize(8);
      var clientdbIndex = this.clientsdb.findIndex(
        (data) => data.id === this.editedItem.Client_Acc
      );

      if (clientdbIndex == -1) {
        var clientdbIndex = this.clientsdb.findIndex(
          (data) => data.AccNo === this.editedItem.Client_Acc.AccNo
        );
      }

      let Client = this.clientsdb[clientdbIndex].Client;

      doc.setFont(undefined, "bold");
      doc.text("Client:", 13.7, 86);
      doc.setFont(undefined, "normal");
      doc.text(Client, 30, 86);

      //Street
      doc.setFont(undefined, "bold");
      doc.text("Street:", 13.7, 91);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Add1, 30, 91);

      //Area
      doc.setFont(undefined, "bold");
      doc.text("Area:", 13.7, 96);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Add2, 30, 96);

      //City
      doc.setFont(undefined, "bold");
      doc.text("City:", 13.7, 101);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Add3, 30, 101);

      //Code
      doc.setFont(undefined, "bold");
      doc.text("Code:", 13.7, 106);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.Add4, 30, 106);

      //Vat No
      doc.setFont(undefined, "bold");
      if (this.systemsetup[0].Use_Vat == 1) {
        doc.text("Vat No:", 13.7, 111);
      } else {
        doc.text(" ", 13.7, 111);
      }
      if (this.editedItem.VatNo) {
        doc.setFont(undefined, "normal");
        doc.text(this.editedItem.VatNo, 30, 111);
      }

      //Deliver To
      doc.setFontSize(10);
      doc.setDrawColor(0);
      doc.setFillColor(192, 192, 192);
      doc.rect(121, 75, 75, 5.6, "FD");
      doc.setTextColor(0, 0, 0);
      doc.setFont(undefined, "bold");
      doc.text("Deliver To:", 122, 79);

      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.setFillColor(225, 225, 225);
      doc.rect(121, 81, 75, 32, "FD");

      //Client
      doc.setFont(undefined, "bold");
      doc.text("Client:", 122, 86);
      doc.setFont(undefined, "normal");
      doc.text(Client, 138.3, 86);

      //Street
      doc.setFont(undefined, "bold");
      doc.text("Street:", 122, 91);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.DelAdd1, 138.3, 91);

      //Area
      doc.setFont(undefined, "bold");
      doc.text("Area:", 122, 96);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.DelAdd2, 138.3, 96);

      //City
      doc.setFont(undefined, "bold");
      doc.text("City:", 122, 101);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.DelAdd3, 138.3, 101);

      //Code
      doc.setFont(undefined, "bold");
      doc.text("Code:", 122, 106);
      doc.setFont(undefined, "normal");
      doc.text(this.editedItem.DelAdd4, 138.3, 106);

      // Using array of sentences
      doc.setFont("helvetica");
      doc.setFontSize(12);
      // doc.text(this.moreText, 12.7, 60, { align: "left", maxWidth: "190" });

      doc.setLineWidth(0.05).line(12.7, 115, 196, 115);

      // Using autoTable plugin
      doc.autoTable({
        columns,
        body: this.sorderItemList,
        // body: this.items,
        margin: { left: 12.7, top: 117 },
        // theme: "grid",
        columnStyles: {
          halign: "right",
          //   0: {
          //     columnWidth: 15,
          //   },
          //   1: {
          //     columnWidth: 25,
          //   },
          //   2: {
          //     columnWidth: 80.5,
          //   },
          //   3: {
          //     columnWidth: 24,
          //   },
          //   4: {
          //     columnWidth: 12,
          //   },
          //   5: {
          //     columnWidth: 27,
          //   },
        },
        styles: {
          fontSize: 8,
          font: "times",
          cellPadding: 1,
        },
      });

      // Creating footer and saving file
      doc
        .setLineWidth(0.01)
        .line(
          12.7,
          doc.internal.pageSize.height - 45,
          196,
          doc.internal.pageSize.height - 45
        );

      /////////////Bnaking Details////////////////
      //Account Name
      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text("Account Name:", 13.7, doc.internal.pageSize.height - 40);
      doc.setFont(undefined, "normal");
      doc.text(this.Company, 40, doc.internal.pageSize.height - 40);

      //Account No
      doc.setFont("Times", "bold");
      doc.text("Account No:", 13.7, doc.internal.pageSize.height - 35);
      if (this.AccNo) {
        doc.setFont(undefined, "normal");
        doc.text(this.AccNo, 40, doc.internal.pageSize.height - 35);
      }

      //Bank
      doc.setFont("Times", "bold");
      doc.text("Bank:", 13.7, doc.internal.pageSize.height - 30);
      doc.setFont(undefined, "normal");
      doc.text(this.Bank, 40, doc.internal.pageSize.height - 30);

      //Branch
      doc.setFont("Times", "bold");
      doc.text("Branch:", 13.7, doc.internal.pageSize.height - 25);
      doc.setFont(undefined, "normal");
      doc.text(this.Branch, 40, doc.internal.pageSize.height - 25);

      //Code
      doc.setFont("Times", "bold");
      doc.text("Code:", 13.7, doc.internal.pageSize.height - 20);
      doc.setFont(undefined, "normal");
      doc.text(this.BCode, 40, doc.internal.pageSize.height - 20);

      //Sub Total
      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text("Sub Total:", 150, doc.internal.pageSize.height - 40);
      doc.setFont(undefined, "normal");
      doc.text(
        this.formatCurrency(this.editedItem.Sub_Total),
        196,
        doc.internal.pageSize.height - 40,
        "right"
      );

      //Vat
      let vHeight = 40;
      if (this.systemsetup[0].Use_Vat == 1) {
        doc.setFont("Times", "bold");
        doc.text("Vat:", 150, doc.internal.pageSize.height - (vHeight - 5));
        doc.setFont(undefined, "normal");
        doc.text(
          this.formatCurrency(this.editedItem.Vat),
          196,
          doc.internal.pageSize.height - (vHeight - 5),
          "right"
        );
        vHeight = 35;
      }

      //Total
      doc.setFont("Times", "bold");
      doc.text("Total:", 150, doc.internal.pageSize.height - (vHeight - 5));
      doc.setFont(undefined, "normal");
      doc.text(
        this.formatCurrency(this.editedItem.Grand_Total),
        196,
        doc.internal.pageSize.height - (vHeight - 5),
        "right"
      );

      doc.setFont("times");
      doc.setFontSize(8);
      doc.setFont(undefined, "italic");
      //doc.setTextColor(0, 0, 255);//Blue
      doc.setTextColor(0, 0, 0);
      doc
        .setLineWidth(0.01)
        .line(
          12.7,
          doc.internal.pageSize.height - 16,
          196,
          doc.internal.pageSize.height - 16
        );
      doc.text(
        `${this.Company.toUpperCase()} - ${this.AddStreet.toUpperCase()}, ${this.AddArea.toUpperCase()}, ${this.AddCity.toUpperCase()}, ${
          this.AddAreaCode
        }, ${this.TelNo}`,
        105,
        doc.internal.pageSize.height - 12,
        { align: "center" }
      );

      doc
        .setLineWidth(0.01)
        .line(
          12.7,
          doc.internal.pageSize.height - 10,
          196,
          doc.internal.pageSize.height - 10
        );

      doc.save(`${this.heading} ${this.editedItem.SO_No}.pdf`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
