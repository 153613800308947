<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="mytasks"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>My Tasks</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md12>
                      <v-text-field
                        v-model="editedItem.task_name"
                        label="Project Title"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-autocomplete
                        v-model="editedItem.task_user"
                        label="User"
                        :items="users"
                        item-text="name"
                        item-value="name"
                        prepend-inner-icon="mdi-checkbox-marked-outline"
                        :rules="[rules.required]"
                        auto-select-first
                        clearable
                        dense
                        filled
                        outlined
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-autocomplete
                        v-model="editedItem.task_status"
                        label="Status"
                        :items="statusdb"
                        item-text="Status"
                        item-value="Status"
                        prepend-inner-icon="mdi-checkbox-marked-outline"
                        :rules="[rules.required]"
                        auto-select-first
                        clearable
                        dense
                        filled
                        outlined
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-menu
                        v-model="datecreate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.task_date_created"
                            label="Created Date"
                            prepend-inner-icon="date_range"
                            filled
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.task_date_created"
                          @input="datecreate = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12 sm6 md6>
                      <v-menu
                        v-model="datedue"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.task_date_due"
                            label="Due Date"
                            prepend-inner-icon="date_range"
                            filled
                            dense
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.task_date_due"
                          @input="datedue = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12 sm6 md6>
                      <v-menu
                        v-model="datestart"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.task_date_start"
                            label="Start Date"
                            prepend-inner-icon="date_range"
                            filled
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.task_date_start"
                          @input="datestart = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12 sm6 md6>
                      <v-menu
                        v-model="dateend"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.task_date_end"
                            label="End Date"
                            prepend-inner-icon="date_range"
                            filled
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.task_date_end"
                          @input="dateend = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex xs12 sm6 md12>
                      <v-textarea
                        v-model="editedItem.task_details"
                        label="Description"
                        prepend-inner-icon="description"
                        :rules="[rules.required]"
                        :counter="250"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="close"
                  >Cancel</v-btn
                >
                <v-btn color="primary" dark class="mb-2" @click="validate"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    valid: true,
    datedue: false,
    datestart: false,
    datecreate: false,
    dateend: false,
    show1: false,
    id: "",
    search: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 10 || "Min 10 characters",
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      counteraddMax: (value) => value.length <= 4 || "Max 4 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Task No",
        align: "left",
        sortable: true,
        value: "id",
        width: "10%",
      },
      { text: "Person", align: "left", value: "task_user", width: "10%" },
      { text: "Project Title", align: "left", value: "task_name" },
      { text: "Due By", align: "left", value: "task_date_due" },
      { text: "Status", align: "left", value: "task_status" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "8%",
      },
    ],
    mytasks: [],
    statusdb: [],
    users: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      task_user: "",
      task_name: "",
      task_details: "",
      task_date_created: "",
      task_date_due: "",
      task_date_start: "",
      task_date_end: "",
      task_status: "",
    },
    defaultItem: {
      id: "",
      task_user: "",
      task_name: "",
      task_details: "",
      task_date_created: "",
      task_date_due: "",
      task_date_start: "",
      task_date_end: "",
      task_status: "",
    },
    acctypetest: [{ test: "admin" }, { test: "test" }],
  }),
  beforeMount: function () {
    let self = this;
    self.getMyTasks();
    self.getStatus();
    self.getUsers();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Task" : "Edit Task";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    getMyTasks: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/mytasks`,
      }).then((response) => {
        this.mytasks = response.data;
      });
    },
    getStatus: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/statuses`,
      }).then((response) => {
        this.statusdb = response.data;
      });
    },
    getUsers: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/users`,
      }).then((response) => (this.users = response.data));
    },
    getMyTasksAfterInsert: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/mytasks`,
      }).then((response) => {
        this.mytasks = response.data;
      });
    },
    postInsertMyTasks: function () {
      const data = {
        task_user: this.editedItem.task_user,
        task_date_created: this.editedItem.task_date_created,
        task_date_due: this.editedItem.task_date_due,
        task_date_start: this.editedItem.task_date_start,
        task_date_end: this.editedItem.task_date_end,
        task_details: this.editedItem.task_details,
        task_name: this.editedItem.task_name,
        task_status: this.editedItem.task_status,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/mytasks`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        this.getMyTasksAfterInsert();
      });
    },
    postUpdateMyTasks: function () {
      const data = {
        id: this.editedItem.id,
        task_user: this.editedItem.task_user,
        task_date_created: this.editedItem.task_date_created,
        task_date_due: this.editedItem.task_date_due,
        task_date_start: this.editedItem.task_date_start,
        task_date_end: this.editedItem.task_date_end,
        task_details: this.editedItem.task_details,
        task_name: this.editedItem.task_name,
        task_status: this.editedItem.task_status,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/mytasks/${this.editedItem.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postDeleteMyTasks: function () {
      const data = {
        id: this.id,
      };
      this.axios({
        method: "delete",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/mytasks/${this.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.mytasks.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.id = item.id;
      const index = this.mytasks.indexOf(item);
      if (confirm("Are you sure you want to delete this task?")) {
        this.mytasks.splice(index, 1);
        this.postDeleteMyTasks();
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.mytasks[this.editedIndex], this.editedItem);
      } else {
        this.mytasks.push(this.editedItem);
      }
      this.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.mytasks[this.editedIndex], this.editedItem);
          this.postUpdateMyTasks();
        } else {
          this.mytasks.push(this.editedItem);
          this.postInsertMyTasks();
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
  },
};
</script>
