<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="invoicedetails"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Payments</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New
              </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="Payment_Date"
                            label="Payment Date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="Payment_Date"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.Grand_Total"
                        label="Total"
                        prepend-inner-icon="mdi-checkbox-marked-outline"
                        outlined
                        dense
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.Amt_Due"
                        label="Amount Due"
                        prepend-inner-icon="mdi-checkbox-marked-outline"
                        outlined
                        dense
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.Amt_Paid"
                        label="Amount Paid"
                        prepend-inner-icon="mdi-checkbox-marked-outline"
                        :rules="[rules.required]"
                        outlined
                        dense
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="Payment_Received"
                        label="Amount Received"
                        prepend-inner-icon="mdi-checkbox-marked-outline"
                        :rules="[rules.required]"
                        outlined
                        clearable
                        dense
                        hide-details
                        type="number"
                        hide-spin-buttons
                        oninput="if(this.value < 0) this.value = 0;"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="close"
                  >Cancel</v-btn
                >
                <v-btn color="primary" dark class="mb-2" @click="validate"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
import { uid } from "uid";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    show1: false,
    id: "",
    search: "",
    Payment_Date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    Payment_Received: "0.00",
    Balance: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      { text: "Inv Date", value: "Inv_Date", width: "10%" },
      {
        text: "Invoice No",
        align: "left",
        sortable: true,
        value: "Invoice_No",
        width: "10%",
      },
      { text: "Client", value: "Client_Acc", width: "40%" },
      { text: "Total", value: "Grand_Total", width: "10%" },
      { text: "Amount Paid", value: "Amt_Paid", width: "10%" },
      { text: "Amount Due", value: "Amt_Due", width: "10%" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "10%",
      },
    ],
    invoicedetails: [],
    bankbalance: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      Invoice_No: "",
      Client_Acc: "",
      Sub_Total: "",
      Grand_Total: "",
      Amt_Paid: "",
      Amt_Due: "",
      Paid: "",
      Inv_Date: "",
    },
    defaultItem: {
      id: "",
      Invoice_No: "",
      Client_Acc: "",
      Sub_Total: "",
      Grand_Total: "",
      Amt_Paid: "",
      Amt_Due: "",
      Paid: "",
      Inv_Date: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getInvoiceDetails();
  },
  computed: {
    formTitle() {
      return (this.editedIndex = "Receive Payment(s)");
    },
    getUserFull: function () {
      return localStorage.getItem("fullname");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {},
  methods: {
    getInvoiceDetails: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/invoicesdetails/payment`,
      }).then((response) => (this.invoicedetails = response.data));
    },
    editItem(item) {
      this.editedIndex = this.invoicedetails.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.resetValidation();
      this.payment_Calc();
    },
    payment_Calc() {
      let Total = 0;
      let TotalDue = 0;
      let TotalPaid = 0;
      let NewTotalDue = 0;
      let NewPaiment = 0;
      let PrevPaiment = 0;

      Total = this.editedItem.Grand_Total;
      TotalDue = this.editedItem.Amt_Due;

      NewPaiment = this.Payment_Received;

      if (!this.editedItem.Amt_Paid) {
        this.editedItem.Amt_Paid = "0.00";
      }

      PrevPaiment = this.editedItem.Amt_Paid;

      TotalPaid = this.formatNumber(
        parseFloat(NewPaiment) + parseFloat(PrevPaiment)
      );

      this.editedItem.Amt_Paid = TotalPaid;

      NewTotalDue = this.formatNumber(TotalDue - this.Payment_Received);

      this.editedItem.Amt_Due = NewTotalDue;

      this.updateInvoice(NewTotalDue);
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.Payment_Received = "0.00";
      this.$refs.form.resetValidation();
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    updateInvoice(NewTotalDue) {
      let PaidFull = "";
      if (NewTotalDue == 0.0) {
        PaidFull = "Paid";
      } else {
        PaidFull = "";
      }

      const data = {
        //id: this.editedItem.Invoice_No,
        Amt_Paid: this.editedItem.Amt_Paid,
        Amt_Due: this.editedItem.Amt_Due,
        Paid: PaidFull,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/invoicesdetails/${this.editedItem.Invoice_No}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        this.getBankBalance();
      });
    },
    insertBankBalance() {
      let CurrentBalance = 0;
      let Pay_Received = 0;
      let TotalBalance = 0;
      CurrentBalance = this.Balance;
      Pay_Received = this.Payment_Received;
      TotalBalance = this.formatNumber(
        parseFloat(CurrentBalance) + parseFloat(Pay_Received)
      );
      const data = {
        Payment_Date: this.Payment_Date,
        Description:
          "Payment from " +
          this.editedItem.Client_Acc +
          " Invoice no " +
          this.editedItem.Invoice_No,
        Credit: this.formatNumber(this.Payment_Received),
        Debit: "0.00",
        Balance: TotalBalance,
        Client: this.editedItem.Client_Acc,
        InvNo: this.editedItem.Invoice_No,
        Created_By: this.getUserFull,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/bankbalances`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
        this.close();
        this.getInvoiceDetails();
      });
    },
    getBankBalance() {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/bankbalances`,
      }).then((response) => {
        this.Balance = response.data[0].Balance;
        this.insertBankBalance();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  width: 100%;
}

.table-heading,
.table-items {
  gap: 5px;
  font-size: 12px;
}

.item-name {
  flex-basis: 50%;

  .item {
    flex-basis: 10%;
  }

  .qty {
    flex-basis: 5%;
  }

  .price {
    flex-basis: 15%;
  }

  .discount {
    flex-basis: 5%;
  }

  .total {
    flex-basis: 15%;
  }

  .table-heading {
    margin-bottom: 16px;
  }

  th {
    text-align: center;
  }

  td {
    text-align: center;
  }

  .button {
    color: #fff;
    // background-color: #252945;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  img {
    margin-right: 4px;
  }

  .table-items {
    position: relative;
    margin-bottom: 24px;

    img {
      position: absolute;
      top: 15px;
      right: 0;
      width: 12px;
      height: 16px;
    }
  }

  .input {
    margin-bottom: 24px;
  }

  input,
  select {
    width: 100%;
    background-color: #1e2139;
    color: #fff;
    border-radius: 4px;
    padding: 12px 4px;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .save {
    margin-top: 60px;

    div {
      flex: 1;
    }

    .right {
      justify-content: flex-end;
    }
  }
}
</style>
