<template>
  <div class="my-5">
    <v-main>
      <v-container fluid fill-height>
        <v-row class="align-center justify-center">
          <v-col cols="12" sm="8" md="4" lg="3">
            <v-card class="elevation-11">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Forgot Password</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <div class="text-center">
                    <p>
                      We will send a password reset link to the email address of
                      the connected account.
                    </p>
                  </div>

                  <v-text-field
                    v-model="editedItem.email"
                    prepend-inner-icon="email"
                    name="login"
                    label="Email"
                    type="text"
                    outlined
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                  <v-btn color="primary" x-large block @click="validate"
                    >Send</v-btn
                  >
                </v-form>
              </v-card-text>
              <v-card-actions>
                {{ status }}
                {{ loading }}
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    loading: "",
    status: "",
    editedItem: {
      email: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.forgotPwd();
        this.$refs.form.resetValidation();
      }
    },
    forgotPwd: function () {
      this.status = "";
      this.loading = "Sending reset request, please wait....";
      const data = {
        email: this.editedItem.email,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/auth/send-password-reset-email`,
        data: data,
      }).then((response) => {
        this.loading = "";
        this.status = response.data.message;
        this.dbres = response.data;
      });
    },
  },
};
</script>
