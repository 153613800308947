<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="paymenttypes"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Payment Types</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.p_type"
                        label="Payment Type"
                        prepend-inner-icon="mdi-cash"
                        :rules="[rules.required]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="close"
                  >Cancel</v-btn
                >
                <v-btn color="primary" dark class="mb-2" @click="validate"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    show1: false,
    id: "",
    search: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Type",
        align: "left",
        sortable: true,
        value: "p_type",
      },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
    paymenttypes: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      p_type: "",
    },
    defaultItem: {
      id: "",
      p_type: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getPaymentTypes();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Type" : "Edit Type";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {},
  methods: {
    getPaymentTypes: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/paymenttypes`,
      }).then((response) => (this.paymenttypes = response.data));
    },
    postInsertPaymentTypes: function () {
      const data = {
        p_type: this.editedItem.p_type,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/paymenttypes`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postUpdatePaymentTypes: function () {
      const data = {
        p_type: this.editedItem.p_type,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/paymenttypes/${this.editedItem.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postDeletePaymentTypes: function () {
      const data = {
        id: this.id,
      };
      this.axios({
        method: "delete",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/paymenttypes/${this.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.paymenttypes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.id = item.id;
      const index = this.paymenttypes.indexOf(item);
      if (confirm("Are you sure you want to delete this Type?")) {
        this.paymenttypes.splice(index, 1);
        this.postDeletePaymentTypes();
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.paymenttypes[this.editedIndex], this.editedItem);
      } else {
        this.paymenttypes.push(this.editedItem);
      }
      this.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.paymenttypes[this.editedIndex], this.editedItem);

          this.postUpdatePaymentTypes();
        } else {
          this.postInsertPaymentTypes();
          this.paymenttypes.push(this.editedItem);
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
  },
};
</script>
