import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5"},[_c(VMain,[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,{staticClass:"align-center justify-center"},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"4","lg":"3"}},[_c(VCard,{staticClass:"elevation-11"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Forgot Password")])],1),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(" We will send a password reset link to the email address of the connected account. ")])]),_c(VTextField,{attrs:{"prepend-inner-icon":"email","name":"login","label":"Email","type":"text","outlined":"","rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}}),_c(VBtn,{attrs:{"color":"primary","x-large":"","block":""},on:{"click":_vm.validate}},[_vm._v("Send")])],1)],1),_c(VCardActions,[_vm._v(" "+_vm._s(_vm.status)+" "+_vm._s(_vm.loading)+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }