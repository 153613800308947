<template>
  <v-form ref="form">
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-3" inset vertical left></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New User
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="regEmail"
                        label="Email"
                        prepend-inner-icon="email"
                        :rules="[rules.required, rules.email]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-select
                        label="Account Type"
                        :items="acctype"
                        item-text="type"
                        item-value="type"
                        v-model="regAccType"
                        prepend-inner-icon="account_box"
                        :rules="[rules.required]"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                {{ status }}
                {{ loading }}
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="close"
                  >Close</v-btn
                >
                <v-btn color="primary" dark class="mb-2" @click="validate"
                  >Send</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog2" max-width="700px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editedItem.surname"
                        label="Surname"
                        prepend-inner-icon="person"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        prepend-inner-icon="email"
                        :rules="[rules.required, rules.email]"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md6>
                      <v-select
                        label="Account Type"
                        :items="acctype"
                        item-text="type"
                        item-value="type"
                        v-model="editedItem.acctype"
                        prepend-inner-icon="account_box"
                        :rules="[rules.required]"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="close2"
                  >Close</v-btn
                >
                <v-btn color="primary" dark class="mb-2" @click="validate2"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    dialogDelete: false,
    show1: false,
    id: "",
    search: "",
    regEmail: "",
    regAccType: "",
    loading: "",
    status: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: true,
        value: "name",
      },
      { text: "Surname", value: "surname" },
      { text: "Email", value: "email" },
      { text: "Acc Type", value: "acctype" },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
    users: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      surname: "",
      email: "",
      acctype: "",
    },
    defaultItem: {
      id: "",
      name: "",
      surname: "",
      email: "",
      acctype: "",
    },
    acctype: [],
    acctypetest: [{ test: "admin" }, { test: "test" }],
  }),
  beforeMount: function () {
    let self = this;
    self.getUsers();
    this.getAccType();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    getUsers: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/users`,
      }).then((response) => (this.users = response.data));
    },
    getAccType: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/acctypes`,
      }).then((response) => {
        this.acctype = response.data;
      });
    },
    postInsertUser: function () {
      const data = {
        name: this.editedItem.name,
        surname: this.editedItem.surname,
        email: this.editedItem.email,
        acctype: this.editedItem.acctype,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/users`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postUpdateUser: function () {
      const data = {
        name: this.editedItem.name,
        surname: this.editedItem.surname,
        email: this.editedItem.email,
        acctype: this.editedItem.acctype,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/users/${this.editedItem.id}`,
        data: data,
      }).then((response) => {
        console.log(response.data.message);
        alert(response.data.message);
      });
    },
    postDeleteUser: function () {
      const data = {
        id: this.id,
      };
      this.axios({
        method: "delete",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/users/${this.id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    postRegisterUser: function () {
      let newAcctype = "";
      if (this.regAccType == "Super User") {
        newAcctype = "SuperUser";
      } else {
        newAcctype = this.regAccType;
      }

      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/auth/send-register-email/${this.regEmail}/${newAcctype}`,
      }).then((response) => {
        this.loading = "";
        this.status = response.data.message;
        // this.dbres = response.data;
        console.log(response);
      });
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog2 = true;
    },

    deleteItem(item) {
      this.id = item.id;
      const index = this.users.indexOf(item);
      if (confirm("Are you sure you want to delete this user?")) {
        this.users.splice(index, 1);
        this.postDeleteUser();
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
    close2() {
      this.dialog2 = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }
      this.close();
    },
    validate() {
      if (this.regAccType != "" && this.regEmail != "") {
        this.status = "";
        this.loading = "Sending email, please wait....";
        this.postRegisterUser();
      }

      // if (this.editedIndex > -1) {
      //   console.log("3");
      //   // Object.assign(this.users[this.editedIndex], this.editedItem);

      // }
      this.$refs.form.resetValidation();
      //this.close();
    },
    validate2() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem);
          this.postUpdateUser();
        } else {
          this.postInsertUser();
          this.users.push(this.editedItem);
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
  },
};
</script>
