<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-card outlined>
            <v-toolbar color="primary" dense>
              <v-toolbar-title>General Information:</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-layout align-start row fill-height>
                <v-container fill-height grid-list-md text-xs-center>
                  <v-layout column>
                    <v-flex>
                      <v-card>
                        <v-card-text> Need to do </v-card-text>
                      </v-card>
                      <v-card>
                        <v-card-text>Clean Garage</v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>

                <v-container>
                  <v-layout column>
                    <v-flex>
                      <v-card>
                        <v-card-text> Need to do </v-card-text>
                      </v-card>
                      <v-card>
                        <v-card-text>Clean Garage</v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-container>
                  <v-layout column>
                    <v-flex>
                      <v-card>
                        <v-card-text> Need to do </v-card-text>
                      </v-card>
                      <v-card>
                        <v-card-text>Clean Garage</v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- <v-col xs="12" sm="12" md="4">
                  <v-flex>
                    <v-autocomplete
                      v-model="Emp_Id"
                      label="Employee"
                      :items="employeedb"
                      :item-text="(item) => item.Name + ' ' + item.Surname"
                      item-value="id"
                      prepend-inner-icon="person"
                      auto-select-first
                      dense
                      outlined
                      return-object
                      hide-details
                      @change="UpdateEmpDetails"
                    ></v-autocomplete>
                  </v-flex>
                  <v-row>
                    <v-col xs="12" sm="12" md="6">
                      <v-flex>
                        <v-text-field
                          v-model="editedItem.PayslipNo"
                          label="Payslip No"
                          outlined
                          clearable
                          dense
                          readonly
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-col>

                    <v-flex>
                      <v-checkbox
                        :label="`Payslip Issued`"
                        false-value="0"
                        true-value="1"
                        value="PayslipI"
                        id="PayslipI"
                        v-model="PaySlipIssued"
                        @change="checkPaySlipIssued"
                        hide-details
                      >
                      </v-checkbox>
                    </v-flex>
                  </v-row>
                </v-col> -->

                <!-- <v-col xs="12" sm="12" md="4">
                  <v-flex> </v-flex>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-flex> </v-flex>
                </v-col> -->

                <!-- <v-col xs="12" sm="12" md="4">
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.Name"
                      :rules="[rules.required]"
                      label="Name"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.IDNo"
                      :rules="[rules.required]"
                      label="ID No"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.DOE"
                      :rules="[rules.required]"
                      label="D.O.E"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.BankName"
                      :rules="[rules.required]"
                      label="Bank"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.Method"
                      :rules="[rules.required]"
                      label="Pay Method"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.Email"
                      :rules="[rules.required]"
                      label="Email"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-col>
                <v-col xs="12" sm="12" md="4">
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.Surname"
                      :rules="[rules.required]"
                      label="Surname"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.Occupation"
                      :rules="[rules.required]"
                      label="Title"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.Company"
                      :rules="[rules.required]"
                      label="Leave"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.AccNo"
                      :rules="[rules.required]"
                      label="Acc No"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.PerHour"
                      :rules="[rules.required]"
                      label="Per Hour"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-col>
                <v-col xs="12" sm="12" md="4">
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.Company"
                      :rules="[rules.required]"
                      label="Pay Month"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.EmpId"
                      :rules="[rules.required]"
                      label="Emp Code"
                      outlined
                      dense
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                      :label="`Payslip Issued`"
                      false-value="0"
                      true-value="1"
                      value="PayslipI"
                      id="PayslipI"
                      v-model="PaySlipIssued"
                      @change="checkPaySlipIssued"
                    >
                    </v-checkbox>
                  </v-flex>
                </v-col> -->
              </v-layout>
            </v-card-text>
          </v-card>

          <!-- <v-column cols="12" sm="4"> </v-column> -->
        </v-container>
        <v-container> Earnings + Deductions </v-container>
        <v-container>
          Year to Date / Month to Date / Company Contributions / Nett Pay
        </v-container>
        <v-container> Buttons </v-container>
        <v-footer padless dense>
          <v-row justify="center" no-gutters>
            <v-btn
              tile
              :disabled="!enableCancel"
              color="primary"
              dark
              class="mx-2"
              >Cancel</v-btn
            >
            <v-btn
              tile
              :disabled="!enableSave"
              color="primary"
              dark
              class="mb-2"
              >Save</v-btn
            >
            <v-btn
              tile
              :disabled="!enabledPrint"
              color="primary"
              dark
              class="mx-2"
              >Print</v-btn
            >
            <v-btn
              tile
              :disabled="!enableClose"
              color="primary"
              dark
              class="mb-2"
              >Close</v-btn
            >
          </v-row>
        </v-footer>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { uid } from "uid";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
export default {
  data: () => ({
    employeedb: [],
    enabledPrint: false,
    enableCancel: true,
    enableSave: true,
    invoiceSaved: false,
    enableNewItem: false,
    enableClient: true,
    enableClose: false,
    isNewInvoice: false,
    Emp_Id: "",
    PaySlipIssued: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 10 || "Min 10 characters",
      minId: (v) => v.length >= 13 || "Min 13 characters",
      requiredId: (value) => {
        if (value == true) {
          return true;
        }
        if (value == false) {
          return false;
        }
      },
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      counteraddMax: (value) => value.length <= 4 || "Max 4 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    editedItem: {
      id: "",
      PayslipNo: "",
      EmpId: "",
      Name: "",
      Surname: "",
      IDNo: "",
      PassNo: "",
      UIF: "",
      DOE: "",
      Occupation: "",
      BankName: "",
      AccNo: "",
      Method: "",
      Email: "",
      PerHour: "",
    },
    defaultItem: {
      id: "",
      PayslipNo: "",
      EmpId: "",
      Name: "",
      Surname: "",
      IDNo: "",
      PassNo: "",
      UIF: "",
      DOE: "",
      Occupation: "",
      BankName: "",
      AccNo: "",
      Method: "",
      Email: "",
      PerHour: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getEmployees();
  },
  computed: {
    formTitle() {
      return this.$route.params.id === "-1"
        ? "New Payslip"
        : "Edit Payslip: " + this.$route.params.id;
    },
  },
  methods: {
    getEmployees() {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/personnel`,
      }).then((response) => (this.employeedb = response.data));
    },
    UpdateEmpDetails() {
      var employeedbIndex = this.employeedb.findIndex(
        (data) => data === this.Emp_Id
      );

      this.editedItem.EmpId = this.employeedb[employeedbIndex].EmpId;
      this.editedItem.Name = this.employeedb[employeedbIndex].Name;
      this.editedItem.Surname = this.employeedb[employeedbIndex].Surname;
      this.editedItem.IDNo = this.employeedb[employeedbIndex].IDNo;
      this.editedItem.PassNo = this.employeedb[employeedbIndex].PassNo;
      this.editedItem.UIF = this.employeedb[employeedbIndex].UIF;

      this.editedItem.DOE = this.employeedb[employeedbIndex].DOE;
      this.editedItem.Occupation = this.employeedb[employeedbIndex].Occupation;
      this.editedItem.BankName = this.employeedb[employeedbIndex].BankName;
      this.editedItem.AccNo = this.employeedb[employeedbIndex].AccNo;

      this.editedItem.Method = this.employeedb[employeedbIndex].Method;
      this.editedItem.PerHour = this.employeedb[employeedbIndex].PerHour;

      this.editedItem.Email = this.employeedb[employeedbIndex].Email;

      //   if (this.enableNewItem == false) {
      //     this.enableNewItem = !this.enableNewItem;
      //   }
      this.getEmployeesPaySlipNo();
    },
    checkPaySlipIssued() {
      this.$nextTick(() => {
        let elt = document.getElementById("PayslipI");
        // if (elt.checked) {
        //   this.enabledVat = true;
        // } else {
        //   this.enabledVat = false;
        // }
      });
    },
    getEmployeesPaySlipNo() {
      const data = {
        EmpId: this.editedItem.EmpId,
      };
      this.axios({
        method: "post",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/payslipcountbyempid`,
        data: data,
      }).then((response) => {
        var EmpPaySlipNo = response.data[0].count;

        console.log("Count 1 " + EmpPaySlipNo);

        EmpPaySlipNo++;

        console.log("Count 2 " + EmpPaySlipNo);
        // if (EmpCode < 10) {
        //   EmpCode = this.SEmpCode + "00" + EmpCode;
        // } else if ((AccCode >= 10) & (EmpCode < 100)) {
        //   EmpCode = this.SEmpCode + "0" + EmpCode;
        // }
        this.editedItem.PayslipNo = EmpPaySlipNo;
      });
    },
  },
};
</script>
