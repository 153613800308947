import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{ref:"form"},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"color":"primary","dense":""}},[_c(VToolbarTitle,[_vm._v("General Information:")])],1),_c(VCardText,[_c(VLayout,{attrs:{"align-start":"","row":"","fill-height":""}},[_c(VContainer,{attrs:{"fill-height":"","grid-list-md":"","text-xs-center":""}},[_c(VLayout,{attrs:{"column":""}},[_c(VFlex,[_c(VCard,[_c(VCardText,[_vm._v(" Need to do ")])],1),_c(VCard,[_c(VCardText,[_vm._v("Clean Garage")])],1)],1)],1)],1),_c(VContainer,[_c(VLayout,{attrs:{"column":""}},[_c(VFlex,[_c(VCard,[_c(VCardText,[_vm._v(" Need to do ")])],1),_c(VCard,[_c(VCardText,[_vm._v("Clean Garage")])],1)],1)],1)],1),_c(VContainer,[_c(VLayout,{attrs:{"column":""}},[_c(VFlex,[_c(VCard,[_c(VCardText,[_vm._v(" Need to do ")])],1),_c(VCard,[_c(VCardText,[_vm._v("Clean Garage")])],1)],1)],1)],1)],1)],1)],1)],1),_c(VContainer,[_vm._v(" Earnings + Deductions ")]),_c(VContainer,[_vm._v(" Year to Date / Month to Date / Company Contributions / Nett Pay ")]),_c(VContainer,[_vm._v(" Buttons ")]),_c(VFooter,{attrs:{"padless":"","dense":""}},[_c(VRow,{attrs:{"justify":"center","no-gutters":""}},[_c(VBtn,{staticClass:"mx-2",attrs:{"tile":"","disabled":!_vm.enableCancel,"color":"primary","dark":""}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"mb-2",attrs:{"tile":"","disabled":!_vm.enableSave,"color":"primary","dark":""}},[_vm._v("Save")]),_c(VBtn,{staticClass:"mx-2",attrs:{"tile":"","disabled":!_vm.enabledPrint,"color":"primary","dark":""}},[_vm._v("Print")]),_c(VBtn,{staticClass:"mb-2",attrs:{"tile":"","disabled":!_vm.enableClose,"color":"primary","dark":""}},[_vm._v("Close")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }