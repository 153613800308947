<template>
    <div class="reports">
        <h1 class="subheading grey--text">Statements</h1>

        <v-container class="my-5">
            content
          </v-container>
    </div>    
</template>

<script>
export default {
    name: 'reports'
}
</script>