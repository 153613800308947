import Vue from "vue";
import VueRouter from "vue-router";
import Bank from "@/components/Bank.vue";
import Login from "@/components/Login";
import Clients from "@/components/Clients";
import Users from "@/components/Users";
import MyTasks from "@/components/MyTasks";
import Reports from "@/components/Reports";
import Dashboard from "@/components/Dashboard";
import SendMail from "@/components/SendMail";
import store from "@/store";
import Invoice from "@/components/Invoice";
import Quote from "@/components/Quote";
import POrder from "@/components/POrder";
import SOrder from "@/components/SOrder";
import Payments from "@/components/Payments";
import Statements from "@/components/Statements";
import Personnel from "@/components/Personnel";
import Planner from "@/components/Planner";
import Payslips from "@/components/Payslips";
import BankAccType from "@/components/BankAccType";
import Contributions from "@/components/Contributions";
import DeductionsType from "@/components/DeductionsType";
import Deductions from "@/components/Deductions";
import Discount from "@/components/Discount";
import Earnings from "@/components/Earnings";
import JobTitle from "@/components/JobTitle";
import PayMethod from "@/components/PayMethod";
import Supplier from "@/components/Supplier";
import SystemSetup from "@/components/SystemSetup";
import Terms from "@/components/Terms";
import Inventory from "@/components/Inventory";
import Status from "@/components/Status";
import EmpStatus from "@/components/EmpStatus";
import InvoiceModal from "@/components/InvoiceModal";
import QuoteModal from "@/components/QuoteModal";
import POrderModal from "@/components/POrderModal";
import SOrderModal from "@/components/SOrderModal";
import PaySlipsModal from "@/components/PaySlipsModal";
import InvoiceQModal from "@/components/InvoiceQModal";
import ResetPassword from "@/components/ResetPassword";
import ForgotPassword from "@/components/ForgotPassword";
import Register from "@/components/Register";
import Support from "@/components/Support";
import InventoryModal from "@/components/InventoryModal";
import Expenses from "@/components/Expenses";
import ExpensesModal from "@/components/ExpensesModal";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/ResetPassword/:id/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/Register/:email/:acctype/:token",
    name: "Register",
    component: Register,
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Users",
    name: "Users",
    component: Users,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/MyTasks",
    name: "MyTasks",
    component: MyTasks,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Reports",
    name: "Reports",
    component: Reports,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/sendmail",
    name: "SendMail",
    component: SendMail,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Invoice",
    name: "Invoice",
    component: Invoice,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Quotations",
    name: "Quotations",
    component: Quote,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/POrder",
    name: "POrder",
    component: POrder,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/SOrder",
    name: "SOrder",
    component: SOrder,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Payments",
    name: "Payments",
    component: Payments,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Statements",
    name: "Statements",
    component: Statements,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Personnel",
    name: "Personnel",
    component: Personnel,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Planner",
    name: "Planner",
    component: Planner,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Payslips",
    name: "Payslips",
    component: Payslips,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Bank",
    name: "Bank",
    component: Bank,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/BankAccType",
    name: "BankAccType",
    component: BankAccType,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Contributions",
    name: "Contributions",
    component: Contributions,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/DeductionsType",
    name: "DeductionsType",
    component: DeductionsType,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Deductions",
    name: "Deductions",
    component: Deductions,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Discount",
    name: "Discount",
    component: Discount,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Earnings",
    name: "Earnings",
    component: Earnings,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/JobTitle",
    name: "JobTitle",
    component: JobTitle,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/PayMethod",
    name: "PayMethod",
    component: PayMethod,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Supplier",
    name: "Supplier",
    component: Supplier,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/SystemSetup",
    name: "SystemSetup",
    component: SystemSetup,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  // {
  //   path: "/SystemSetup",
  //   name: "SystemSetup",
  //   component: SystemSetup,
  //   beforeEnter: (to, from, next) => {
  //
  //     if (canAccess()) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   },
  // },
  {
    path: "/Terms",
    name: "Terms",
    component: Terms,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Inventory",
    name: "Inventory",
    component: Inventory,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Status",
    name: "Status",
    component: Status,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/EmpStatus",
    name: "EmpStatus",
    component: EmpStatus,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/InvoiceModal/:id",
    name: "InvoiceModal",
    component: InvoiceModal,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/QuoteModal/:id",
    name: "QuoteModal",
    component: QuoteModal,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/POrderModal/:id",
    name: "POrderModal",
    component: POrderModal,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/SOrderModal/:id",
    name: "SOrderModal",
    component: SOrderModal,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/PaySlipsModal/:id",
    name: "PaySlipsModal",
    component: PaySlipsModal,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/InvoiceQModal/:id",
    name: "InvoiceQModal",
    component: InvoiceQModal,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Support",
    name: "Support",
    component: Support,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/InventoryModal/:id",
    name: "InventoryModal",
    component: InventoryModal,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/Expenses",
    name: "Expenses",
    component: Expenses,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
  {
    path: "/ExpensesModal/:id",
    name: "ExpensesModal",
    component: ExpensesModal,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next(false);
      }
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next("/dashboard");
      return;
    }
    next("/");
  } else {
    next();
  }
});

Vue.component("demo-grid", {
  template: "#grid-template",
  props: {
    heroes: Array,
    columns: Array,
    filterKey: String,
  },
  data: function () {
    var sortOrders = {};
    this.columns.forEach(function (key) {
      sortOrders[key] = 1;
    });
    return {
      sortKey: "",
      sortOrders: sortOrders,
    };
  },
  computed: {
    filteredHeroes: function () {
      var sortKey = this.sortKey;
      var filterKey = this.filterKey && this.filterKey.toLowerCase();
      var order = this.sortOrders[sortKey] || 1;
      var heroes = this.heroes;
      if (filterKey) {
        heroes = heroes.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (sortKey) {
        heroes = heroes.slice().sort(function (a, b) {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return heroes;
    },
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  methods: {
    sortBy: function (key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
  },
});

export default router;
