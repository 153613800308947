<template>
  <v-form>
    <v-toolbar flat>
      <v-toolbar-title>System Setup</v-toolbar-title>
      <v-divider class="mx-3" inset vertical left></v-divider>
    </v-toolbar>
    <v-container>
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-tabs background-color="primary accent-4" centered dark>
              <v-tab>
                <v-icon left> mdi-city </v-icon>
                Company Setup
              </v-tab>
              <v-tab>
                <v-icon left> mdi-bank </v-icon>
                Banknking Details
              </v-tab>
              <v-tab>
                <v-icon left> mdi-email-outline </v-icon>
                Email Setup
              </v-tab>
              <v-tab>
                <v-icon left> settings </v-icon>
                General Setup
              </v-tab>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-layout wrap>
                      <v-col xs="12" sm="12" md="4">
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Company"
                            :rules="[rules.required]"
                            label="Company Name"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Website"
                            :rules="[rules.required]"
                            label="Website"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Email"
                            :rules="[rules.required, rules.email]"
                            label="Email"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Owner"
                            :rules="[rules.required]"
                            label="Owner"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Tel"
                            :rules="[
                              rules.required,
                              rules.min,
                              rules.counterTel,
                            ]"
                            label="Tel No"
                            counter="10"
                            outlined
                            clearable
                            dense
                            maxlength="10"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.TelAlt"
                            label="Alternate Tel No"
                            counter="10"
                            outlined
                            clearable
                            dense
                            maxlength="10"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Cell"
                            :rules="[
                              rules.required,
                              rules.min,
                              rules.counterTel,
                            ]"
                            label="Cell No"
                            counter="10"
                            outlined
                            clearable
                            dense
                            maxlength="10"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Fax"
                            label="Fax"
                            counter="10"
                            outlined
                            clearable
                            dense
                            maxlength="10"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                      </v-col>

                      <v-col xs="12" sm="12" md="4">
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Address"
                            label="Steet Address"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Area"
                            label="Area"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>

                        <v-flex>
                          <v-text-field
                            v-model="editedItem.City"
                            label="City"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Code"
                            label="Area Code"
                            counter="4"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required, rules.counteraddMin]"
                            maxlength="4"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>

                        <v-flex>
                          <v-text-field
                            v-model="editedItem.POBox"
                            label="P.O. Box"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.AreaP"
                            label="Postal Area"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>

                        <v-flex>
                          <v-text-field
                            v-model="editedItem.CityP"
                            label="Postal City"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.CodeP"
                            label="Postal Code"
                            outlined
                            clearable
                            dense
                            counter="4"
                            maxlength="4"
                            :rules="[rules.required, rules.counteraddMin]"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                      </v-col>
                      <v-col xs="12" sm="12" md="4">
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.RegNo"
                            :rules="[rules.required]"
                            label="Regestration Number"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.EmpCode"
                            label="Employee Prefix Code"
                            :rules="[rules.required]"
                            outlined
                            clearable
                            dense
                            maxlength="3"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.CurrentFinancialYear"
                            label="Current Finacial Year (CFY)"
                            :rules="[rules.required]"
                            outlined
                            clearable
                            dense
                            maxlength="4"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-checkbox
                            :label="`Use Vat`"
                            false-value="0"
                            true-value="1"
                            value="Vat"
                            id="Vat"
                            v-model="editedItem.Use_Vat"
                            @change="checkUseVat"
                          >
                          </v-checkbox>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            :disabled="!enabledVat"
                            v-model="editedItem.VatRegNo"
                            label="Vat No"
                            outlined
                            clearable
                            dense
                            maxlength="10"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          >
                          </v-text-field>
                        </v-flex>
                      </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-layout wrap>
                      <v-col xs="12" sm="12" md="4">
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.AccNo"
                            :rules="[rules.required]"
                            label="Account Number"
                            outlined
                            clearable
                            dense
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Bank"
                            label="Bank"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Branch"
                            label="Branch"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.BCode"
                            label="Branch Code"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                      </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-layout wrap>
                      <v-col xs="12" sm="12" md="6">
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Host"
                            :rules="[rules.required]"
                            label="SMTP Host"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-select
                            v-model="editedItem.SmtpPort"
                            label="SMTP Port"
                            :items="smtpports"
                            item-text="portno"
                            item-value="portno"
                            outlined
                            dense
                            :rules="[rules.required]"
                          >
                          </v-select>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.From"
                            label="From"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required, rules.email]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.FromCC"
                            label="CC"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.SMTP_UserN"
                            label="Username"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.SMTP_Pwd"
                            type="password"
                            label="Password"
                            outlined
                            clearable
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-flex>
                      </v-col>
                      <v-col xs="12" sm="12" md="6">
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.EmailAcc"
                            :rules="[rules.required, rules.email]"
                            label="Accounts Email"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.EmailHR"
                            :rules="[rules.required, rules.email]"
                            label="HR Email"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.EmailSupp"
                            :rules="[rules.required, rules.email]"
                            label="Support Email"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            :rules="[rules.required, rules.email]"
                            label="Test Email"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-checkbox
                            :label="`Use Email`"
                            false-value="0"
                            true-value="1"
                            v-model="editedItem.Use_Email"
                          >
                          </v-checkbox>
                        </v-flex>
                        <v-flex>
                          <v-checkbox
                            :label="`Use TLS`"
                            false-value="0"
                            true-value="1"
                            v-model="editedItem.Use_TLS"
                          >
                          </v-checkbox>
                        </v-flex>
                      </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-layout wrap>
                      <v-col xs="12" sm="12" md="6">
                        <v-flex>
                          <v-checkbox
                            :label="`Invoice Auto Number`"
                            false-value="0"
                            true-value="1"
                            value="AutoI"
                            id="AutoI"
                            v-model="editedItem.Auto_Invoice"
                            @change="checkAutoInvoice"
                          >
                          </v-checkbox>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            :disabled="enabledInvoice"
                            v-model="editedItem.Own_Invoice"
                            label="Invoice Own Number"
                            outlined
                            clearable
                            dense
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            v-model="editedItem.Slogan"
                            label="Company Slogan"
                            outlined
                            clearable
                            dense
                          ></v-text-field>
                        </v-flex>
                      </v-col>
                      <v-col xs="12" sm="12" md="6">
                        <v-flex>
                          <v-checkbox
                            :label="`Quotations Auto Number`"
                            false-value="0"
                            true-value="1"
                            value="AutoQ"
                            id="AutoQ"
                            v-model="editedItem.Auto_Quote"
                            @change="checkAutoQuote"
                          >
                          </v-checkbox>
                        </v-flex>
                        <v-flex>
                          <v-text-field
                            :disabled="enabledQuote"
                            v-model="editedItem.Own_Quote"
                            label="Quotations Own Number"
                            outlined
                            clearable
                            dense
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          ></v-text-field>
                        </v-flex>
                      </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn ref="form" color="primary" @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    valid: true,
    search: "",
    id_no: "",
    email: "",
    pagination: {
      rowsPerPage: 10,
    },
    show1: false,
    enabledInvoice: false,
    enabledQuote: false,
    enabledVat: false,
    dbres: "",
    id: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length > 9 || "Min 10 digits",
      counter: (value) => value.length <= 4 || "Max 4 digits",
      counterTel: (value) => value.length <= 10 || "Max 10 characters",
      counteraddMin: (value) => value.length >= 4 || "Min 4 digits",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    systemsetup: [],
    smtpports: [],
    editedIndex: -1,
    editedItem: {
      s_id: "",
      Company: "",
      Website: "",
      Email: "",
      Owner: "",
      RegNo: "",
      Tel: "",
      TelAlt: "",
      Fax: "",
      Cell: "",
      Address: "",
      Area: "",
      City: "",
      Code: "",
      POBox: "",
      AreaP: "",
      CityP: "",
      CodeP: "",
      AccNo: "",
      Bank: "",
      Branch: "",
      BCode: "",
      Slogan: "",
      EmpCode: "",
      Host: "",
      From: "",
      FromCC: "",
      Use_Vat: "",
      VatRegNo: "",
      SmtpPort: "",
      SMTP_UserN: "",
      SMTP_Pwd: "",
      Use_TLS: "",
      Use_Email: "",
      Logo_Main_Path: "",
      Logo_Main_Center: "",
      Logo_Payslip_Main_Path: "",
      Logo_Payslip_Small_Path: "",
      Auto_Invoice: "",
      Own_Invoice: "",
      Auto_Quote: "",
      Own_Quote: "",
      EmailAcc: "",
      EmailSupp: "",
      CurrentFinancialYear: "",
      EmailHR: "",
    },
    defaultItem: {
      s_id: "",
      Company: "",
      Website: "",
      Email: "",
      Owner: "",
      RegNo: "",
      Tel: "",
      TelAlt: "",
      Fax: "",
      Cell: "",
      Address: "",
      Area: "",
      City: "",
      Code: "",
      POBox: "",
      AreaP: "",
      CityP: "",
      CodeP: "",
      AccNo: "",
      Bank: "",
      Branch: "",
      BCode: "",
      Slogan: "",
      EmpCode: "",
      Host: "",
      From: "",
      FromCC: "",
      Use_Vat: "",
      VatRegNo: "",
      SmtpPort: "",
      SMTP_UserN: "",
      SMTP_Pwd: "",
      Use_TLS: "",
      Use_Email: "",
      Logo_Main_Path: "",
      Logo_Main_Center: "",
      Logo_Payslip_Main_Path: "",
      Logo_Payslip_Small_Path: "",
      Auto_Invoice: "",
      Own_Invoice: "",
      Auto_Quote: "",
      Own_Quote: "",
      EmailAcc: "",
      EmailSupp: "",
      CurrentFinancialYear: "",
      EmailHR: "",
    },
  }),
  beforeMount: function () {
    let self = this;
    self.getSystemSetup();
    this.getSmtpPorts();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Ticket" : "Edit Ticket";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {},
  methods: {
    getSystemSetup: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/systemsetup`,
      }).then((response) => {
        this.editedIndex = response.data[0].id;
        let tempID = String(response.data[0].id);
        this.editedItem.s_id = tempID;
        this.editedItem.Company = response.data[0].Company;
        this.editedItem.Website = response.data[0].Website;
        this.editedItem.Email = response.data[0].Email;
        this.editedItem.Owner = response.data[0].Owner;
        this.editedItem.RegNo = response.data[0].RegNo;
        this.editedItem.Tel = response.data[0].Tel;
        this.editedItem.TelAlt = response.data[0].TelAlt;
        this.editedItem.Fax = response.data[0].Fax;
        this.editedItem.Cell = response.data[0].Cell;
        this.editedItem.Address = response.data[0].Address;
        this.editedItem.Area = response.data[0].Area;
        this.editedItem.City = response.data[0].City;
        this.editedItem.Code = response.data[0].Code;
        this.editedItem.POBox = response.data[0].POBox;
        this.editedItem.AreaP = response.data[0].AreaP;
        this.editedItem.CityP = response.data[0].CityP;
        this.editedItem.CodeP = response.data[0].CodeP;
        this.editedItem.AccNo = response.data[0].AccNo;
        this.editedItem.Bank = response.data[0].Bank;
        this.editedItem.Branch = response.data[0].Branch;
        this.editedItem.BCode = response.data[0].BCode;
        this.editedItem.Slogan = response.data[0].Slogan;
        this.editedItem.EmpCode = response.data[0].EmpCode;
        this.editedItem.Host = response.data[0].Host;
        this.editedItem.From = response.data[0].From;
        this.editedItem.FromCC = response.data[0].FromCC;
        this.editedItem.Use_Vat = response.data[0].Use_Vat;
        if (this.editedItem.Use_Vat == 1) {
          this.enabledVat = true;
        } else {
          this.enabledVat = false;
        }
        this.editedItem.VatRegNo = response.data[0].VatRegNo;
        this.editedItem.SmtpPort = response.data[0].SmtpPort;
        this.editedItem.SMTP_UserN = response.data[0].SMTP_UserN;
        this.editedItem.SMTP_Pwd = response.data[0].SMTP_Pwd;
        this.editedItem.Use_TLS = response.data[0].Use_TLS;
        this.editedItem.Use_Email = response.data[0].Use_Email;
        this.editedItem.Logo_Main_Path = response.data[0].Logo_Main_Path;
        this.editedItem.Logo_Main_Center = response.data[0].Logo_Main_Center;
        this.editedItem.Logo_Payslip_Main_Path =
          response.data[0].Logo_Payslip_Main_Path;
        this.editedItem.Logo_Payslip_Small_Path =
          response.data[0].Logo_Payslip_Small_Path;
        this.editedItem.Auto_Invoice = response.data[0].Auto_Invoice;
        if (this.editedItem.Auto_Invoice == 1) {
          this.enabledInvoice = true;
        } else {
          this.enabledInvoice = false;
        }
        this.editedItem.Own_Invoice = response.data[0].Own_Invoice;
        this.editedItem.Auto_Quote = response.data[0].Auto_Quote;
        if (this.editedItem.Auto_Quote == 1) {
          this.enabledQuote = true;
        } else {
          this.enabledQuote = false;
        }
        this.editedItem.Own_Quote = response.data[0].Own_Quote;
        this.editedItem.EmailAcc = response.data[0].EmailAcc;
        this.editedItem.EmailSupp = response.data[0].EmailSupp;
        this.editedItem.CurrentFinancialYear =
          response.data[0].CurrentFinancialYear;
        this.editedItem.EmailHR = response.data[0].EmailHR;
      });
    },
    postUpdateSystemSetup: function () {
      const data = {
        Company: this.editedItem.Company,
        Website: this.editedItem.Website,
        Email: this.editedItem.Email,
        Owner: this.editedItem.Owner,
        RegNo: this.editedItem.RegNo,
        Tel: this.editedItem.Tel,
        TelAlt: this.editedItem.TelAlt,
        Fax: this.editedItem.Fax,
        Cell: this.editedItem.Cell,
        Address: this.editedItem.Address,
        Area: this.editedItem.Area,
        City: this.editedItem.City,
        Code: this.editedItem.Code,
        POBox: this.editedItem.POBox,
        AreaP: this.editedItem.AreaP,
        CityP: this.editedItem.CityP,
        CodeP: this.editedItem.CodeP,
        AccNo: this.editedItem.AccNo,
        Bank: this.editedItem.Bank,
        Branch: this.editedItem.Branch,
        BCode: this.editedItem.BCode,
        Slogan: this.editedItem.Slogan,
        EmpCode: this.editedItem.EmpCode,
        Host: this.editedItem.Host,
        From: this.editedItem.From,
        FromCC: this.editedItem.FromCC,
        Use_Vat: this.editedItem.Use_Vat,
        VatRegNo: this.editedItem.VatRegNo,
        SmtpPort: this.editedItem.SmtpPort,
        SMTP_UserN: this.editedItem.SMTP_UserN,
        SMTP_Pwd: this.editedItem.SMTP_Pwd,
        Use_TLS: this.editedItem.Use_TLS,
        Use_Email: this.editedItem.Use_Email,
        Logo_Main_Path: this.editedItem.Logo_Main_Path,
        Logo_Main_Center: this.editedItem.Logo_Main_Center,
        Logo_Payslip_Main_Path: this.editedItem.Logo_Payslip_Main_Path,
        Logo_Payslip_Small_Path: this.editedItem.Logo_Payslip_Small_Path,
        Auto_Invoice: this.editedItem.Auto_Invoice,
        Own_Invoice: this.editedItem.Own_Invoice,
        Auto_Quote: this.editedItem.Auto_Quote,
        Own_Quote: this.editedItem.Own_Quote,
        EmailAcc: this.editedItem.EmailAcc,
        EmailSupp: this.editedItem.EmailSupp,
        CurrentFinancialYear: this.editedItem.CurrentFinancialYear,
        EmailHR: this.editedItem.EmailHR,
      };
      this.axios({
        method: "put",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/systemsetup/${this.editedItem.s_id}`,
        data: data,
      }).then((response) => {
        this.dbres = response.data;
      });
    },
    getSmtpPorts: function () {
      this.axios({
        method: "get",
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        url: `${this.$hostname}/api/smtpports`,
      }).then((response) => {
        this.smtpports = response.data;
      });
    },
    close() {
      // this.dialog = false
      // setTimeout(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      // }, 300)
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.editedIndex > -1) {
        //Object.assign(this.systemsetup[this.editedIndex], this.editedItem)
        this.postUpdateSystemSetup();
        // this.close
      } else {
        //this.systemsetup.push(this.editedItem)
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.systemsetup[this.editedIndex], this.editedItem);
          this.postUpdateSystemSetup();
        } else {
          this.systemsetup.push(this.editedItem);
          // this.postInsertTicket()
        }
        this.$refs.form.resetValidation();
        this.close();
      }
    },
    checkUseVat() {
      this.$nextTick(() => {
        let elt = document.getElementById("Vat");
        if (elt.checked) {
          this.enabledVat = true;
        } else {
          this.enabledVat = false;
        }
      });
    },
    checkAutoInvoice() {
      this.$nextTick(() => {
        let elt = document.getElementById("AutoI");
        if (elt.checked) {
          this.enabledInvoice = true;
          this.editedItem.Own_Invoice = "";
        } else {
          this.enabledInvoice = false;
        }
      });
    },
    checkAutoQuote() {
      this.$nextTick(() => {
        let elt = document.getElementById("AutoQ");
        if (elt.checked) {
          this.enabledQuote = true;
          this.editedItem.Own_Quote = "";
        } else {
          this.enabledQuote = false;
        }
      });
    },
  },
};
</script>
