import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[_c('h1',{staticClass:"subheading grey--text"},[_vm._v("Dashboard")]),_c(VContainer,{staticClass:"my-5"},_vm._l((_vm.mytasks),function(mytask){return _c(VCard,{key:mytask.title,staticClass:"pa-3",attrs:{"flat":""}},[_c(VRow,{class:`mytask ${mytask.task_status}`},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Project Title")]),_c('div',[_vm._v(_vm._s(mytask.task_name))])]),_c(VCol,{attrs:{"xs":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Person")]),_c('div',[_vm._v(_vm._s(mytask.task_user))])]),_c(VCol,{attrs:{"xs":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Due Date")]),_c('div',[_vm._v(_vm._s(mytask.task_date_due))])]),_c(VCol,{attrs:{"xs":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Status")]),_c('div',[_c(VChip,{staticClass:"white--text",attrs:{"small":"","caption":"","color":_vm.getColor(mytask.task_status)}},[_vm._v(" "+_vm._s(mytask.task_status)+" ")])],1)])],1),_c(VRow,[_c(VDivider)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }