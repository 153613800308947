import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5"},[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md6":""}},[_c(VCard,{staticClass:"elevation-12",attrs:{"dense":""}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Support email...")])],1),_c(VCardText,[_c(VForm,[_c(VTextField,{staticClass:"d-flex my-1",attrs:{"prepend-inner-icon":"email","rules":[_vm.rules.email],"name":"email","label":"To...","type":"text","outlined":"","dense":"","hide-details":"","readonly":""},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}}),_c(VTextField,{staticClass:"d-flex my-1",attrs:{"prepend-inner-icon":"subject","rules":[_vm.rules.required],"name":"subject","label":"Subject...","type":"text","outlined":"","dense":"","hide-details":""},model:{value:(_vm.editedItem.subject),callback:function ($$v) {_vm.$set(_vm.editedItem, "subject", $$v)},expression:"editedItem.subject"}}),_c(VFileInput,{staticClass:"d-flex my-1",attrs:{"label":"File input","outlined":"","prepend-icon":"","prepend-inner-icon":"mdi-paperclip","dense":"","hide-details":"","type":"file","accept":"*"},on:{"change":_vm.uploadFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c(VFlex,{attrs:{"xs12":"","sm6":"","md12":""}},[_c(VTextarea,{staticClass:"d-flex my-1",attrs:{"label":"Description","prepend-inner-icon":"description","rules":[_vm.rules.required],"outlined":"","dense":""},model:{value:(_vm.editedItem.textBody),callback:function ($$v) {_vm.$set(_vm.editedItem, "textBody", $$v)},expression:"editedItem.textBody"}})],1)],1)],1),_c(VCardActions,[_vm._v(" "+_vm._s(_vm.status)+" "+_vm._s(_vm.loading)+" "),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.send}},[_vm._v("Send")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }