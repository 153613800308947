import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        // primary: colors.blue.darken4,
        primary: colors.lightBlue,
        secondary: colors.grey.darken1,
        accent: colors.pink.darken1,
        error: colors.red.accent3,
        background: colors.indigo.lighten5,
        info: colors.teal.darken1,
      },
      dark: {
        primary: colors.lightBlue,
        // primary: colors.blue.darken4,
        background: colors.indigo.base,
        info: colors.teal.lighten1,
      },
    },
  },
  icons: {
    iconfont: "mdi" || "md",
  },
});

// Vue.use(Vuetify, {
//   iconfont: "md",
//   theme: {
//     primary: "#5277ff",
//     success: "#3cd1c2",
//     info: "#ffaa2c",
//     error: "#f83e70",
//   },
// });

// export default new Vuetify({});
