<template>
  <nav>
    <v-app-bar
      flat
      app
      outlined
      src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
    >
      <v-app-bar-nav-icon
        title="Menu"
        class="white--text"
        @click="drawer = !drawer"
      >
      </v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase white--text">
        <span class="font-weight-light">Rogue IT </span>
        <span>office management system (oms) &copy v {{ version }} </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div v-if="isLoggedIn">
        <v-btn
          title="Helpdesk"
          icon
          href="http://helpdesk.omsweb.co.za/"
          target="_blank"
        >
          <v-icon class="mr-1" color="white">support_agent</v-icon>
        </v-btn>
      </div>

      <div v-if="isLoggedIn">
        <v-btn
          title="Wiki"
          icon
          href="https://wiki.omsweb.co.za/"
          target="_blank"
        >
          <v-icon class="mr-1" color="white">mdi-help-circle-outline</v-icon>
        </v-btn>
      </div>

      <div>
        <v-btn
          title="Theme"
          icon
          v-if="!$vuetify.theme.dark"
          @click="toggleTheme()"
        >
          <v-icon class="mr-1" color="blue-grey darken-4">mdi-lightbulb</v-icon>
        </v-btn>
        <v-btn
          title="Theme"
          icon
          v-if="$vuetify.theme.dark"
          @click="toggleTheme()"
        >
          <v-icon color="white darken-3">mdi-lightbulb-outline</v-icon>
        </v-btn>
      </div>

      <div v-if="isLoggedIn">
        <v-btn text color="white" @click="logout">
          <span>Sign Out</span>
          <v-icon right>exit_to_app</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      temporary
      v-model="drawer"
      app
      class="primary"
      v-if="isLoggedIn"
      width="300"
      dense
    >
      <v-list nav dense v-if="roletype">
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group class="white--text" no-action>
          <template v-slot:activator>
            <v-list-item class="white--text">
              <v-list-item-action>
                <!-- <v-icon slot="prependIcon" color="white">account_balance</v-icon> -->
                <v-icon class="white--text">mdi-finance</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="white--text"
                  >Accounting</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="account in accounting"
            :key="account.text"
            router
            :to="account.route"
          >
            <v-list-item-action>
              <v-icon class="white--text">{{ account.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                account.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-for="clientl in client"
          :key="clientl.text"
          router
          :to="clientl.route"
          no-action
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ clientl.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              clientl.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          class="white--text"
          no-action
          v-if="checkPermissions(['HR'])"
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-action>
                <v-icon class="white--text">mdi-human-queue</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="white--text">HR</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item v-for="hrl in hr" :key="hrl.text" router :to="hrl.route">
            <v-list-item-action>
              <v-icon class="white--text">{{ hrl.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                hrl.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-for="stockl in stock"
          :key="stockl.text"
          router
          :to="stockl.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ stockl.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              stockl.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          class="white--text"
          no-action
          v-if="checkPermissions(['Setup'])"
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-action>
                <v-icon class="white--text">settings</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="white--text">Setup</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="setupl in setup"
            :key="setupl.text"
            router
            :to="setupl.route"
          >
            <v-list-item-action>
              <v-icon class="white--text">{{ setupl.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                setupl.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="checkPermissions(['Setup'])"
          v-for="systemSetup1 in systemSetup"
          :key="systemSetup1.text"
          router
          :to="systemSetup1.route"
          no-action
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ systemSetup1.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              systemSetup1.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="supplierl in supplier"
          :key="supplierl.text"
          router
          :to="supplierl.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ supplierl.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              supplierl.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="ticket1 in ticket"
          :key="ticket1.text"
          router
          :to="ticket1.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ ticket1.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              ticket1.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="checkPermissions(['SendMail'])"
          v-for="sendmail1 in sendmail"
          :key="sendmail1.text"
          router
          :to="sendmail1.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ sendmail1.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              sendmail1.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="support1 in support"
          :key="support1.text"
          router
          :to="support1.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ support1.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              support1.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import packageInfo from "../../package";
export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
      logIn: "Login",
      licon: "input",
      version: packageInfo.version,

      links: [{ icon: "dashboard", text: "Dashboard", route: "/Dashboard" }],
      accounting: [
        { icon: "mdi-file-document-plus", text: "Invoice", route: "/Invoice" },
        {
          icon: "mdi-file-document-check",
          text: "Quotes",
          route: "/Quotations",
        },
        {
          icon: "mdi-file-document-minus",
          text: "Purchase Order",
          route: "/POrder",
        },
        {
          icon: "mdi-file-document-alert",
          text: "Sales Order",
          route: "/SOrder",
        },
        {
          icon: "mdi-network-pos",
          text: "Payments",
          route: "/Payments",
        },
        {
          icon: "mdi-network-pos",
          text: "Expenses",
          route: "/Expenses",
        },
        {
          icon: "mdi-text-box-search",
          text: "Statements",
          route: "/Statements",
        },
      ],
      client: [
        {
          icon: "mdi-account-network",
          text: "Client Center",
          route: "/clients",
        },
      ],
      hr: [
        {
          icon: "mdi-account-group",
          text: "Personnel Center",
          route: "/Personnel",
        },
        { icon: "date_range", text: "Leave Planner", route: "/Planner" },
        { icon: "mdi-account-cash", text: "Payslips", route: "/Payslips" },
      ],
      stock: [{ icon: "assessment", text: "Inventory", route: "/Inventory" }],
      setup: [
        { icon: "account_balance", text: "Bank", route: "/Bank" },
        {
          icon: "account_balance_wallet",
          text: "Bank Account Type",
          route: "/BankAccType",
        },
        {
          icon: "add_circle_outline",
          text: "Contribution Type",
          route: "/Contributions",
        },
        {
          icon: "remove_circle_outline",
          text: "Deduction Type",
          route: "/DeductionsType",
        },
        { icon: "all_inclusive", text: "Discount", route: "/Discount" },
        { icon: "add_circle", text: "Earnings", route: "/Earnings" },
        { icon: "add_circle", text: "Employee Status", route: "/EmpStatus" },
        { icon: "assignment_ind", text: "Job Title", route: "/JobTitle" },
        { icon: "credit_card", text: "Payment Type", route: "/PayMethod" },
        {
          icon: "mdi-checkbox-multiple-marked-outline",
          text: "Status",
          route: "/Status",
        },
        { icon: "local_offer", text: "Terms", route: "/Terms" },
        { icon: "supervisor_account", text: "Users", route: "/users" },
      ],
      systemSetup: [
        {
          icon: "settings_applications",
          text: "System Setup",
          route: "/SystemSetup",
        },
      ],
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      supplier: [
        { icon: "mdi-account-network", text: "Supplier", route: "/Supplier" },
      ],
      ticket: [{ icon: "mdi-call-split", text: "My Tasks", route: "/MyTasks" }],

      sendmail: [
        {
          icon: "mdi-mail",
          text: "Send Email",
          route: "/SendMail",
        },
      ],
      support: [
        {
          icon: "contact_mail",
          text: "Support",
          route: "/Support",
        },
      ],
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    roleAccess: function () {
      return this.$store.getters.roleAccess;
    },
    roletype: function () {
      let test = localStorage.getItem("acctype");
      if (test === "Super User" || test === "Manager" || test === "Admin") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    checkPermissions(uType) {
      if (uType == "HR") {
        return false;
      }

      if (
        (uType == "Setup" || uType == "SendMail") &&
        this.roleAccess == "Super User"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
